import axios from 'axios';
import { useDispatch } from 'react-redux';
import { config } from '../config/environment/environment';
import store from 'src/store/Store';
import { setSessionExpiredInfo } from '@modules/home/state/auth/AuthStateSlice';

class Services {
  // Call at the very beginning
  dispatch = useDispatch();

  static init(): void {
    axios.defaults.timeout = config.REQ_TIME_OUT;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.baseURL = config.BASE_API_URL;

    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (
          error?.response?.status === 401 ||
          error?.response?.data?.statusCode === 401
        ) {
          store.dispatch(setSessionExpiredInfo({ isSessionExpired: true }));
        }
        return Promise.reject(error);
      },
    );
  }

  // Call after authentication is done and make required changes
  static initAfterAuth(appConfig: { token: string }): void {
    axios.defaults.headers.common['authorization'] = `Bearer ${
      appConfig?.token || ''
    }`;
  }

  // The generic handling of errors
  static genericErrorHandler(error: any): void {
    if (error && error.response && error.response.data) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
}

export default Services;
