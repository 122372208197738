import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { APP } from 'src/styles/variables';

export const MainLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 51;
  position: absolute;
  height: 65%;
  width: 100%;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 51;
  width: 100%;
  height: 12.5rem;
`;

export const ErrorTextStyled = styled('div')`
  margin-top: 0.5rem;
  color: ${APP.PALETTE.ERROR.MAIN};
`;

export const DialogContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 14rem;
`;

export const Form = styled.div<{
  disabled: boolean;
}>`
  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'default')};
`;

// Title and Role selection dropdown styling
export const TitleAndRoleSelectionDropdownContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  margin-top: 1rem;
  height: 100%;
`;

export const TitleAndRoleLabel = styled.div`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
`;

export const TitleSelectionDropdownContainer = styled('div')``;

export const TitleSelectionDropdown = styled('div')``;

export const RoleSelectionDropdownContainer = styled('div')``;

export const RoleSelectionDropdown = styled('div')``;

export const DividerStyled = styled('hr')`
  width: 100%;
  border-bottom: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
  margin-top: 2rem;
  margin-bottom: 1.375rem;
`;
