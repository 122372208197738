import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import { APP } from 'src/styles/variables';
import SearchBar from '@components/search-bar/SearchBar';
import IconButton from '@components/buttons/icon-button/IconButton';
import TextButton from '@components/buttons/text-button/TextButton';

export const DataNotFoundDiv = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 1rem;
  border: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
`;

export const TopBarContentGrid = styled(Grid)`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const SearchBarStyled = styled(SearchBar)`
  width: 21rem;
  @media screen and (max-width: 768px) {
    width: 17rem;
  }
`;

export const TopBarItemsWrapperGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.9375rem;
`;

export const FilterIconButton = styled(IconButton)`
  padding: 0.125rem;
  cursor: pointer;
`;

export const EmailHeadingTypography = styled(Typography)`
  font-size: ${APP.FONT.FONT_SIZE.H6};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  color: ${APP.PALETTE.COMMON.BLACK};
  margin: 1.5rem;
`;

export const EmailSettingsCellGrid = styled(Grid)`
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE2};
  font-family: ${APP.FONT.FONT_FAMILY.MEDIUM};
`;

export const ClassificationGroupedDropdownGrid = styled(Grid)`
  width: 100%;
`;

export const StateDropdownGrid = styled(Grid)`
  width: 100%;
`;

export const EmailSettingsBodyWrapperGrid = styled(Grid)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EmailSettingsSubjectTypography = styled(Typography)`
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE2};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
`;

export const EmailSettingsBodyTypography = styled(Typography)`
  font-family: ${APP.FONT.FONT_FAMILY.MEDIUM};
  font-size: 0.875rem;
  font-weight: ${APP.FONT.FONT_WEIGHT.H5};
  margin-left: 0.5em;
`;

export const BulkEditTextButton = styled(TextButton)`
  background-color: ${APP.PALETTE.SECONDARY.MAIN};
  border-color: ${APP.PALETTE.SECONDARY.MAIN};

  &:hover {
    background-color: ${APP.PALETTE.SECONDARY.MAIN};
    border-color: ${APP.PALETTE.SECONDARY.MAIN};
  }
`;
