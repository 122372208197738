import ServiceRequest from 'src/services/ServiceRequest';

import { getAllRolesUrl, getAllTitlesUrl } from './RolesApiEndPoints';

import { GetAllRolesQueryParam, GetAllRolesResDto } from './RolesServiceMapper';

export const getAllTitles = async (
  data?: GetAllRolesQueryParam,
): Promise<GetAllRolesResDto> => {
  return await ServiceRequest.get(getAllTitlesUrl, {
    payload: data,
  });
};

export const getAllRoles = async (
  data?: GetAllRolesQueryParam,
): Promise<GetAllRolesResDto> => {
  return await ServiceRequest.get(getAllRolesUrl, {
    payload: data,
  });
};
