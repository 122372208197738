import styled from '@emotion/styled';
import { APP } from '../variables';

export const ErrorTextStyled = styled('div')`
  color: ${APP.PALETTE.ERROR.MAIN};
`;

export const CircularProgressStyled = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;
export const NoDataFoundDivStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 1rem;
  border: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
`;

export const DialogLoaderContainerDivStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;
