import TextButton from '@components/buttons/text-button/TextButton';
import styled from '@emotion/styled';
import { Grid, MenuItem, Typography } from '@mui/material';
import { APP } from 'src/styles/variables';

export const QueueAIGrid = styled(Grid)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DataNotFoundDiv = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 1rem;
  border: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
`;

export const RowActionsWrapperDiv = styled('div')`
  width: fit-content;
  display: flex;
  flex-wrap: nowrap;
`;

export const ViewTextButton = styled(TextButton)`
  width: 5.866rem;
  height: 2.5rem;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  border: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
  background-color: ${APP.PALETTE.COMMON.WHITE};
`;

export const MenuItemStyled = styled(MenuItem)`
  border-bottom: 1px solid ${APP.PALETTE.COMMON.WHITE};
  color: ${APP.PALETTE.COMMON.WHITE};
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
`;
export const MoreMenuIconWrapperDiv = styled('div')`
  display: inline-flex;
  padding: 0.625rem 0.25rem 1.25rem 0.625rem;
  align-items: flex-start;
  gap: 0.625rem;
`;
export const MoreMenuIconImg = styled('img')`
  width: 1.25rem;
  height: 1.25rem;
`;

export const ViewTextTypography = styled(Typography)`
  color: ${APP.PALETTE.COMMON.BLACK};
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  text-transform: uppercase;
  padding-top: 0.05rem;
`;

export const RowGrid = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const CaseNumberTypography = styled(Typography)`
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
  color: ${APP.PALETTE.TEXT.PRIMARY};
`;

export const TaskCategoryRowGrid = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const CustomerNameTypography = styled(Typography)`
  color: ${APP.PALETTE.TEXT.PRIMARY};
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
`;

export const TaskTypeTypography = styled(Typography)`
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
  color: ${APP.PALETTE.TEXT.PRIMARY};
`;

export const NoTaskTypeTypography = styled(Typography)`
  width: 100%;
  color: ${APP.PALETTE.TEXT.PRIMARY};
  text-align: start;
  padding-left: 2rem;
`;

export const TaskCategoryTypography = styled(Typography)`
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
  color: ${APP.PALETTE.TEXT.PRIMARY};
`;

export const NoTaskCategoryTypography = styled(Typography)`
  width: 100%;
  color: ${APP.PALETTE.TEXT.PRIMARY};
  text-align: start;
  padding-left: 2rem;
`;

export const CustomerTypography = styled(Typography)`
  color: ${APP.PALETTE.TEXT.PRIMARY};
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
`;

export const NsaOrStateTypography = styled(Typography)`
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
  color: ${APP.PALETTE.TEXT.PRIMARY};
`;

export const PayorTypography = styled(Typography)`
  color: ${APP.PALETTE.TEXT.PRIMARY};
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
`;

export const DueDateTypography = styled(Typography)`
  color: ${APP.PALETTE.TEXT.PRIMARY};
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
`;

export const StatusTypography = styled(Typography)`
  color: ${APP.PALETTE.TEXT.PRIMARY};
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
`;

export const AssignedTypography = styled(Typography)`
  color: ${APP.PALETTE.TEXT.PRIMARY};
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
`;
