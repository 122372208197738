import React, { useState } from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import useTranslation from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import StyledSelectField from './SelectField';
import {
  DropdownGroupHeader,
  DropdownMenuItem,
  FormControlDiv,
  PlaceholderTypography,
  SelectFieldLabel,
  SelectWrapperGrid,
} from './SelectInputStyle';
import {
  DropdownOptionsType,
  GroupedFilterOptionType,
} from 'src/types/adminTypes';

interface ISelectInputProps {
  value?: string | boolean;
  dropdownOptions?: DropdownOptionsType[] | GroupedFilterOptionType[];
  selectInputHeading?: string;
  isstylingneeded?: 'yes' | 'no';
  handleChange?: (selectedOptionId?: string | HTMLInputElement) => void;
  showSelectedOption?: boolean;
  preSelectedValue?: string | boolean;
  isDefaultValuePresent?: boolean;
  isgroupingstylingneeded?: 'yes' | 'no';
  backgroundColor?: string;
  menuProps?: object;
  borderRadius?: string | number;
  width?: string;
  open?: boolean;
  paddingMedium?: 'yes' | 'no';
  isDropdownOpen?: boolean;
  onSelectChange?: (selectedValue: boolean) => void;
  onClose?: () => void;
  onOpen?: () => void;
  boxShadow?: 'yes' | 'no';
  isBorderRadiusRequired?: 'yes' | 'no';
  isindentationneeded?: 'yes' | 'no';
  isIdAsValueRequired?: boolean;
}

const SelectInput = (props: ISelectInputProps): React.ReactElement => {
  const {
    dropdownOptions = [],
    selectInputHeading,
    isstylingneeded = 'yes',
    boxShadow = 'yes',
    handleChange,
    showSelectedOption = false,
    preSelectedValue = '',
    isgroupingstylingneeded = 'no',
    isDefaultValuePresent = false,
    backgroundColor,
    borderRadius,
    paddingMedium = 'no',
    isBorderRadiusRequired = 'no',
    open,
    onClose,
    onOpen,
    onSelectChange,
    width,
    isIdAsValueRequired = false,
    menuProps = {
      style: {
        maxHeight: 500,
      },
    },
    isindentationneeded = 'yes',
    value,
    ...rest
  } = props;
  const { t } = useTranslation;
  const { control } = useForm({ mode: 'onChange' });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleSelectChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    if (handleChange) {
      handleChange(event.target.value);
    }
  };

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
    if (onSelectChange) {
      onSelectChange(true);
    }
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
    if (onSelectChange) {
      onSelectChange(false);
    }
  };

  const generateSelectOptions = () => {
    return dropdownOptions.map(
      (option: DropdownOptionsType | GroupedFilterOptionType) => {
        const id = option?.optionId
          ? option.optionId
          : option.optionId + crypto.randomUUID();
        const text = option.optionText;

        if (!text && option.listHeader) {
          return (
            <DropdownGroupHeader key={id}>
              {option.listHeader}
            </DropdownGroupHeader>
          );
        }

        if (text) {
          return (
            <DropdownMenuItem
              isindentationneeded={isindentationneeded}
              key={id}
              value={isIdAsValueRequired ? id : text}
            >
              {text}
            </DropdownMenuItem>
          );
        }
      },
    );
  };

  return (
    <SelectWrapperGrid>
      {selectInputHeading && (
        <SelectFieldLabel shrink>{selectInputHeading}</SelectFieldLabel>
      )}
      <Controller
        name={selectInputHeading ? selectInputHeading : 'SelectInputController'}
        control={control}
        defaultValue={preSelectedValue}
        render={({ field }) => (
          <FormControlDiv>
            <Select
              sx={{
                '&:focus-visible': {
                  outline: 'none',
                  border: 'none',
                },
                '&:active': {
                  outline: 'none',
                  border: 'none',
                },
                '&:focus': {
                  outline: 'none',
                  border: 'none',
                },
              }}
              style={{ fontWeight: '400 !important' }}
              variant="standard"
              displayEmpty={isDefaultValuePresent}
              MenuProps={menuProps}
              open={open || isDropdownOpen}
              onClose={onClose || handleDropdownClose}
              onOpen={onOpen || handleDropdownOpen}
              value={showSelectedOption ? value || field.value : ''}
              onChange={(e: SelectChangeEvent<HTMLInputElement>) => {
                handleSelectChange(e);
                field.onChange(e);
              }}
              onBlur={field.onBlur}
              input={
                <StyledSelectField
                  value={value}
                  paddingmedium={paddingMedium}
                  isstylingneeded={isstylingneeded}
                  isgroupingstylingneeded={isgroupingstylingneeded}
                  backgroundcolor={backgroundColor}
                  borderradius={borderRadius}
                  boxshadow={boxShadow}
                  isborderradiusrequired={isBorderRadiusRequired}
                  width={width}
                  fontWeight="600"
                  sx={{
                    '&:focus-visible': {
                      outline: 'none',
                      border: 'none',
                    },
                    '&:active': {
                      outline: 'none',
                      border: 'none',
                    },
                    '&:focus': {
                      outline: 'none',
                      border: 'none',
                    },
                  }}
                />
              }
              {...rest}
            >
              {isDefaultValuePresent && (
                <MenuItem key={'select'} disabled value="">
                  <PlaceholderTypography>
                    {t('ADMIN.COMMON.SELECT_INPUT.SELECT_INPUT_PLACEHOLDER')}
                  </PlaceholderTypography>
                </MenuItem>
              )}
              {generateSelectOptions()}
            </Select>
          </FormControlDiv>
        )}
      />
    </SelectWrapperGrid>
  );
};

export default SelectInput;
