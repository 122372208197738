import CustomDataGrid from '../CustomDataGrid';
import { ReactElement, useMemo, useState } from 'react';
import {
  MRT_ColumnDef as MRTColumnDef,
  MRT_Row as MRTRow,
  MRT_RowData as MRTRowData,
  MRT_ExpandedState as MRTExpandedState,
} from 'material-react-table';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import {
  DashboardDataGridWrapper,
  DashboardDataQueueListExpandLoaderContainer,
  ErrorGrid,
  ExpandIconImg,
  LoadingGrid,
} from './DashboardDataGridStyle';
import { APP } from 'src/styles/variables';
import UpArrowSVG from 'src/assets/images/ArrowSolid.svg';
import IconButton from '@components/buttons/icon-button/IconButton';
import Loader from '@components/loaders/Loader';
import { fetchAllCategoryTypesByCategoryId } from 'src/services/service-handlers/private/dashboard/DashboardPrivateService';
import {
  AssignedType,
  GetAllCategoryTypesByCategoryIdParams,
  RangeType,
  StateNsaType,
  StatusType,
  TaskOwnerType,
} from 'src/services/service-handlers/private/dashboard/DashboardServiceMapper';

export interface IQueryParamInterface {
  status?: StatusType;
  range?: RangeType;
  taskOwnerType?: TaskOwnerType;
  stateNsa?: StateNsaType;
  assigned?: AssignedType;
}
interface IDashboardDataGridProps<TData extends MRTRowData> {
  columns: MRTColumnDef<TData>[];
  data: TData[];
  isSubRowsPresent?: boolean;
  isSortingEnabled?: boolean;
  isExpandingEnabled?: boolean;
  isRowActionsEnabled?: boolean;
  isExpandAllEnabled?: boolean;
  isRowSelectionEnabled?: boolean;
  queryParams?: IQueryParamInterface;
}

interface ITypeItem {
  id?: string | number;
  categoryId: string;
  typeId: string;
  queueType?: string;
  total?: string | number;
  high: string | number;
  medium: string | number;
  low: string | number;
  isExpandable?: boolean;
  types?: ITypeItem[] | null;
}

interface IRowData {
  id?: string | number | null;
  queueType?: string;
  categoryId: string | null;
  total?: string | number;
  high: string | number;
  medium: string | number;
  low: string | number;
  isExpandable?: boolean;
  typeId?: string | null;
  types: ITypeItem[] | null;
}

const DashboardDataGrid = <TData extends MRTRowData>(
  props: IDashboardDataGridProps<TData>,
): ReactElement => {
  const {
    columns,
    data: rowDataInitial,
    isSubRowsPresent = false,
    isSortingEnabled: enableSorting = false,
    isExpandingEnabled: enableExpanding = false,
    isRowActionsEnabled: enableRowActions = false,
    isExpandAllEnabled: enableExpandAll = false,
    isRowSelectionEnabled: enableRowSelection = false,
    queryParams,
    ...rest
  } = props;
  const [expanded, setExpanded] = useState<MRTExpandedState>({});
  const [rowData, setRowData] = useState<TData[] | any>(rowDataInitial);

  const OverrideTablePaperProps = () => {
    return {
      elevation: 0,
      sx: {
        borderRadius: '0',
        border: 'none !important',
      },
    };
  };

  const OverrideTableBodyCellProps = () => {
    return {
      sx: {
        padding: '0.5rem 0.2rem',
        display: 'flex',
        justifyContent: 'center',
      },
    };
  };

  const OverrideTableBodyRowProps = (row: MRTRow<TData>) => {
    return {
      sx: {
        backgroundColor:
          row.depth == 1
            ? APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.SECONDARY
            : 'transparent',
        height: '3.875rem',
      },
    };
  };

  const styleOverrides = {
    // this is the wrapper box covering mui table
    muiTablePaperProps: OverrideTablePaperProps(),
    // override table body cells
    muiTableBodyCellProps: OverrideTableBodyCellProps(),
    // conditionally rendering 1st level nested rows
    muiTableBodyRowProps: ({ row }: { row: MRTRow<TData> }) =>
      OverrideTableBodyRowProps(row),
  };

  // controls the expand row column
  const displayColumnDefOptions = {
    'mrt-row-expand': {
      header: '',
      grow: false,
      size: 41,
      enableExpanding: true,
      Cell: ({ row }: { row: MRTRow<any> }) => (
        <div>
          {row.getCanExpand() && (
            <IconButton
              {...{
                onClick: row.getToggleExpandedHandler(),
              }}
            >
              <ExpandIconImg
                isopen={row.getIsExpanded() ? 'yes' : 'no'}
                alt="..."
                src={UpArrowSVG}
                height={16}
                width={16}
              />
            </IconButton>
          )}
        </div>
      ),
    },
  };

  // which rows have sub-rows expanded and need their direct sub-rows to be included in the API call
  const expandedRowIds: string[] = useMemo(
    () =>
      Object.entries(expanded)
        .filter(([_categoryId, isExpanded]) => isExpanded)
        .map(([categoryId]) => categoryId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expanded],
  );

  const {
    data = [],
    isError,
    isRefetching,
    isLoading,
  } = useQuery({
    queryKey: [
      'dashboard-grid', // give a unique key for this query
      expandedRowIds,
    ],
    queryFn: async () => {
      const rowDataList = [...rowData];

      if (Array.isArray(expandedRowIds) && expandedRowIds.length > 0) {
        for (const categoryId of expandedRowIds) {
          const payload: GetAllCategoryTypesByCategoryIdParams = {
            categoryId: categoryId,
            ...queryParams,
          };

          const response = await fetchAllCategoryTypesByCategoryId(payload);
          const fetchedRowData = response.data.rows[0];

          const flatArray: (IRowData | ITypeItem)[] = [];

          [fetchedRowData].forEach((item: IRowData) => {
            const fetchedItem = { ...item };
            fetchedItem.id = fetchedItem.categoryId;
            fetchedItem.categoryId = null;
            fetchedItem.isExpandable = true;
            flatArray.push({ ...fetchedItem, types: [] });
            if (item.types) {
              item.types.forEach((typeItem: ITypeItem) => {
                typeItem.isExpandable = false;
                flatArray.push(typeItem);
              });
            }
          });

          if (flatArray) {
            flatArray.forEach((eachRow: IRowData | ITypeItem) => {
              // If row already exists, replace it with the newly fetched one
              // else if sub row doesn't exist, push the new sub row
              if (!eachRow.typeId) {
                const existingIndex = rowDataList.findIndex(
                  row => row.id === eachRow.id,
                );
                if (existingIndex >= 0) {
                  rowDataList[existingIndex] = eachRow;
                }
              }

              if (eachRow.typeId) {
                const existingIndex = rowDataList.findIndex(
                  row => row.typeId === eachRow.typeId,
                );

                if (existingIndex >= 0) {
                  rowDataList[existingIndex] = eachRow;
                } else {
                  rowDataList.push(eachRow);
                }
              }
            });
          }
        }
        setRowData(rowDataList);
        return rowDataList;
      } else {
        await new Promise(resolve => setTimeout(resolve, 200));
        return rowDataList;
      }
    },
    placeholderData: keepPreviousData, // don't go to 0 rows when refetching
  });

  if (isLoading)
    return (
      <LoadingGrid>
        <Loader />
      </LoadingGrid>
    );
  if (isError) return <ErrorGrid>{isError}</ErrorGrid>;

  // renderSubRows function is responsible for parsing the flat array
  // into tree structure and providing sub-rows for a given parent row.
  const renderSubRows = (row: TData) => {
    return data.filter(r => r.categoryId === row.id);
  };

  // telling MRT the rows have additional sub-rows that can be fetched
  const isRowExpandable = (row: MRTRowData) => {
    return row.original.isExpandable;
  };

  return (
    <DashboardDataGridWrapper>
      <CustomDataGrid
        columns={columns}
        data={data}
        enableSorting={enableSorting}
        muiTableContainerProps={{
          sx: { maxHeight: '800px', minHeight: 'fit-content' },
        }}
        getRowCanExpand={row =>
          isSubRowsPresent ? isRowExpandable(row) : false
        }
        getSubRows={row => (isSubRowsPresent ? renderSubRows(row) : [])}
        state={{
          isLoading,
          showAlertBanner: isError,
        }}
        isSubRowsPresent={isSubRowsPresent}
        setExpanded={setExpanded}
        enableExpanding={enableExpanding}
        enableRowActions={enableRowActions}
        enableExpandAll={enableExpandAll}
        enableRowSelection={enableRowSelection}
        displayColumnDefOptions={displayColumnDefOptions}
        {...styleOverrides}
        {...rest}
      />
      {isRefetching && (
        <DashboardDataQueueListExpandLoaderContainer>
          <Loader />
        </DashboardDataQueueListExpandLoaderContainer>
      )}
    </DashboardDataGridWrapper>
  );
};

export default DashboardDataGrid;
