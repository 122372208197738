import { ReactElement, useEffect, useRef, useState } from 'react';
import useTranslation from 'i18next';
import Dialog from '@mui/material/Dialog';
import {
  AttachmentLoaderWrapperGrid,
  AttachmentViewerIFrame,
  CloseIconImg,
  DialogActionsSectionGrid,
  DialogContentDiv,
  DialogContentWrapperGrid,
  DialogHeaderSectionGrid,
  DialogTitleTypography,
  DownloadButton,
  DownloadIconImg,
  DownloadTypography,
  NoPreviewAvailable,
} from './EmailAttachmentViewerDialogStyle';
import CloseIcon from '@assets/images/X.svg';
import DownloadIcon from '@assets/images/Download.svg';
import Loader from '@components/loaders/Loader';
import { Tooltip } from '@mui/material';

interface DialogProps {
  open: boolean;
  dialogTitle: string;
  isLoading?: boolean;
  dialogContent: any;
  handleCloseBtnClick: () => void;
  handleDownloadBtnClick: () => void;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  isMimeTypeUnsupported?: boolean;
}

const EmailAttachmentViewerDialog = (props: DialogProps): ReactElement => {
  const { t } = useTranslation;
  const {
    open = false,
    handleCloseBtnClick,
    handleDownloadBtnClick,
    isLoading = false,
    dialogTitle,
    dialogContent,
    fullWidth = true,
    maxWidth = 'lg',
    isMimeTypeUnsupported = false,
  } = props;

  const titleRef = useRef<HTMLDivElement>(null);
  const [tooltipWidth, setTooltipWidth] = useState<string | number>('auto');

  useEffect(() => {
    if (titleRef.current) {
      setTooltipWidth(titleRef.current.offsetWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleRef.current]);

  const renderDialogActions = () => {
    return (
      <DialogActionsSectionGrid>
        <DownloadButton onClick={handleDownloadBtnClick}>
          <>
            <DownloadIconImg src={DownloadIcon} alt="download" />
            <DownloadTypography>
              {t('ADMIN.EMAIL_ATTACHMENT_DIALOG.DOWNLOAD')}
            </DownloadTypography>
          </>
        </DownloadButton>
        <CloseIconImg
          autoFocus
          onClick={handleCloseBtnClick}
          src={CloseIcon}
          alt="close"
        />
      </DialogActionsSectionGrid>
    );
  };

  const renderDialogHeader = (): ReactElement => {
    return (
      <DialogHeaderSectionGrid>
        <Tooltip
          title={dialogTitle}
          placement="bottom-start"
          componentsProps={{
            tooltip: {
              sx: {
                maxWidth: tooltipWidth,
                width: tooltipWidth,
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              },
            },
          }}
        >
          <DialogTitleTypography ref={titleRef}>
            {dialogTitle}
          </DialogTitleTypography>
        </Tooltip>
        {renderDialogActions()}
      </DialogHeaderSectionGrid>
    );
  };

  const renderDialogContent = (): ReactElement => {
    return (
      <DialogContentWrapperGrid>
        <DialogContentDiv>
          {isMimeTypeUnsupported ? (
            <NoPreviewAvailable>
              {t('ADMIN.EMAIL_ATTACHMENT_DIALOG.NO_PREVIEW_AVAILABLE')}
            </NoPreviewAvailable>
          ) : isLoading ? (
            <AttachmentLoaderWrapperGrid>
              <Loader />
            </AttachmentLoaderWrapperGrid>
          ) : (
            <AttachmentViewerIFrame src={dialogContent} />
          )}
        </DialogContentDiv>
      </DialogContentWrapperGrid>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseBtnClick}
      fullWidth={isMimeTypeUnsupported ? false : fullWidth}
      maxWidth={maxWidth}
    >
      {renderDialogHeader()}
      {renderDialogContent()}
    </Dialog>
  );
};

export default EmailAttachmentViewerDialog;
