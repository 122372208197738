import CustomChip from '@components/custom-chip/CustomChip';
import styled from '@emotion/styled';
import { Box, Checkbox } from '@mui/material';
import { APP } from 'src/styles/variables';

export const MainLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 51;
  position: absolute;
  height: 88%;
  width: 100%;
`;

export const PUAddUserDialogContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

export const DividerStyled = styled('hr')<{ ending?: boolean }>`
  width: 100%;
  border-bottom: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
  margin-top: 1.25rem;
  margin-bottom: ${({ ending = false }) => (ending ? '' : '1.5625rem')};
`;

export const ErrorTextStyled = styled('div')`
  margin-top: 0.5rem;
  color: ${APP.PALETTE.ERROR.MAIN};
`;

export const DialogContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const SearchAndSelectUserDropDownContainer = styled.div`
  width: 100%;
  margin-top: 9px;
`;

// Selected User info section
export const SelectedUserInfoContainer = styled.div`
  width: 100%;
  margin-top: 13px;
  display: flex;
  justify-content: space-between;
`;

export const SelectedUserInfo = styled.div`
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.H6};
`;

export const SelectedUserName = styled.div`
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  overflow: hidden; /* Hide any content that overflows its container */
  overflow-wrap: break-word;
  max-width: 300px;
`;

export const SelectedUserEmailId = styled.div`
  overflow: hidden; /* Hide any content that overflows its container */
  overflow-wrap: break-word;
  max-width: 300px;
`;

export const SelectedUserPhoneNumber = styled.div``;

export const RemoveSelectedUser = styled.div`
  cursor: pointer;
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE2};
  font-weight: ${APP.FONT.FONT_WEIGHT.H6};
  text-decoration-line: underline;
`;

export const FormAfterSelectingUserFromAzure = styled.div<{
  disabled: boolean;
}>`
  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'default')};
`;

// Title and Role selection dropdown styling
export const TitleAndRoleSelectionDropdownContainer = styled(Box)`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  margin-top: 1rem;
`;

export const TitleAndRoleLabel = styled.div`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
`;

export const TitleSelectionDropdownContainer = styled('div')``;

export const TitleSelectionDropdown = styled('div')``;

export const RoleSelectionDropdownContainer = styled('div')``;

export const RoleSelectionDropdown = styled('div')``;

// State/NSA styles

export const StateNsaContainer = styled(Box)`
  width: 100%;
`;

export const StateNsaTitle = styled.div`
  font-family: ${APP.FONT.FONT_FAMILY.PRIMARY};
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
`;

export const SelectStateNsaDropdownContainer = styled.div`
  margin: 0.6875rem 0 0.75rem;
`;

export const SelectStateNsaDropdownTitle = styled(StateNsaTitle)`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
`;

export const SelectStateNsaDropdownWrapper = styled.div``;

// searchable select state
export const SelectStateListContainer = styled.div`
  width: 100%;
  margin-top: 0.75rem;
`;

export const CustomImg = styled.img`
  margin-right: 0;
  padding-right: 3px;
`;

export const StateCodeChipsContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledCustomChip = styled(CustomChip)`
  color: ${APP.PALETTE.COMMON.WHITE};
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  background-color: ${APP.PALETTE.SECONDARY.MAIN};
  margin: 0.2rem 0.1rem 0.3rem 0.1rem;
  padding: 0.3rem;
`;

// Queue assignments section

export const QueueAssignmentsContainer = styled(Box)``;

export const QueueAssignmentsTitle = styled.div`
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
`;

export const QueueAssignmentsOptionsContainer = styled(Box)``;

export const QueueAssignmentOptionContainer = styled.div``;

export const CheckboxStyled = styled(Checkbox)`
  & .MuiSvgIcon-root {
    font-size: 28;
  }

  color: ${APP.PALETTE.GREY.SEMI_GRAY};
`;

// tab filter section

export const TabFilterContainer = styled(Box)`
  margin-left: 1.75rem;
`;
