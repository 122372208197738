import * as Yup from 'yup';
export const settingsValidationSchema = Yup.object().shape({
  maxAssignments: Yup.number(),
});

export const flagQueueItemValidationSchema = Yup.object().shape({
  flagQueueItem: Yup.string().required(
    'ADMIN.VALIDATIONS.FLAG_QUEUE_ITEM_DIALOG.FLAG_QUEUE_ITEM_REQUIRED',
  ),
});
