import ServiceRequest from 'src/services/ServiceRequest';
import {
  getAllAzureUsersFromOurSystemUrl,
  getAllUsersByServicePrincipalIdFromAzureUrl,
  getUserDetailsByPrincipalIdFromAzureUrl,
  getUserProfileByIdUrl,
  signUpNewUserFromAzureUrl,
  updateUserActivityStatusByIdUrl,
  updateUserProfileByIdUrl,
} from './UsersApiEndPoints';
import {
  GetAllUsersByServicePrincipalIdFromAzureQueryParam,
  GetADUsersByServicePrincipalIdResDto,
  GetADUserByPrincipleIdResDto,
  AddUserPayloadDto,
  AddUserResDto,
  GetUserProfileResDto,
  UpdateUserProfilePayloadDto,
  UpdateUserProfileResDto,
  GetAllUsersFromOurSystemResDto,
  GetAllUsersFromOurSystemQueryParam,
  UpdateUserActivityStatusQueryParam,
  UpdateUserActivityStatusResDto,
  GetUserProfileQueryParam,
} from './UsersServiceMapper';

// get list of users based on status from our system
export const fetchAllUsersFromOurSystem = async (
  data?: GetAllUsersFromOurSystemQueryParam,
): Promise<GetAllUsersFromOurSystemResDto> => {
  return ServiceRequest.get(getAllAzureUsersFromOurSystemUrl, {
    payload: data,
  });
};

export const getAllUsersByServicePrincipalIdFromAzure = async (
  data?: GetAllUsersByServicePrincipalIdFromAzureQueryParam,
): Promise<GetADUsersByServicePrincipalIdResDto> => {
  return await ServiceRequest.get(getAllUsersByServicePrincipalIdFromAzureUrl, {
    payload: data,
  });
};

export const getUserByServicePrincipalIdFromAzure = async (
  id: string,
): Promise<GetADUserByPrincipleIdResDto> => {
  return await ServiceRequest.get(
    getUserDetailsByPrincipalIdFromAzureUrl.replace(':id', id),
  );
};

// sign up new user
export const addUser = async (
  data: AddUserPayloadDto,
): Promise<AddUserResDto> => {
  return await ServiceRequest.post(signUpNewUserFromAzureUrl, {
    payload: data,
  });
};

// get user profile by id who have already signed up
export const getUserProfileById = async (
  id: string,
  data: GetUserProfileQueryParam,
): Promise<GetUserProfileResDto> => {
  return await ServiceRequest.get(getUserProfileByIdUrl.replace(':id', id), {
    payload: data,
  });
};

export const updateUserProfile = async (
  id: string,
  data: UpdateUserProfilePayloadDto,
): Promise<UpdateUserProfileResDto> => {
  return await ServiceRequest.put(updateUserProfileByIdUrl.replace(':id', id), {
    payload: data,
  });
};

export const updateUserActivityStatus = async (
  id: string,
  data?: UpdateUserActivityStatusQueryParam,
): Promise<UpdateUserActivityStatusResDto> => {
  return await ServiceRequest.put(
    updateUserActivityStatusByIdUrl.replace(':id', id),
    undefined,
    {
      payload: data,
    },
  );
};
