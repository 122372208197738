import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IProfileState } from './ProfileDetailsTypes';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import { TimeOffsDataDto } from 'src/services/service-handlers/private/profile-details-handlers/ProfileDetailsServiceMapper';

interface TimeOffsPayload {
  timeOffs: TimeOffsDataDto[];
}

const initialState: IProfileState = {
  id: '',
  name: '',
  email: '',
  phone: '',
  timeOffs: [],
  title: { id: '', label: '' },
  userType: AdminConstants.ADMIN,
  stateNsa: '',
  profilePic: '',
  profilePicId: '',
  isProfilePictureLoading: false,
  isProfileDetailsLoading: false,
};

const profileDetailsSlice = createSlice({
  initialState,
  name: 'profileDetails',
  reducers: {
    setProfileData(state, action: PayloadAction<IProfileState>) {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.profilePicId = action.payload.profilePicId;
      state.timeOffs = action.payload.timeOffs ?? [];
      state.title = action.payload.title ?? { id: '', label: '' };
      state.userType = action.payload.userType;
      state.stateNsa = action.payload.stateNsa;
    },

    setTimeOffs(state, action: PayloadAction<TimeOffsPayload>) {
      // Update the timeOffs array in the state
      state.timeOffs = action.payload.timeOffs ?? [];
    },

    setProfilePicture(state, action: PayloadAction<string>) {
      state.profilePic = action.payload;
    },

    setProfilePictureId(state, action: PayloadAction<string>) {
      state.profilePicId = action.payload;
    },

    removeProfilePicture(state) {
      state.profilePic = '';
    },
    setIsProfilePictureLoading(state, action: PayloadAction<boolean>) {
      state.isProfilePictureLoading = action.payload;
    },
    setIsProfileDetailsLoading(state, action: PayloadAction<boolean>) {
      state.isProfileDetailsLoading = action.payload;
    },
  },
});

export const {
  setProfileData,
  setTimeOffs,
  setProfilePicture,
  removeProfilePicture,
  setProfilePictureId,
  setIsProfilePictureLoading,
  setIsProfileDetailsLoading,
} = profileDetailsSlice.actions;

export default profileDetailsSlice.reducer;
