import { ReactElement, useState } from 'react';
import SelectInput from './SelectInput';
import { GroupedFilterOptionType } from 'src/types/adminTypes';

const filterOptions = [
  { optionId: 1, listHeader: 'Informal Negotiations', optionText: null },
  {
    optionId: 11,
    optionText: 'Undistributed',
    listHeader: 'Informal Negotiations',
  },
  {
    optionId: 12,
    optionText: 'Requested Fees',
    listHeader: 'Informal Negotiations',
  },
  {
    optionId: 14,
    optionText: 'Informal Negotiations',
    listHeader: 'Informal Negotiations',
  },
  {
    optionId: 15,
    optionText: 'Fee Payments',
    listHeader: 'Informal Negotiations',
  },
  { optionId: 16, optionText: 'All', listHeader: 'Informal Negotiations' },
  { optionId: 21, listHeader: 'Formal', optionText: null },
  { optionId: 22, optionText: 'Additional Info Request', listHeader: 'Formal' },
  { optionId: 23, optionText: 'IDRE Ineligible', listHeader: 'Formal' },
  { optionId: 24, optionText: 'IDRE Re-Selection', listHeader: 'Formal' },
  {
    optionId: 25,
    optionText: 'Brief Due',
    listHeader: 'Formal',
  },
];

interface IGroupedSelectInputProps {
  groupedFilterOptions?: GroupedFilterOptionType[];
  handleOptionSelect: (data?: string | HTMLInputElement) => void;
  borderRadius?: string | number;
  isgroupingstylingneeded?: 'yes' | 'no';
  backgroundColor?: string;
  width?: string;
  isIdAsValueRequired?: boolean;
  preSelectedValue?: string;
  value?: string;
  onSelectChange?: (selectedValue: boolean) => void;
  isStylingNeeded?: 'yes' | 'no';
  paddingMedium?: 'yes' | 'no';
  boxShadow?: 'yes' | 'no';
  isBorderRadiusRequired?: 'yes' | 'no';
}

const GroupedSelectInput = (props: IGroupedSelectInputProps): ReactElement => {
  const {
    handleOptionSelect,
    groupedFilterOptions = filterOptions,
    borderRadius,
    isgroupingstylingneeded = 'yes',
    backgroundColor,
    preSelectedValue,
    isIdAsValueRequired,
    value,
    width,
    isStylingNeeded = 'yes',
    paddingMedium = 'no',
    boxShadow = 'yes',
    isBorderRadiusRequired = 'no',
    onSelectChange,
  } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onOptionChangeHandler = (
    data: string | HTMLInputElement | undefined,
  ) => {
    if (handleOptionSelect) {
      handleOptionSelect(data);
    }
  };

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
    if (onSelectChange) {
      onSelectChange(true);
    }
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
    if (onSelectChange) {
      onSelectChange(false);
    }
  };

  return (
    <SelectInput
      isstylingneeded={isStylingNeeded}
      isgroupingstylingneeded={isgroupingstylingneeded}
      dropdownOptions={groupedFilterOptions}
      handleChange={onOptionChangeHandler}
      open={isDropdownOpen}
      onClose={handleDropdownClose}
      onOpen={handleDropdownOpen}
      showSelectedOption={true}
      isDefaultValuePresent={true}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      isIdAsValueRequired={isIdAsValueRequired}
      preSelectedValue={preSelectedValue}
      value={value}
      paddingMedium={paddingMedium}
      boxShadow={boxShadow}
      isBorderRadiusRequired={isBorderRadiusRequired}
      width={width}
      menuProps={{
        style: {
          maxHeight: 500,
        },
      }}
    />
  );
};

export default GroupedSelectInput;
