import styled from '@emotion/styled';
import CustomChip from '@components/custom-chip/CustomChip';
import TextButton from '@components/buttons/text-button/TextButton';
import { APP } from 'src/styles/variables';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { PriorityTypes } from '../queue-constants/QueueConstants';

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 51;
  width: 95%;
  height: 80%;
`;

export const RowStyleDiv = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  cursor: pointer;
`;

export const RowCaseStyleDiv = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const RowStyledDiv = styled('div')`
  font-family: ${APP.FONT.FONT_FAMILY.PRIMARY};
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
`;
export const RowStyledBackLinkDiv = styled('div')`
  font-family: ${APP.FONT.FONT_FAMILY.PRIMARY};
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
  color: ${APP.FONT.FONT_COLOR.SECONDARY};
  text-decoration: underline;
  cursor: pointer;
  padding-left: 0.5rem;
`;
export const RowStyleSemiBoldTextDiv = styled(RowStyleDiv)`
  font-family: ${APP.FONT.FONT_FAMILY.SEMIBOLD};
`;

export const RowCPTStyleDiv = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  color: ${APP.PALETTE.SECONDARY.MAIN};
`;

export const StyledChip = styled(CustomChip)`
  color: ${APP.PALETTE.COMMON.WHITE};
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  text-transform: capitalize;
  background-color: ${props =>
    props.label === PriorityTypes.LOW
      ? APP.PALETTE.BACKGROUND.CHIP_GRAY
      : props.label === PriorityTypes.HIGH
      ? APP.PALETTE.BACKGROUND.CHIP_RED
      : props.label === PriorityTypes.MEDIUM
      ? APP.PALETTE.BACKGROUND.CHIP_ORANGE
      : APP.PALETTE.BACKGROUND.CHIP_GRAY};
`;

export const ButtonDiv = styled(TextButton)`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  color: ${APP.PALETTE.COMMON.BLACK};
  border-color: ${APP.PALETTE.GREY.SEMI_GRAY};
`;

export const TypeCellSubTextStyle = styled('p')`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  margin-top: 0.5rem;
`;

export const RowActionsWrapper = styled(Box)`
  display: flex;
  flex-wrap: nowrap;
  margin-left: 2.5rem;
`;

export const ViewButtonStyled = styled(Button)`
  width: 5.866rem;
  height: 2.5rem;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
  background-color: ${APP.PALETTE.COMMON.WHITE};
  color: ${APP.PALETTE.COMMON.BLACK};
  text-align: center;
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  text-transform: uppercase;
`;

export const MoreWrapper = styled('div')`
  display: inline-flex;
  padding: 0.625rem 1.25rem;
  align-items: flex-start;
  gap: 0.625rem;
`;

export const MoreIconStyled = styled('img')`
  width: 1.25rem;
  height: 1.25rem;
`;

export const MenuItemStyled = styled(MenuItem)`
  border-bottom: 1px solid ${APP.PALETTE.COMMON.WHITE};
  color: ${APP.PALETTE.COMMON.WHITE};
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
`;

export const MenuStyled = styled(Menu)`
  background: ${APP.PALETTE.COMMON.BLACK};
  width: 12.5rem;
  height: 2.5rem;
  flex-shrink: 0;
`;

export const AssignedUserDivStyled = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE2};
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
`;

export const OverlayLoaderContainer = styled.div`
  height: calc(100vh - 5rem);
  position: absolute;
  width: 100vw;
  z-index: 1301;
  background: ${APP.PALETTE.BACKGROUND.BLUE_WITH_OPACITY};
  display: flex;
  justify-content: center;
  align-items: center;
`;
