import { Avatar, Button, Grid, Modal, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { APP } from 'src/styles/variables';
import { Link } from 'react-router-dom';

export const MainProfileContainer = styled('div')<{ isAdmin?: boolean }>`
  overflow-y: auto;
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
  height: ${({ isAdmin = false }) =>
    isAdmin ? '100%' : 'calc(100vh - 3.75rem)'};
`;

export const ProfileContainer = styled('div')<{ isAdmin?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
  flex-direction: column;
  padding: 2rem 0;
  margin-top: ${({ isAdmin = false }) => (isAdmin ? '20px' : '0')};
`;

export const ProfileCard = styled(Grid)`
  width: 35.5rem;
  border-radius: 1.25rem;
  background-color: ${APP.PALETTE.BACKGROUND.PAPER};
  padding-left: 2rem;
  padding-right: 2rem;

  @media (min-width: 706px) {
    width: 35.5rem;
    height: fit-content;
  }

  @media (min-width: 481px) and (max-width: 705px) {
    width: 90%;
    height: fit-content;
  }

  @media (max-width: 480px) {
    width: 90%;
    height: fit-content;
  }
`;

export const ProfileHeading = styled.h1`
  color: ${APP.FONT.FONT_COLOR.PRIMARY};
  font-family: ${APP.FONT.FONT_FAMILY.PRIMARY};
  font-size: ${APP.FONT.FONT_SIZE.H6};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  padding-top: 1.75rem;

  @media (max-width: 480px) {
    font-size: ${APP.FONT.FONT_SIZE.H6};
  }
`;

type ProfileAvatarProps = {
  hovered: 'yes' | 'no';
};

export const ProfileAvatar = styled(Avatar)<ProfileAvatarProps>`
  width: 6.5rem;
  height: 6.5rem;
  margin-top: 1.5rem;
  position: ${({ hovered }) => (hovered === 'yes' ? 'relative' : 'static')};
  opacity: ${({ hovered }) => (hovered === 'yes' ? 0.5 : 1)};
  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 480px) {
    width: 6.25rem;
    height: 6.25rem;
  }
`;
export const DetailsContainer = styled.div`
  padding-bottom: 1rem;
`;

export const DividerLine = styled.hr`
  margin-top: 1.813rem;
  border: none;
  border-bottom: 0.063rem solid ${APP.PALETTE.GREY.SEMI_GRAY};
  color: ${APP.PALETTE.GREY.SHADE_50};
`;

export const SecondDividerLine = styled.hr`
  border: none;
  border-bottom: 0.063rem solid ${APP.PALETTE.GREY.SEMI_GRAY};
  color: ${APP.PALETTE.GREY.SHADE_50};
`;

export const FooterDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.275rem;
  margin-bottom: 1rem;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
`;

export const PrivacyPolicyLinks = styled.div`
  display: flex;
  gap: 0.625rem;
  font-size: ${APP.FONT.FONT_SIZE.BODY2};
  padding-top: 0.6rem;
`;

export const CustomLink = styled(Link)`
  font-family: ${APP.FONT.FONT_FAMILY.PRIMARY};
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  font-weight: bold;
  text-decoration: underline;
  text-decoration-thickness: 0.063rem;
  text-underline-offset: 0.25rem;
`;

export const ProfileNameGrid = styled(Grid)`
  padding-top: 1.625rem;
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};

  @media (max-width: 480px) {
    font-size: ${APP.FONT.FONT_SIZE.BODY1};
  }
`;

export const ProfileEmailGrid = styled(Grid)`
  padding-top: 0.875rem;
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};

  @media (max-width: 480px) {
    font-size: ${APP.FONT.FONT_SIZE.BODY1};
  }
`;
export const ProfilePhoneGrid = styled(Grid)`
  padding-top: 0.875rem;
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};

  @media (max-width: 480px) {
    font-size: ${APP.FONT.FONT_SIZE.BODY1};
  }
`;

export const ProfileEditContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LogoutButton = styled(Button)`
  color: ${APP.PALETTE.BACKGROUND.CHIP_RED};
  cursor: 'pointer';
  text-transform: capitalize;
  text-decoration: underline;
  text-decoration-thickness: 0.094rem;
  text-underline-offset: 0.25rem;
  font-size: ${APP.FONT.FONT_SIZE.BODY1};

  @media (max-width: 480px) {
    font-size: ${APP.FONT.FONT_SIZE.BODY3};
  }
`;

export const EmailTypography = styled(Typography)`
  word-wrap: break-word;
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};

  @media (max-width: 480px) {
    font-size: ${APP.FONT.FONT_SIZE.BODY1};
  }
`;

export const AddButtonText = styled(Button)<{ disabled?: boolean }>`
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
  color: ${APP.FONT.FONT_COLOR.PRIMARY};
  width: 5rem;
  height: 2.5rem;
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  text-transform: capitalize;
  border: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
  border-radius: 0.625rem;
  margin-right: 10px;
  &.Mui-disabled {
    pointer-events: auto;
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;
interface SetTimeOffContainerProps {
  isTopMarginRequired: boolean;
}
export const SetTimeOffContainer = styled.div<SetTimeOffContainerProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${props => (props.isTopMarginRequired ? '1.5rem' : '0')};
  justify-content: space-between;
`;

export const SetTimeoutHeading = styled.p`
  font-weight: bold;
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};
  color: ${APP.PALETTE.TEXT.PRIMARY};
  margin-top: 0.563rem;
`;

export const SetTimeOffCaption = styled.p`
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_17PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.H6};
  margin-top: 0.125rem;
`;

export const SetTimeOffButtonDiv = styled.div``;

export const SetTimeOffHeadingDiv = styled.div``;

export const SetTimeOffSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SetTimeOffDatesContainer = styled.div`
  max-height: 12.5rem;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  margin-top: ${APP.FONT.FONT_SIZE.BODY1};
  padding: 0rem 0.125rem;
`;

export const SetTimeOffDatesSection = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f7d2d2;
  border-radius: 4px;
  height: auto;
  margin-bottom: 0.3rem;
  padding-left: 0.125rem;
  padding-right: 1.5rem;
  padding-top: 0.594rem;
  padding-bottom: 0.594rem;
`;

export const DateRangeModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const SetTimeOffDates = styled.p`
  font-weight: bold;
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_17PX};
  color: ${APP.FONT.FONT_COLOR.PRIMARY};
`;

export const SetTimeOffEditAndCancelContainer = styled.div`
  display: flex;
`;

export const EditButton = styled(Button)`
  text-transform: capitalize;
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_17PX};
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  text-decoration: underline;
`;

export const CancelButton = styled(Button)`
  text-transform: capitalize;
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_17PX};
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  text-decoration: underline;
`;

export const LoadingGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5rem;
`;

export const DivContainer = styled.div``;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-content: center;
  width: 100%;
  position: absolute;
  top: 30px;
`;

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const ProfileAvatarContainer = styled.div`
  position: relative;
  height: 6rem;
  width: 6.55rem;
`;

export const LoadingGridForProfilePic = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  width: 6.55rem;
  margin-top: 1.5rem;
`;

export const VersionDetailsGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 0.45rem;
`;

export const VersionTypography = styled(Typography)`
  font-size: ${APP.FONT.FONT_SIZE.CAPTION};
  color: ${APP.PALETTE.GREY.SHADE_600};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
  letter-spacing: 0.08rem;
`;
