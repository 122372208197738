interface ILocation {
  search: string;
  pathname: string;
}

export interface IQueryParamsList {
  queryParamName: string;
  queryParamValue: string;
}

export const setURLWithParams = (
  location: ILocation | null,
  queryParamsList: IQueryParamsList[],
  destinationURL?: string,
): string => {
  let newUrlWithQueryParams = '';

  if (location) {
    const { pathname, search } = location;
    // existing URL path with query string
    newUrlWithQueryParams = pathname + search;

    if (queryParamsList.length > 0) {
      const params = new URLSearchParams(search);

      queryParamsList.forEach(queryParam => {
        params.set(queryParam.queryParamName, queryParam.queryParamValue);
      });

      newUrlWithQueryParams = `${pathname}?${params.toString()}`;
    }
  } else if (destinationURL) {
    const params = new URLSearchParams();
    if (destinationURL.includes('?')) {
      // If destinationURL already contains query parameters
      const existingParams = new URLSearchParams(destinationURL.split('?')[1]);
      existingParams.forEach((value, key) => {
        params.set(key, value);
      });
    }
    queryParamsList.forEach(queryParam => {
      params.set(queryParam.queryParamName, queryParam.queryParamValue);
    });
    newUrlWithQueryParams = `${
      destinationURL.split('?')[0]
    }?${params.toString()}`;
  }

  return newUrlWithQueryParams;
};
