import { ReactElement } from 'react';
import { CustomClearIcon, CustomTextInputDiv } from './SearchBarStyle';
import { InputAdornment, TextFieldProps } from '@mui/material';
import { Search } from '@mui/icons-material';
import { t } from 'i18next';

interface ISearchBarProps extends Omit<TextFieldProps, 'variant'> {
  label: string;
  variant?: 'filled' | 'outlined' | 'standard';
  height?: number;
}

const SearchBar = (props: ISearchBarProps): ReactElement => {
  const { label, height, value, onChange, ...rest } = props;

  return (
    <div>
      <CustomTextInputDiv
        height={height}
        {...rest}
        variant="outlined"
        label={label}
        margin="none"
        placeholder={t('PRIVATE.EMAILS.SEARCH')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: value ? (
            <CustomClearIcon
              className="icon"
              onClick={() => {
                // clearSearchedText && clearSearchedText();
                const syntheticEvent = { target: { value: '' } };
                onChange &&
                  onChange(
                    syntheticEvent as React.ChangeEvent<HTMLInputElement>,
                  );
              }}
            />
          ) : null,
        }}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchBar;
