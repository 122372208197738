import { baseUrl } from '../../common/ApiEndPoint';

const usersBaseUrl = `${baseUrl}/users`;

export const getAllUsersByServicePrincipalIdFromAzureUrl = `${usersBaseUrl}/azure`;
export const getAllAzureUsersFromOurSystemUrl = `${usersBaseUrl}/all`;
export const getUserDetailsByPrincipalIdFromAzureUrl = `${usersBaseUrl}/azure/:id`;
export const getLoggedInUserDetailsViaMeApiUrl = `${usersBaseUrl}/me`;
export const getUserProfileByIdUrl = `${usersBaseUrl}/:id`;
export const updateUserProfileByIdUrl = `${usersBaseUrl}/:id`;
export const updateUserActivityStatusByIdUrl = `${usersBaseUrl}/status/:id`;
export const createUserTimeOffByIdUrl = `${usersBaseUrl}/:id/time-off`;
export const updateUserTimeOffByTimeOffIdUrl = `${usersBaseUrl}/:id/time-off/:timeOffId`;
export const deleteUserTimeOffByTimeOffIdURL = `${usersBaseUrl}/:id/time-off/:timeOffId`;
export const signUpNewUserFromAzureUrl = `${usersBaseUrl}/add-user`;
