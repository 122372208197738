import { useRef, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useIsAuthenticated } from '@azure/msal-react';
import { Grid, IconButton } from '@mui/material';
import AuthUtils from '@utils/AuthUtils';
import useTranslation from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { logout, setUserType } from '@modules/home/state/auth/AuthStateSlice';
import { useNavigate } from 'react-router-dom';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import { useSnackbar } from 'notistack';
import {
  ProfileContainer,
  ProfileCard,
  ProfileHeading,
  DetailsContainer,
  FooterDetails,
  PrivacyPolicyLinks,
  CustomLink,
  ProfileNameGrid,
  ProfileEmailGrid,
  ProfilePhoneGrid,
  ProfileEditContainer,
  LogoutButton,
  ProfileAvatar,
  EmailTypography,
  LoadingGrid,
  ButtonsContainer,
  VisuallyHiddenInput,
  ProfileAvatarContainer,
  LoadingGridForProfilePic,
  VersionDetailsGrid,
  VersionTypography,
  MainProfileContainer,
} from './ProfileStyle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getErrorMessage } from '@utils/ErrorUtils';
import {
  getSetTimeOffs,
  addNewTimeOff,
  deleteTimeOff,
  getProfileDetails,
  updateTimeOff,
  uploadProfilePicture,
  deleteProfilePicture,
} from 'src/services/service-handlers/private/profile-details-handlers/ProfileDetailsPrivateService';
import Loader from '@components/loaders/Loader';
import { RootState } from 'src/store/Store';
import {
  setProfileData,
  setTimeOffs,
  removeProfilePicture,
  setProfilePictureId,
  setIsProfilePictureLoading,
} from '@modules/home/state/profile-details/ProfileDetailsSlice';
import TimeOff from '@components/timeOff/TimeOff';
import {
  MAX_FILE_SIZE_FOR_PROFILE_PIC,
  VALIDATION_IMAGE_CONSTANTS,
} from '@constants/global-constants/constants';
import appVersion from 'src/appVersion';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import moment from 'moment';

const Profile = (): JSX.Element => {
  const { instance } = useMsal();
  const { t } = useTranslation;
  const { enqueueSnackbar } = useSnackbar();
  const isAzureAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  const {
    id,
    name,
    email,
    phone,
    timeOffs,
    title,
    profilePic,
    profilePicId,
    isProfilePictureLoading,
    isProfileDetailsLoading,
    userType,
  } = useSelector((state: RootState) => state.profile);

  const handleEditClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current?.click();
    }
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const fetchProfileDetails = async () => {
    try {
      // Fetch profile details
      const response = await getProfileDetails();
      const { data } = response;
      // Update profile data in Redux store
      dispatch(setProfileData(data));
      dispatch(setProfilePictureId(data.profilePicId));
    } catch (errorResponse) {
      // Handle error
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    }
  };

  const init = async () => {
    await fetchProfileDetails();
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const generateRandomUUId = () => {
    const generatedUUID = Math.random().toString();
    return generatedUUID;
  };

  const handleDeleteClick = async () => {
    // Remove the profile picture and reset the state
    if (profilePicId) {
      dispatch(setIsProfilePictureLoading(true));
      try {
        await deleteProfilePicture(id, profilePicId);
        enqueueSnackbar(
          t('ADMIN.PROFILE_PICTURE_VALIDATION.PROFILE_PICTURE_DELETE'),
          SnackBarConfig.getSuccess(),
        );
        dispatch(removeProfilePicture());
      } catch (error) {
        const errMessage = getErrorMessage(error);
        enqueueSnackbar(errMessage, SnackBarConfig.getError());
      } finally {
        dispatch(setIsProfilePictureLoading(false));
        resetFileInput();
      }
    }
  };

  const handleChangeImg = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsHovered(false);
      const validImageTypes = [
        VALIDATION_IMAGE_CONSTANTS.JPEG,
        VALIDATION_IMAGE_CONSTANTS.JPG,
        VALIDATION_IMAGE_CONSTANTS.WEBP,
        VALIDATION_IMAGE_CONSTANTS.PNG,
      ];
      if (!validImageTypes.includes(file.type)) {
        const errMessage = t(
          'ADMIN.PROFILE_PICTURE_VALIDATION.IMAGE_TYPE_ERROR',
        );
        enqueueSnackbar(errMessage, SnackBarConfig.getError());
        return;
      }

      const maxSizeInBytes =
        MAX_FILE_SIZE_FOR_PROFILE_PIC.MAX_FILE_SIZE_ALLOWED;
      if (file.size > maxSizeInBytes) {
        const errMessage = t(
          'ADMIN.PROFILE_PICTURE_VALIDATION.IMAGE_SIZE_ERROR',
        );
        enqueueSnackbar(errMessage, SnackBarConfig.getError());
        return;
      }
      dispatch(setIsProfilePictureLoading(true));
      try {
        // Upload profile picture
        await uploadProfilePicture(id, file);
        enqueueSnackbar(
          t('ADMIN.PROFILE_PICTURE_VALIDATION.PROFILE_PICTURE_UPLOAD_SUCCESS'),
          SnackBarConfig.getSuccess(),
        );
      } catch (error) {
        dispatch(setIsProfilePictureLoading(false));
        const errMessage = getErrorMessage(error);
        enqueueSnackbar(errMessage, SnackBarConfig.getError());
      } finally {
        init();
      }
    }
  };

  const addNewTimeOffApi = async (startDate: Date, endDate: Date) => {
    if (startDate <= endDate) {
      try {
        const addNewTimeOffPayload = {
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD'),
        };
        const addNewTimeOffApiResponse = await addNewTimeOff(
          addNewTimeOffPayload,
          id,
        );
        const getTimeOffsResponse = await getSetTimeOffs(id);
        const timeOffsList = getTimeOffsResponse.data.rows ?? [];
        dispatch(setTimeOffs({ timeOffs: timeOffsList }));
        const successMessage =
          addNewTimeOffApiResponse?.data?.message ??
          t('ADMIN.USERS_TAB.USER_SETTINGS.DEFAULT_ADDED_NEW_TIME_OFF');
        enqueueSnackbar(successMessage, SnackBarConfig.getSuccess());
      } catch (errorResponse) {
        const errMessage = getErrorMessage(errorResponse);
        enqueueSnackbar(errMessage, SnackBarConfig.getError());
      }
    } else {
      const ErrorMessage = t(
        'ADMIN.USERS_TAB.ADD_USER_FORM.TIME_OFF_VALIDATIONS.END_DATE_SHOULD_GREATER',
      );
      enqueueSnackbar(ErrorMessage, SnackBarConfig.getError());
    }
  };

  const updateTimeOffApi = async (
    startDate: Date,
    endDate: Date,
    editIndex: number,
  ) => {
    if (editIndex !== null) {
      const timeOffToUpdate = timeOffs[editIndex];
      try {
        const updateTimeOffPayload = {
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD'),
        };
        // Update the time off entry
        const updateTimeOffApiResponse = await updateTimeOff(
          updateTimeOffPayload,
          id,
          timeOffToUpdate.id,
        );

        const getTimeOffsResponse = await getSetTimeOffs(id);
        const timeOffsList = getTimeOffsResponse.data.rows;
        if (timeOffsList && timeOffsList.length > 0) {
          dispatch(setTimeOffs({ timeOffs: timeOffsList }));
        } else {
          dispatch(setTimeOffs({ timeOffs: [] }));
        }
        // Show success message
        const successMessage =
          updateTimeOffApiResponse?.data?.message ??
          t('ADMIN.USERS_TAB.USER_SETTINGS.DEFAULT_UPDATED_TIME_OFF');
        enqueueSnackbar(successMessage, SnackBarConfig.getSuccess());
      } catch (errorResponse) {
        const errMessageResponse =
          getErrorMessage(errorResponse) ??
          t(
            'ADMIN.USERS_TAB.ADD_USER_FORM.TIME_OFF_VALIDATIONS.START_DATE_CANNOT_BE_CHANGED',
          );
        enqueueSnackbar(errMessageResponse, SnackBarConfig.getError());
      }
    }
  };

  const deleteTimeOffApi = async (
    index: number,
    UserId: string,
    timeOffId: string,
  ) => {
    try {
      // delete time off
      const deleteTimeOffApiResponse = await deleteTimeOff(UserId, timeOffId);
      const getTimeOffsResponse = await getSetTimeOffs(id);
      const timeOffsList = getTimeOffsResponse.data.rows;
      dispatch(setTimeOffs({ timeOffs: timeOffsList }));

      const successMessage =
        deleteTimeOffApiResponse?.data?.message ??
        t('ADMIN.USERS_TAB.USER_SETTINGS.DEFAULT_DELETED_TIME_OFF');
      enqueueSnackbar(successMessage, SnackBarConfig.getSuccess());
    } catch (errorResponse) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    }
  };

  const handleLogout = () => {
    try {
      if (isAzureAuthenticated) {
        // Perform logout redirect
        AuthUtils.clearAuthToken();
        AuthUtils.clearUserType();
        dispatch(logout());
        dispatch(setUserType(''));
        instance.logoutPopup();
      }
      // Clear access token from localStorage
      AuthUtils.clearAuthToken();
      navigate(PrivateNavigationRoutes.Home.Path);
      dispatch(logout());
      dispatch(setUserType(''));
      AuthUtils.clearUserType();
    } catch (errorResponse) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      dispatch(removeProfilePicture());
      dispatch(setProfilePictureId(''));
    }
  };

  // Profile Details
  const renderProfileDetails = () => {
    return (
      <>
        <ProfileEditContainer>
          <ProfileHeading>{t('PRIVATE.PROFILE.HEADING')}</ProfileHeading>
        </ProfileEditContainer>
        <ProfileAvatarContainer
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {isProfilePictureLoading || isProfileDetailsLoading ? (
            <LoadingGridForProfilePic>
              <Loader size={40} />
            </LoadingGridForProfilePic>
          ) : (
            <>
              <ProfileAvatar
                key={profilePicId}
                src={profilePic}
                hovered={isHovered ? 'yes' : 'no'}
              />
              {isHovered && (
                <ButtonsContainer>
                  <IconButton
                    size="large"
                    aria-label="Edit"
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon />
                  </IconButton>
                  {profilePic && (
                    <IconButton
                      aria-label="Delete"
                      size="large"
                      onClick={() => handleDeleteClick()}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </ButtonsContainer>
              )}
            </>
          )}
          <VisuallyHiddenInput
            id={generateRandomUUId()}
            ref={fileInputRef}
            accept=".jpg,.jpeg, .webp, .png"
            type="file"
            onChange={handleChangeImg}
          />
        </ProfileAvatarContainer>

        {isProfileDetailsLoading ? (
          <LoadingGrid>
            <Loader size={40} />
          </LoadingGrid>
        ) : (
          <>
            <ProfileNameGrid container>
              <Grid item xs={4.5}>
                {t('PRIVATE.PROFILE.NAME')}
              </Grid>
              <Grid item xs={6} sm={7} xl={7.5}>
                {name}
              </Grid>
            </ProfileNameGrid>

            <ProfileEmailGrid container>
              <Grid item xs={4.5}>
                {t('PRIVATE.PROFILE.EMAIL')}
              </Grid>
              <Grid item xs={4.5} sm={6}>
                <EmailTypography>{email}</EmailTypography>
              </Grid>
            </ProfileEmailGrid>

            <ProfilePhoneGrid container>
              <Grid item xs={4.5}>
                {t('PRIVATE.PROFILE.PHONE_NUMBER')}
              </Grid>
              <Grid item xs={5} sm={4} xl={7.5}>
                {phone ?? '-'}
              </Grid>
            </ProfilePhoneGrid>

            <ProfilePhoneGrid container>
              <Grid item xs={4.5}>
                {t('PRIVATE.PROFILE.TITLE')}
              </Grid>
              <Grid item xs={5} sm={4} xl={7.5}>
                {title.label}
              </Grid>
            </ProfilePhoneGrid>
          </>
        )}
      </>
    );
  };

  // Footer Section
  const renderProfileFooter = () => {
    return (
      <FooterDetails>
        <PrivacyPolicyLinks>
          <CustomLink to={''}>
            {t('PRIVATE.PROFILE.TERMS&CONDITIONS')}
          </CustomLink>
          <CustomLink to={''}>{t('PRIVATE.PROFILE.PRIVACY_POLICY')}</CustomLink>
        </PrivacyPolicyLinks>
        <LogoutButton onClick={handleLogout}>
          {t('PRIVATE.PROFILE.LOG_OUT')}
        </LogoutButton>
      </FooterDetails>
    );
  };

  return (
    <MainProfileContainer
      isAdmin={userType?.toLowerCase() === AdminConstants.ADMIN.toLowerCase()}
    >
      <ProfileContainer
        isAdmin={userType?.toLowerCase() === AdminConstants.ADMIN.toLowerCase()}
      >
        <ProfileCard>
          {renderProfileDetails()}
          <DetailsContainer>
            {
              <TimeOff
                isTopMarginRequired={true}
                isDividerVisible={true}
                disabled={false}
                selectedDateValue={null}
                timeOffIds={timeOffs}
                userId={id}
                addNewTimeOffApi={addNewTimeOffApi}
                updateTimeOffApi={updateTimeOffApi}
                deleteTimeOffApi={deleteTimeOffApi}
              />
            }
            {renderProfileFooter()}
          </DetailsContainer>
        </ProfileCard>
        <VersionDetailsGrid>
          <VersionTypography>
            {t('ADMIN.COMMON.VERSION')}
            {appVersion}
          </VersionTypography>
        </VersionDetailsGrid>
      </ProfileContainer>
    </MainProfileContainer>
  );
};

export default Profile;
