import React, { useState, useEffect, useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef as MRTColumnDef,
  type MRT_RowData as MRTRowData,
  type MRT_TableOptions as MRTTableOptions,
  MRT_DisplayColumnDef as MRTDisplayColumnDef,
  MRT_ExpandedState as MRTExpandedState,
} from 'material-react-table';
import { APP } from 'src/styles/variables';
import { Box } from '@mui/material';

interface ICustomDataGridProps<TData extends MRTRowData>
  extends MRTTableOptions<TData> {
  columns: MRTColumnDef<TData>[];
  data: TData[];
  state?: any;
  setExpanded?: (expanded: MRTExpandedState) => void;
  isSubRowsPresent?: boolean;
  displayColumnDefOptions?: MRTDisplayColumnDef<TData> | any;
}

const CustomDataGrid = <TData extends MRTRowData>(
  props: ICustomDataGridProps<TData>,
): React.ReactElement => {
  const {
    columns,
    data,
    displayColumnDefOptions,
    state,
    setExpanded: setExpandedHandle,
    isSubRowsPresent,
    ...rest
  } = props;
  const [expanded, setExpanded] = useState<MRTExpandedState>({});

  useEffect(() => {
    if (setExpandedHandle) setExpandedHandle(expanded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const defaultConfig = {
    muiTableHeadCellProps: {
      sx: {
        color: APP.PALETTE.GREY.GRAY_COLUMN,
        fontSize: APP.FONT.FONT_SIZE.BODY2,
        fontWeight: APP.FONT.FONT_WEIGHT.BODY1,
        backgroundColor: APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY,
      },
    },
  };

  // Merge defaultConfig with each column definition
  const mergedColumnsData = columns.map(column => ({
    ...defaultConfig,
    ...column,
  }));

  // render only the top level rows
  const rootData = useMemo(() => {
    if (data && data.length) return data.filter(r => !r?.categoryId ?? '');
    return [];
  }, [data]);

  const table = useMaterialReactTable({
    columns: mergedColumnsData,
    data: isSubRowsPresent ? rootData : data,
    getRowId: row => row?.id ?? row?.typeId,
    enableTopToolbar: false, // hide top toolbar
    enableStickyHeader: true,
    enableColumnActions: false,
    enableRowSelection: true,
    enableSelectAll: true,
    enableRowActions: true,
    positionActionsColumn: 'last',
    enableBottomToolbar: false,
    enableGlobalFilterModes: true,
    enablePagination: false,
    enableRowVirtualization: true,
    onExpandedChange: setExpanded,
    state: {
      expanded,
      ...state,
    },
    muiTableHeadProps: {
      sx: theme => ({
        opacity: 1,
      }),
    },
    muiTableHeadCellProps: {
      sx: theme => ({
        backgroundColor: APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY,
        cursor: 'pointer',
      }),
    },
    muiTableBodyCellProps: {
      sx: theme => ({
        cursor: 'pointer',
      }),
    },
    muiSelectCheckboxProps: {
      sx: theme => ({
        color: APP.PALETTE.GREY.GRAY_COLUMN,
        '&.Mui-checked': {
          color: APP.PALETTE.SECONDARY.MAIN,
        },
      }),
    },
    muiSelectAllCheckboxProps: {
      sx: theme => ({
        borderLeft: '12px solid transparent',
        borderRadius: 0,
        color: APP.PALETTE.GREY.GRAY_COLUMN,
        '&.MuiCheckbox-indeterminate': {
          color: APP.PALETTE.SECONDARY.MAIN,
        },
        '&.Mui-checked': {
          color: APP.PALETTE.SECONDARY.MAIN,
        },
      }),
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '',
        grow: false,
      },
      ...displayColumnDefOptions,
    },
    ...rest,
  });

  return (
    <Box>
      <MaterialReactTable table={table} />
    </Box>
  );
};

export default CustomDataGrid;
