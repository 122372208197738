import { ChangeEvent, ReactElement, useState } from 'react';
import {
  Checkbox as CheckboxInput,
  FormControlLabel,
  SxProps,
} from '@mui/material';
import { CheckboxWrapperDiv, LabelTypography } from './CheckboxStyle';

interface CheckboxProps {
  label: string;
  size?: 'small' | 'medium';
  indeterminate?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  sx?: SxProps;
}

const Checkbox = (props: CheckboxProps): ReactElement => {
  const {
    label,
    size = 'medium',
    checked = false,
    indeterminate = false,
    disabled = false,
    onChange,
    sx,
    ...rest
  } = props;

  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(event.target.checked);
    setIsChecked(!isChecked);
  };

  return (
    <CheckboxWrapperDiv>
      <FormControlLabel
        label={<LabelTypography>{label}</LabelTypography>}
        control={
          <CheckboxInput
            {...rest}
            size={size}
            disabled={disabled}
            indeterminate={indeterminate}
            checked={checked}
            disableRipple
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleOnChange(e);
            }}
            sx={{ height: '1.56rem', ...sx } ?? {}}
          />
        }
      />
    </CheckboxWrapperDiv>
  );
};

export default Checkbox;
