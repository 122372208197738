import Storage from './Storage';

class AuthUtils {
  static getAuthToken(): any {
    return Storage.getItem(Storage.KEYS.AUTH_TOKEN);
  }

  static clearAuthToken(): void {
    Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
  }

  static setAuthToken(token: any): void {
    this.clearAuthToken();
    Storage.setItem(Storage.KEYS.AUTH_TOKEN, token);
  }

  static clearStorage(): void {
    Storage.clear();
  }

  static getIsAzureAuthenticated(): any {
    return Storage.getItem(Storage.KEYS.IS_AZURE_AUTHENTICATED);
  }

  static clearIsAzureAuthenticated(): void {
    Storage.removeItem(Storage.KEYS.IS_AZURE_AUTHENTICATED);
  }

  static setIsAzureAuthenticated(isAzureAuthenticated: boolean): void {
    this.clearIsAzureAuthenticated();
    Storage.setItem(Storage.KEYS.IS_AZURE_AUTHENTICATED, isAzureAuthenticated);
  }

  static getUserType(): any {
    return Storage.getItem(Storage.KEYS.USER_TYPE);
  }

  static clearUserType(): void {
    Storage.removeItem(Storage.KEYS.USER_TYPE);
  }

  static setUserType(userType: string): void {
    this.clearUserType();
    Storage.setItem(Storage.KEYS.USER_TYPE, userType);
  }
}

export default AuthUtils;
