import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { APP } from 'src/styles/variables';

export const CheckboxWrapperDiv = styled('div')`
  width: fit-content;
  height: fit-content;
`;

export const LabelTypography = styled(Typography)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_17PX};
  color: ${APP.PALETTE.TEXT.PRIMARY};
  padding-top: 0.22rem;
  letter-spacing: 0.02rem;
`;
