import ServiceRequest from 'src/services/ServiceRequest';
import {
  getProfileDetailsUrl,
  updateTimeOffUrl,
  addTimeOffUrl,
  deleteTimeOffUrl,
  getTimeOffsUrl,
  updateProfilePictureUrl,
  getProfilePictureUrl,
  deleteProfilePictureUrl,
} from './ProfileDetailsEndPoints';
import {
  AddUserTimeOffByIdUserResDto,
  DeleteProfilePictureResDto,
  DeleteUserTimeOffByIdUserResDto,
  EditUserTimeOffByIdUserResDto,
  GetLoggedUserResDto,
  GetProfilePictureResDto,
  GetUserProfileQueryParam,
  GetUserTimeOffByIdResDto,
  GetUserTimeOffQueryParam,
  SetTimeOffEditParams,
  SetTimeOffPostParams,
  UpdateProfilePictureResDto,
} from './ProfileDetailsServiceMapper';

export const getProfileDetails = async (): Promise<GetLoggedUserResDto> => {
  return await ServiceRequest.get(getProfileDetailsUrl);
};

// get TimeOffs
export const getSetTimeOffs = async (
  id: string,
  data?: GetUserTimeOffQueryParam,
): Promise<GetUserTimeOffByIdResDto> => {
  return await ServiceRequest.get(getTimeOffsUrl.replace(':id', id), {
    payload: data,
  });
};

// add TimeOffs
export const addNewTimeOff = async (
  data: SetTimeOffPostParams,
  id: string,
): Promise<AddUserTimeOffByIdUserResDto> => {
  return await ServiceRequest.post(addTimeOffUrl.replace(':id', id), {
    payload: data,
  });
};

// update TimeOffs
export const updateTimeOff = async (
  data: SetTimeOffEditParams,
  id: string,
  timeOffId: string,
): Promise<EditUserTimeOffByIdUserResDto> => {
  return await ServiceRequest.put(
    updateTimeOffUrl.replace(':id', id).replace(':timeOffId', timeOffId),
    { payload: data },
  );
};

// delete TimeOffs
export const deleteTimeOff = async (
  id: string,
  timeOffId: string,
): Promise<DeleteUserTimeOffByIdUserResDto> => {
  return await ServiceRequest.delete(
    deleteTimeOffUrl.replace(':id', id).replace(':timeOffId', timeOffId),
  );
};

// upload Profile Picture
export const uploadProfilePicture = async (
  id: string,
  file: File,
): Promise<UpdateProfilePictureResDto> => {
  // Update response type when api is available
  const formData = new FormData();
  formData.append('profilePic', file);

  return await ServiceRequest.postDoc(
    updateProfilePictureUrl.replace(':id', id),
    { payload: formData },
  );
};

export const getProfilePicture = async (
  id: string,
  data?: GetUserProfileQueryParam,
): Promise<GetProfilePictureResDto> => {
  return await ServiceRequest.get(getProfilePictureUrl.replace(':id', id), {
    payload: data,
  });
};

export const deleteProfilePicture = async (
  id: string,
  profilePicId: string,
): Promise<DeleteProfilePictureResDto> => {
  return await ServiceRequest.delete(
    deleteProfilePictureUrl
      .replace(':id', id)
      .replace(':profilePicId', profilePicId),
  );
};
