import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  UsersStateType,
  IsSearchAndFilterAppliedPayloadType,
  ShouldReloadUsersTableGridPayloadType,
  UsersTableNamePrefixPayload,
  UserSearchAndFilterPayloadType,
} from './UsersTypes';
import { generateRandomTableName } from '@utils/miscellaneousUtils';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';

const initialState: UsersStateType = {
  shouldReloadUsersTableGrid: false,
  fetchUsersDataFnQueryKeyName: AdminConstants.USERS.USERS_DATA,
  userSearchAndFilterOptions: {},
  isSearchAndFilterOptionsApplied: false,
};

const UsersSlice = createSlice({
  initialState,
  name: 'users',
  reducers: {
    setUserSearchAndFilterValues(
      state,
      action: PayloadAction<UserSearchAndFilterPayloadType>,
    ) {
      state.userSearchAndFilterOptions = action.payload.data;
    },
    setIsSearchAndFilterApplied(
      state,
      action: PayloadAction<IsSearchAndFilterAppliedPayloadType>,
    ) {
      state.isSearchAndFilterOptionsApplied =
        action.payload.isSearchAndFilterApplied;
    },
    setShouldReloadUsersTableGrid(
      state,
      action: PayloadAction<ShouldReloadUsersTableGridPayloadType>,
    ) {
      state.shouldReloadUsersTableGrid =
        action.payload.shouldReloadUsersTableGridPay;
    },
    changeUsersTableName(
      state,
      action: PayloadAction<UsersTableNamePrefixPayload>,
    ) {
      state.fetchUsersDataFnQueryKeyName = generateRandomTableName(
        action.payload.usersTableNamePrefix,
      );
      state.shouldReloadUsersTableGrid = false;
    },
    resetUsersState() {
      return initialState;
    },
  },
});

export const {
  setIsSearchAndFilterApplied,
  setShouldReloadUsersTableGrid,
  changeUsersTableName,
  resetUsersState,
  setUserSearchAndFilterValues,
} = UsersSlice.actions;

export default UsersSlice.reducer;
