import { ReactElement } from 'react';

import { t } from 'i18next';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import UsersTable from '../users-table/UsersTable';
import { KebabMenuItemType } from 'src/types/adminTypes';
import { fetchAllUsersFromOurSystem } from 'src/services/service-handlers/private/users-handlers/UsersPrivateService';

const ActiveUsersTable = (): ReactElement => {
  const kebabMenuItemsList: KebabMenuItemType[] = [
    {
      id: 1,
      label: t('ADMIN.USERS_TAB.VIEW_PROFILE'),
      value: AdminConstants.USERS.USERS_KEBAB_MENU_OPTIONS.VIEW_PROFILE,
    },
    {
      id: 2,
      label: t('ADMIN.USERS_TAB.MARK_INACTIVE'),
      value: AdminConstants.USERS.USERS_KEBAB_MENU_OPTIONS.MARK_INACTIVE,
    },
  ];

  return (
    <UsersTable
      kebabMenuItemsList={kebabMenuItemsList}
      fetchUsers={fetchAllUsersFromOurSystem}
      fetchDataFnQueryKeyName={AdminConstants.USERS.ACTIVE_USERS_DATA}
      setLimitOffsetAndTotal={() => null}
    />
  );
};

export default ActiveUsersTable;
