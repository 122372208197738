import {
  CancelButtonStyled,
  SaveButtonStyled,
} from '@components/dialog/form-dialog/FormDialogStyle';
import styled from '@emotion/styled';
import { DialogActions } from '@mui/material';

export const SaveButtonWidthStyled = styled(SaveButtonStyled)`
  width: 15.5rem;
`;
export const CancelButtonWidthStyled = styled(CancelButtonStyled)`
  width: 15.5rem;
`;

export const DialogActionsContainerStyled = styled(DialogActions)`
  justify-content: center;
  padding-top: 1rem;
`;
