import React, { ReactElement, ReactNode } from 'react';
import { TopBarGrid } from './TopBarWrapperGridStyle';

interface TopBarWrapperGidProps {
  children: ReactNode;
  alignItems?: string;
  justifyContent?: string;
  height: string;
}

const TopBarWrapperGrid = ({
  children,
  alignItems,
  justifyContent,
  height,
}: TopBarWrapperGidProps): ReactElement => {
  return (
    <TopBarGrid
      alignItems={alignItems}
      justifyContent={justifyContent}
      height={height}
    >
      {children}
    </TopBarGrid>
  );
};

export default TopBarWrapperGrid;
