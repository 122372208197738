import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  MRT_RowVirtualizer as MRTRowVirtualizer,
  type MRT_ColumnDef as MRTColumnDef,
  MRT_TableOptions as MRTTableOptions,
  MRT_RowData as MRTRowData,
  MRT_Column as MRTColumn,
  MRT_TableInstance as MRTTableInstance,
  MRT_SortingState as MRTSortingState,
  MRT_RowSelectionState as MRTRowSelectionState,
} from 'material-react-table';
import { TableCellProps } from '@mui/material';
import { APP } from 'src/styles/variables';

import { useInfiniteQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import CustomDataGrid from './CustomDataGrid';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/Store';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';

interface IServerSideDataGridProps<TData extends MRTRowData>
  extends Omit<MRTTableOptions<TData>, 'data'> {
  columns: MRTColumnDef<TData>[];
  fetchDataFn: (
    pageParam: number,
    fetchSize: number,
    sorting?: MRTSortingState,
  ) => Promise<TData[] | any>;
  fetchDataFnQueryKeyName?: string;
  columnFilters?: Array<any> | null;
  globalFilter?: string;
  dataFetchSize?: number;
  totalRowsCount: number;
  data?: TData[];
  onSortChange?: (data: any) => void;
  muiTableHeadCellProps?:
    | TableCellProps
    | ((props: {
        column: MRTColumn<TData>;
        table: MRTTableInstance<TData>;
      }) => TableCellProps)
    | undefined;
  enableRowSelection?: boolean;
  enableRowActions?: boolean;
  isDataLoading?: boolean;
  showBottomProgressBar?: boolean;
  enableBottomToolbar?: boolean;
  rowSelection?: MRTRowSelectionState;
  isUserPortal?: boolean;
  isUpdatedDataLoading?: boolean;
}

const ServerSideDataGrid = <TData extends MRTRowData>({
  columns,
  fetchDataFn,
  fetchDataFnQueryKeyName = 'table-data',
  columnFilters = [],
  globalFilter = '',
  totalRowsCount,
  dataFetchSize = 50,
  onSortChange,
  rowSelection,
  muiTableHeadCellProps = undefined,
  enableRowSelection = false,
  enableRowActions = false,
  isDataLoading = undefined,
  showBottomProgressBar = undefined,
  enableBottomToolbar = false,
  isUserPortal = false,
  isUpdatedDataLoading = false,
  ...rest
}: IServerSideDataGridProps<TData>): React.ReactElement => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef = useRef<MRTRowVirtualizer>(null);
  const fetchSize = dataFetchSize;
  const [sorting, setSorting] = useState<MRTSortingState>([]);

  const { updatedStatusesForAssigneeChange } = useSelector(
    (state: RootState) => state.queue,
  );

  const { data, fetchNextPage, isError, isFetching } = useInfiniteQuery({
    queryKey: [fetchDataFnQueryKeyName, columnFilters, globalFilter, sorting],
    queryFn: ({ pageParam }) => fetchDataFn(pageParam, fetchSize, sorting),

    initialPageParam: 0,
    getNextPageParam: (_lastGroup, groups) => groups.length,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    onSortChange?.(sorting);
  }, [onSortChange, sorting]);

  const flatData = useMemo(() => {
    const flatDataPages = data?.pages.flat() || [];

    if (updatedStatusesForAssigneeChange) {
      const updatedData = flatDataPages.map(item => {
        if (item && item.id) {
          const newStatusToUpdate = updatedStatusesForAssigneeChange[item.id];
          const flatDataItem = flatDataPages.find(
            flatDataEachItem => flatDataEachItem.id === item.id,
          );

          if (
            flatDataItem &&
            flatDataItem.status !== AdminConstants.QUEUE.ASSIGNED &&
            newStatusToUpdate
          ) {
            return { ...item, status: newStatusToUpdate };
          }
        }
        return item;
      });

      // Check if the data has been updated
      const isDataUpdated = updatedData.some((item, index) => {
        const originalDataItem = flatDataPages[index];
        return (
          item && originalDataItem && item.status !== originalDataItem.status
        );
      });

      if (isDataUpdated) {
        return updatedData;
      }
    }

    return flatDataPages;
  }, [data, updatedStatusesForAssigneeChange]);

  const totalDBRowCount = totalRowsCount;
  const totalFetched = flatData.length;

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 600 &&
          !isFetching &&
          totalFetched < totalDBRowCount
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
  );

  useEffect(() => {
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      //
    }
  }, []);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const defaultConfig = {
    muiTableHeadCellProps: muiTableHeadCellProps ?? {
      sx: {
        color: APP.PALETTE.GREY.GRAY_COLUMN,
        fontSize: APP.FONT.FONT_SIZE.BODY2,
        fontWeight: APP.FONT.FONT_WEIGHT.BODY1,
        backgroundColor: APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY,
      },
    },
  };

  const mergedColumnsData = columns.map(column => ({
    ...defaultConfig,
    ...column,
  }));

  return (
    <CustomDataGrid
      {...rest}
      columns={mergedColumnsData}
      enableRowActions={enableRowActions}
      enableRowVirtualization={true}
      enableBottomToolbar={enableBottomToolbar}
      muiTableContainerProps={{
        sx: {
          maxHeight: isUserPortal
            ? 'calc(100vh - 8.75rem)'
            : 'calc(100vh - 5rem)',
        },
        ref: tableContainerRef,
        onScroll: (event: any) =>
          fetchMoreOnBottomReached(event.target as HTMLDivElement),
      }}
      manualSorting={true}
      onSortingChange={value => setSorting(value)}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: t('PRIVATE.EMAILS.QUEUE_TABLE.ERROR_LOADING_DATA'),
            }
          : undefined
      }
      state={{
        rowSelection: rowSelection || {},
        columnFilters: columnFilters || [],
        globalFilter: globalFilter || '',
        isLoading: isFetching || isUpdatedDataLoading,
        showAlertBanner: isError,
        showProgressBars: showBottomProgressBar ?? isFetching,
        sorting: sorting || [],
      }}
      rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
      rowVirtualizerOptions={{ overscan: 4 }}
      data={flatData}
      enableRowSelection={enableRowSelection}
    />
  );
};

export default ServerSideDataGrid;
