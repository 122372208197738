import { baseUrl } from '../../common/ApiEndPoint';

// emails api urls
const emailsBaseUrl = `${baseUrl}/emails`;
export const getEmailDetailsByIdUrl = `${emailsBaseUrl}/:id`;
export const getEmailIdentifiersByIdUrl = `${emailsBaseUrl}/:id/identifiers`;
export const getAllEmailsUrl = `${emailsBaseUrl}/all`;
export const receiveAndStoreEmailsInOurSystemUrl = `${emailsBaseUrl}/receive-email`;

// training-email api urls
const trainingEmailsBaseUrl = `${baseUrl}/training-emails`;
export const getAllEmailSettingsURL = `${trainingEmailsBaseUrl}/all`;
export const getEmailSettingsDetailURL = `${trainingEmailsBaseUrl}`;
export const updateEmailSettingsDetailURL = `${trainingEmailsBaseUrl}`;
