import styled from '@emotion/styled';
import {Button} from '@mui/material';

export const CustomTextButton = styled(Button)`
  font-family: ${({theme}) => theme.typography.fontFamily};
  text-transform: capitalize;
  font-size: ${({fontSize}: {fontSize: string | number}) => fontSize || '1rem'};
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;
