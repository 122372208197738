import {
  CancelButtonStyled,
  SaveButtonStyled,
} from '@components/dialog/form-dialog/FormDialogStyle';
import styled from '@emotion/styled';

export const SaveButtonWidthStyled = styled(SaveButtonStyled)`
  width: 12rem !important;
`;
export const CancelButtonWidthStyled = styled(CancelButtonStyled)`
  width: 12rem !important;
`;
