export const EMAIL_SETTINGS_SCREEN_SEARCH_AND_FILTER_OPTIONS_CONFIG = {
  SEARCH_BY_OPTIONS: [
    { label: 'All', value: 'all' },
    { label: 'Subject', value: 'subject' },
    { label: 'From Email', value: 'from' },
    { label: 'Body', value: 'body' },
  ],
  FILTER_OPTIONS: [
    { label: 'No State', value: 'noState', type: 'checkbox' },
    { label: 'No Classification', value: 'noClassification', type: 'checkbox' },
  ],
};
