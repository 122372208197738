import React, { ReactElement, useState } from 'react';
import {
  AddButtonText,
  CancelButton,
  DateRangeModal,
  DivContainer,
  DividerLine,
  EditButton,
  LoadingGrid,
  SecondDividerLine,
  SetTimeOffButtonDiv,
  SetTimeOffCaption,
  SetTimeOffContainer,
  SetTimeOffDates,
  SetTimeOffDatesContainer,
  SetTimeOffDatesSection,
  SetTimeOffEditAndCancelContainer,
  SetTimeOffHeadingDiv,
  SetTimeOffSection,
  SetTimeoutHeading,
} from '@modules/home/main/admin/profile/ProfileStyle';
import CustomDateRangePicker from '@components/custom-date-range-picker/CustomDateRangePicker';
import { FormatDate } from '@constants/global-constants/constants';
import { ListItem } from '@mui/material';
import { t } from 'i18next';
import Loader from '@components/loaders/Loader';
import { enqueueSnackbar } from 'notistack';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { Value } from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types';
import { format } from 'date-fns';
import { getErrorMessage } from '@utils/ErrorUtils';
import { TimeOffsDataDto } from 'src/services/service-handlers/private/profile-details-handlers/ProfileDetailsServiceMapper';
import moment from 'moment';
import { formatDateWithoutTime } from '@modules/home/utils/Utils';

interface ITimeOffsProps {
  disabled?: boolean;
  selectedDateValue?: Value;
  subText?: string;
  timeOffIds?: TimeOffsDataDto[];
  userId: string;
  isDividerVisible?: boolean;
  isTopMarginRequired?: boolean;
  addNewTimeOffApi: (startDate: Date, endDate: Date) => Promise<void>;
  updateTimeOffApi: (
    startDate: Date,
    endDate: Date,
    editIndex: number,
  ) => Promise<void>;
  deleteTimeOffApi: (
    index: number,
    UserId: string,
    timeOffId: string,
  ) => Promise<void>;
}

const TodayDate = new Date();
TodayDate.setDate(TodayDate.getDate() + 1);

const yesterdayBegin = new Date(
  TodayDate.getFullYear(),
  TodayDate.getMonth(),
  TodayDate.getDate() - 1,
  0, // Hours
  0, // Minutes
  0, // Seconds
  0, // Milliseconds
);
const todayEnd = new Date(
  TodayDate.getFullYear(),
  TodayDate.getMonth(),
  TodayDate.getDate(),
  23,
  59,
  59,
  999,
);

const TimeOff = (props: ITimeOffsProps): ReactElement => {
  const {
    selectedDateValue,
    disabled,
    subText = '',
    timeOffIds = [],
    userId,
    isDividerVisible = false,
    isTopMarginRequired = false,
    addNewTimeOffApi,
    updateTimeOffApi,
    deleteTimeOffApi,
  } = props;
  const usersId = userId;
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<Value>([yesterdayBegin, todayEnd]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [minDate, setMinDate] = useState<Date>(TodayDate);

  const handleAddDateButton = () => {
    const preSelectedStartDate = new Date();
    preSelectedStartDate.setDate(preSelectedStartDate.getDate() + 1);

    const preSelectedEndDate = new Date();
    preSelectedEndDate.setDate(preSelectedEndDate.getDate() + 2);

    setEditIndex(null);
    setShowDatePicker(true);
    setValue([preSelectedStartDate, preSelectedEndDate]);
    setMinDate(preSelectedStartDate);
  };

  const handleToggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  // validate section starts

  const validateSelectedDateRangeChange = (
    startDate: Date | null,
    endDate: Date | null,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    editIndex: number | null,
  ): boolean => {
    const currentDateWithoutTime = formatDateWithoutTime(new Date());
    const startDateWithoutTime = formatDateWithoutTime(startDate);
    const endDateWithoutTime = formatDateWithoutTime(endDate);
    if (
      startDate &&
      endDate &&
      moment(startDateWithoutTime).isBefore(currentDateWithoutTime) &&
      moment(endDateWithoutTime).isBefore(currentDateWithoutTime) &&
      editIndex === null
    ) {
      const ErrorMessage = t(
        'ADMIN.USERS_TAB.ADD_USER_FORM.TIME_OFF_VALIDATIONS.START_DATE_IN_PAST',
      );
      enqueueSnackbar(ErrorMessage, SnackBarConfig.getError());
      return false;
    }
    return true;
  };

  const validateSetTimeOffUpdate = (
    startDate: Date,
    endDate: Date,
    editIndexForUpdate: number,
  ): boolean => {
    const timeOffToUpdate = timeOffIds[editIndexForUpdate];
    const currentStartDate = formatDateWithoutTime(
      new Date(timeOffToUpdate.startDate),
    );
    const currentEndDate = formatDateWithoutTime(
      new Date(timeOffToUpdate.endDate),
    );
    const currentDateWithoutTime = formatDateWithoutTime(new Date());

    const startDateWithoutTime = formatDateWithoutTime(startDate);
    const endDateWithoutTime = formatDateWithoutTime(endDate);

    if (
      startDate &&
      endDate &&
      moment(startDateWithoutTime).isSame(currentStartDate) &&
      moment(endDateWithoutTime).isAfter(currentDateWithoutTime) &&
      !moment(endDateWithoutTime).isSame(currentEndDate)
    ) {
      return true;
    } else if (
      moment(startDateWithoutTime).isAfter(currentDateWithoutTime) &&
      moment(endDateWithoutTime).isAfter(currentDateWithoutTime)
    ) {
      return true;
    } else {
      const ErrorMessage = t(
        'ADMIN.USERS_TAB.ADD_USER_FORM.TIME_OFF_VALIDATIONS.START_DATE_CANNOT_BE_CHANGED',
      );
      enqueueSnackbar(ErrorMessage, SnackBarConfig.getError());
    }
    return false;
  };

  const validateDisabledDates = (
    startDate: Date,
    setMinDateForDisable: (date: Date) => void,
    currentDate: Date,
  ): void => {
    const startDateObj = new Date(startDate);
    if (startDateObj < currentDate) {
      // Start date is in the past, disable previous dates
      setMinDateForDisable(startDateObj);
    } else if (startDateObj > currentDate) {
      // Start date is in the future, allow selection from today
      setMinDateForDisable(currentDate);
    }
  };

  const validateDelete = (
    index: number,
    SelectedTimeOffIds: TimeOffsDataDto[],
    currentDate: Date,
  ): boolean => {
    const currentDateWithoutTime = formatDateWithoutTime(currentDate);
    const { startDate, endDate } = SelectedTimeOffIds[index];
    const startDateWithoutTime = formatDateWithoutTime(startDate);
    const endDateWithoutTime = formatDateWithoutTime(endDate);

    if (
      startDate &&
      endDate &&
      (moment(startDateWithoutTime).isAfter(currentDateWithoutTime) ||
        moment(endDateWithoutTime).isAfter(currentDateWithoutTime))
    ) {
      return true; // Valid to proceed with cancellation
    } else {
      const errorMessage = t(
        'ADMIN.USERS_TAB.ADD_USER_FORM.TIME_OFF_VALIDATIONS.CANNOT_DELETE_PAST_DATE',
      );
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
      return false;
    }
  };

  // validate section ends

  // handle section starts

  const handleDateRangeChange = async (newValue: Value) => {
    setValue(newValue);
    if (newValue && Array.isArray(newValue)) {
      const [startDate, endDate] = newValue;
      const validate = validateSelectedDateRangeChange(
        startDate,
        endDate,
        editIndex,
      );
      if (!validate) {
        return;
      }
      if (startDate && endDate) {
        if (editIndex !== null) {
          setLoading(true);
          handleToggleDatePicker();
          if (validateSetTimeOffUpdate(startDate, endDate, editIndex)) {
            await updateTimeOffApi(startDate, endDate, editIndex);
          }
          setEditIndex(null);
          setLoading(false);
        } else {
          // Adding new date range
          setLoading(true);
          handleToggleDatePicker();
          await addNewTimeOffApi(startDate, endDate);
          setLoading(false);
        }
      }
    }
  };

  const handleEdit = (index: number) => {
    setEditIndex(index);
    const { startDate, endDate } = timeOffIds[index];
    setValue([new Date(startDate), new Date(endDate)]);
    handleToggleDatePicker();
    const startDateObj = new Date(startDate);
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);

    validateDisabledDates(startDateObj, setMinDate, currentDate);
  };

  const handleCancel = async (index: number) => {
    try {
      const currentDate = new Date();
      const isValid = validateDelete(index, timeOffIds, currentDate);
      const { id: timeOffId } = timeOffIds[index];
      if (isValid) {
        setLoading(true);
        await deleteTimeOffApi(index, usersId, timeOffId);
        setLoading(false);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
      setLoading(false);
    }
  };

  // handle section ends

  // render section starts

  const renderTimeOffSection = () => {
    return (
      <>
        {isDividerVisible && <DividerLine />}
        <SetTimeOffContainer isTopMarginRequired={isTopMarginRequired}>
          <SetTimeOffSection>
            <SetTimeOffHeadingDiv>
              <SetTimeoutHeading>
                {t('PRIVATE.PROFILE.SET_TIME_OFF')}
              </SetTimeoutHeading>
              {subText.length > 0 && (
                <SetTimeOffCaption>{subText}</SetTimeOffCaption>
              )}
            </SetTimeOffHeadingDiv>
            <SetTimeOffButtonDiv>
              <AddButtonText
                onClick={handleAddDateButton}
                disabled={disabled || loading}
              >
                {t('PRIVATE.PROFILE.BUTTON_ADD_TEXT')}
              </AddButtonText>
            </SetTimeOffButtonDiv>
          </SetTimeOffSection>
          <DateRangeModal
            open={showDatePicker}
            onClose={handleToggleDatePicker}
          >
            <DivContainer>
              <CustomDateRangePicker
                value={selectedDateValue || value}
                onChange={handleDateRangeChange}
                minDate={minDate}
              />
            </DivContainer>
          </DateRangeModal>
          <SetTimeOffDatesContainer>
            {disabled || loading ? (
              <LoadingGrid>
                <Loader />
              </LoadingGrid>
            ) : (
              timeOffIds &&
              timeOffIds.length > 0 &&
              timeOffIds.map((timeOff: TimeOffsDataDto, index: number) => (
                <SetTimeOffDatesSection key={index}>
                  <ListItem>
                    <SetTimeOffDates>
                      {format(timeOff.startDate, FormatDate.MonthAndDay)} -{' '}
                      {format(timeOff.endDate, FormatDate.MonthAndDay)}
                    </SetTimeOffDates>
                  </ListItem>
                  <SetTimeOffEditAndCancelContainer>
                    <EditButton onClick={() => handleEdit(index)}>
                      {t('PRIVATE.PROFILE.BUTTON_EDIT_TEXT')}
                    </EditButton>
                    <CancelButton onClick={() => handleCancel(index)}>
                      {t('PRIVATE.PROFILE.BUTTON_CANCEL_TEXT')}
                    </CancelButton>
                  </SetTimeOffEditAndCancelContainer>
                </SetTimeOffDatesSection>
              ))
            )}
          </SetTimeOffDatesContainer>
        </SetTimeOffContainer>
        {isDividerVisible && <SecondDividerLine />}
      </>
    );
  };

  // render section ends

  return <div>{renderTimeOffSection()}</div>;
};

export default TimeOff;
