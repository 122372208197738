import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { APP } from 'src/styles/variables';

export const DashboardDataGridWrapper = styled(Grid)`
  position: relative;
  width: 100% !important;

  .MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
  }

  .MuiTable-root {
    border: none !important;
    border-bottom: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY_2} !important;
    box-shadow: none;
  }

  .MuiTableRow-root {
    padding: 0 !important;
    box-shadow: none !important;
  }

  .MuiTableCell-root {
    border-bottom: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY_2} !important;
  }

  .MuiTableCell-head {
    background-color: transparent !important;
    font-weight: bold !important;
    border: none !important;
    border-bottom: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY_2} !important;
  }

  .Mui-TableHeadCell-Content {
    justify-content: center !important;
  }

  .MuiTableContainer-root {
    box-shadow: none;
  }

  .MuiTableBody-root {
    border-left: none !important;
    border-right: none !important;
  }
`;

export const ExpandIconImg = styled('img')`
  transform: ${({ isopen }: { isopen: 'yes' | 'no' }) =>
    isopen === 'yes' ? 'rotate(180deg)' : 'rotate(0)'};
`;

export const LoadingGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 3rem;
`;

export const ErrorGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 3rem;
  color: ${APP.PALETTE.GREY.SHADE_A400};
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  font-weight: 600;
`;

export const DashboardDataQueueListExpandLoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${APP.PALETTE.BACKGROUND.WHITE_WITH_OPACITY};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;
