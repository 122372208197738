import styled from '@emotion/styled';
import { Grid, Typography, Avatar } from '@mui/material';
import { APP } from 'src/styles/variables';

export const DashboardWrapperGrid = styled(Grid)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
`;

export const DashboardTopBarGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 1.62rem 1.87rem;
`;

export const DashboardSectionGridWrapper = styled(Grid)`
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const DashboardFilterGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  overflow-x: auto;
  width: fit-content;
  flex-wrap: nowrap;
`;

export const DashboardFilterHeadingTypography = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 1rem;
  font-weight: bold;
`;

export const DashboardSelectFilterGrid = styled(Grid)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DashboardHeadingTypography = styled(Typography)`
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${APP.FONT.FONT_SIZE.H6};
`;

export const DashboardInfoCardWrapperGrid = styled(Grid)`
  display: grid;
  grid-template-columns: repeat(5, minmax(11.31rem, 1fr));
  grid-auto-rows: minmax(8.5rem, auto);

  gap: 0.625rem;
  width: 100%;
  margin: 0 auto;
  padding: 0.625rem 0.625rem;

  @media (max-width: 1460px) {
    grid-template-columns: repeat(5, minmax(11.31rem, 1fr));
  }

  @media (max-width: 1100px) {
    grid-template-columns: repeat(auto-fit, minmax(18.31rem, 1fr));
  }
`;

export const DashboardTableSectionWrapperGrid = styled(Grid)`
  width: 100%;
  flex: 1;
  padding: 0.62rem 0.62rem 2.12rem 0.62rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1.12rem;

  @media (max-width: 721px) {
    flex-direction: column;
  }
`;

export const DashboardTableContainerGrid = styled(Grid)`
  min-height: 100%;
  flex: 1;
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 1.75rem;
  background-color: ${({ theme }) => theme.palette.background.paper};

  @media (max-width: 725px) {
    min-height: auto;
    flex: auto;
    width: 100%;
  }
`;

export const DashboardTableSectionGrid = styled(Grid)`
  height: 100%;
  width: 100%;
`;

export const DashboardTableHeaderGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 0.93rem;
`;

export const DashboardTableHeaderTypography = styled(Typography)`
  font-size: ${APP.FONT.FONT_SIZE.H5};
  font-weight: bold;
`;

export const UserCellWrapperDiv = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: inherit;
  text-transform: none;
  flex-direction: row;
`;

export const UserCellImageWrapperDiv = styled('div')`
  padding: 0.62rem 0.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UserCellProfilePictureLoadingDiv = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
`;

export const UserImageAvatar = styled(Avatar)`
  height: 2rem;
  width: 2rem;
`;

export const UserCellContentDiv = styled('div')`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.1rem;
`;

export const UserCellNameTypography = styled(Typography)`
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  font-weight: bolder;
  line-height: 1.12rem;
  cursor: pointer;
`;

export const UserCellStateTypography = styled(Typography)`
  font-size: 12px;
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  line-height: 0.875rem;
`;

export const QueueTypeCellDiv = styled('div')<{ isRowExpanded: string }>`
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: none;
  padding-left: ${({ isRowExpanded }: { isRowExpanded: string }) =>
    isRowExpanded == 'yes' ? '1.2rem' : ''};
  gap: 0.4rem;
  flex-direction: row;
`;

export const QueueTypeCellTypography = styled(Typography)`
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};
  font-weight: bolder;
  cursor: pointer;
`;

export const DefaultCellDiv = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: inherit;
`;

export const DefaultCellTypography = styled(Typography)`
  cursor: pointer;
`;

export const InfoCardLoadingDiv = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 17rem;
`;

export const UserQueueGridLoadingDiv = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 3rem;
`;

export const QueueGridLoadingDiv = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 3rem;
`;
