import { ReactElement, useState, ReactNode, useEffect } from 'react';
import useTranslation from 'i18next';
import {
  CountTypography,
  DateRangeTabs,
  DateRangeTabsWrapperDiv,
  InfoCardBox,
  InfoCardContentDiv,
  TextDescriptionTypography,
  TimeSpanButtonTab,
} from './InfoCardStyle';
import { DashboardConstants } from '@constants/admin-constants/AdminConstants';
import { RangeType } from 'src/services/service-handlers/private/dashboard/DashboardServiceMapper';

interface InfoCardProps {
  count?: number | ReactNode;
  label?: string;
  isactive?: string;
  israngebuttonneeded: string;
  timeRangeValue?: RangeType;
  height?: number | string;
  width?: number | string;
  gap?: number;
  onClick?: (e: React.SyntheticEvent, selectedCard?: string) => void;
  onTimeRangeChange?: (value: string) => void;
}
export interface TimeSpanButtonsInterface {
  label: string;
  value: string;
}

const InfoCard = (props: InfoCardProps): ReactElement => {
  const { t } = useTranslation;

  const TimeSpanButtons: TimeSpanButtonsInterface[] = [
    {
      label: t('PRIVATE.DASHBOARD.INFO_CARD.TIME_SPAN.DAY'),
      value: DashboardConstants.TIME_SPAN.DAY,
    },
    {
      label: t('PRIVATE.DASHBOARD.INFO_CARD.TIME_SPAN.MONTH'),
      value: DashboardConstants.TIME_SPAN.MONTH,
    },
    {
      label: t('PRIVATE.DASHBOARD.INFO_CARD.TIME_SPAN.YEAR'),
      value: DashboardConstants.TIME_SPAN.YEAR,
    },
  ];

  const {
    count,
    label,
    israngebuttonneeded = 'no',
    height,
    width,
    gap = 0,
    isactive = 'no',
    onClick,
    timeRangeValue = TimeSpanButtons[0].value,
    onTimeRangeChange,
    ...rest
  } = props;

  const [currentTimeSpan, SetCurrentTimeSpan] = useState<string | number>(
    timeRangeValue,
  );

  const handleButtonClick = (event: React.SyntheticEvent, newValue: string) => {
    SetCurrentTimeSpan(newValue);
    if (onTimeRangeChange) onTimeRangeChange(newValue);
  };

  useEffect(() => {
    SetCurrentTimeSpan(timeRangeValue as RangeType);
  }, [timeRangeValue, currentTimeSpan]);

  const timeRangeOptionButtons = () => {
    return (
      <DateRangeTabsWrapperDiv>
        <DateRangeTabs
          value={currentTimeSpan}
          onChange={handleButtonClick}
          TabIndicatorProps={{
            sx: {
              display: 'none',
            },
          }}
        >
          {TimeSpanButtons.map((eachTimeSpan: TimeSpanButtonsInterface) => {
            return (
              <TimeSpanButtonTab
                key={eachTimeSpan.label}
                label={eachTimeSpan.label}
                value={eachTimeSpan.value}
                disableRipple
              />
            );
          })}
        </DateRangeTabs>
      </DateRangeTabsWrapperDiv>
    );
  };

  return (
    <InfoCardBox
      height={height}
      width={width}
      gap={gap}
      isactive={isactive}
      {...rest}
      onClick={(e: React.SyntheticEvent) => {
        if (onClick) onClick(e, label);
      }}
      israngebuttonneeded={israngebuttonneeded}
    >
      {israngebuttonneeded === 'yes' && timeRangeOptionButtons()}
      <InfoCardContentDiv israngebuttonneeded={israngebuttonneeded}>
        <CountTypography>{count}</CountTypography>
        <TextDescriptionTypography>
          {label ? label : ''}
        </TextDescriptionTypography>
      </InfoCardContentDiv>
    </InfoCardBox>
  );
};

export default InfoCard;
