import { ReactElement, useState } from 'react';
import {
  BadgeCount,
  BadgeCountContainer,
  ChildrenContainer,
  FilterContainer,
  FilterDropdownWithBadgeCountContainer,
  FilterIcon,
} from './FilterDropdownWithBadgeCountStyle';
import Filter from '@assets/images/Filter.svg';

type FilterDropdownWithBadgeCountProps = {
  isBadgeCountRequired?: boolean;
  badgeCount?: number;
  children?: ReactElement;
  top?: string;
  left?: string;
};

const FilterDropdownWithBadgeCount = (
  filterDropdownWithBadgeCountProps: FilterDropdownWithBadgeCountProps,
): ReactElement => {
  const {
    isBadgeCountRequired = false,
    badgeCount = 0,
    children,
    top = '',
    left = '',
  } = filterDropdownWithBadgeCountProps;

  const [showChildren, setShowChildren] = useState(false);

  const renderBadgeWithCount = () => (
    <BadgeCountContainer>
      <BadgeCount>{badgeCount}</BadgeCount>
    </BadgeCountContainer>
  );

  return (
    <FilterDropdownWithBadgeCountContainer>
      <FilterContainer
        onClick={() => {
          setShowChildren(!showChildren);
        }}
      >
        <FilterIcon>
          <img src={Filter} height={40} width={40} alt="..." />
        </FilterIcon>
        {isBadgeCountRequired ? renderBadgeWithCount() : null}
      </FilterContainer>
      <ChildrenContainer top={top} left={left}>
        {children ? children : null}
      </ChildrenContainer>
    </FilterDropdownWithBadgeCountContainer>
  );
};

export default FilterDropdownWithBadgeCount;
