import { baseUrl } from '../../common/ApiEndPoint';

export const dashboardBaseUrl = `${baseUrl}/dashboard/summary`;

// dashboard User Queue api urls
export const dashboardUserQueueBaseUrl = `${baseUrl}/user-queue`;

export const getAllDashboardInfoCardListUrl = `${dashboardBaseUrl}`;

export const getAllCategoryListUrl = `${dashboardBaseUrl}/category`;

export const getAllCategoryTypeByCategoryIdUrl = `${dashboardBaseUrl}/type`;

export const getAllDashboardUserListUrl = `${dashboardBaseUrl}/user`;

export const getCompletedTaskDetailsUrl = `${dashboardBaseUrl}/completed`;
