import { PublicNavigationRoutes } from '@constants/RouteConstants';
import Login from '@modules/public/main/login/Login';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { RootState } from 'src/store/Store';

const PublicRoutes = (): JSX.Element => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );

  return (
    <Router>
      <Routes>
        {!isAuthenticated && <Route path="*" element={<Navigate to="/" />} />}

        <Route path={'/'} element={<Login />} />
        <Route
          path={PublicNavigationRoutes.Login.FullPath}
          element={<Login />}
        />
      </Routes>
    </Router>
  );
};

export default PublicRoutes;
