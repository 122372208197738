import { baseUrl } from '../../common/ApiEndPoint';

export const getProfileDetailsUrl = `${baseUrl}/users/me`;
export const getTimeOffsUrl = `${baseUrl}/users/:id/time-off`;
export const addTimeOffUrl = `${baseUrl}/users/:id/time-off`;
export const updateTimeOffUrl = `${baseUrl}/users/:id/time-off/:timeOffId`;
export const deleteTimeOffUrl = `${baseUrl}/users/:id/time-off/:timeOffId`;
export const updateProfilePictureUrl = `${baseUrl}/users/:id/profile-pic`;
export const getProfilePictureUrl = `${baseUrl}/users/:id/profile-pic`;
export const deleteProfilePictureUrl = `${baseUrl}/users/:id/profile-pic/:profilePicId`;
