import ServiceRequest from 'src/services/ServiceRequest';

import {
  getAllStatesUrl,
  getAllTaskStatusesUrl,
  getAllTaskTypesUrl,
} from './RefDataApiEndPoints';

import {
  GetAllStatesQueryParam,
  GetAllStatesResDto,
  GetAllTaskStatusesQueryParam,
  GetAllTaskStatusesResponseData,
  GetAllTaskTypesQueryParam,
  GetAllTaskTypesResponseData,
} from './RefDataServiceMapper';

export const getAllStates = async (
  data?: GetAllStatesQueryParam,
): Promise<GetAllStatesResDto> => {
  return await ServiceRequest.get(getAllStatesUrl, {
    payload: data,
  });
};

export const getAllTaskTypes = async (
  data?: GetAllTaskTypesQueryParam,
): Promise<GetAllTaskTypesResponseData> => {
  const defaultData = {
    includeNonEmailTypes: true,
    ...data,
  };
  return await ServiceRequest.get(getAllTaskTypesUrl, {
    payload: defaultData,
  });
};

export const getAllTaskStatuses = async (
  data?: GetAllTaskStatusesQueryParam,
): Promise<GetAllTaskStatusesResponseData> => {
  return await ServiceRequest.get(getAllTaskStatusesUrl, {
    payload: data,
  });
};
