import styled from '@emotion/styled';
import { InputBase, InputBaseProps, Theme } from '@mui/material';
import { APP } from 'src/styles/variables';

interface CustomInputProps extends InputBaseProps {
  isstylingneeded?: 'yes' | 'no';
  theme?: Theme | undefined;
  isgroupingstylingneeded?: 'yes' | 'no';
  width?: string;
  borderradius?: string | number;
  backgroundcolor?: string;
  paddingmedium?: 'yes' | 'no';
  boxshadow?: 'yes' | 'no';
  isborderradiusrequired?: 'yes' | 'no';
  fontWeight?: string;
}

const StyledSelectField = styled(InputBase)<CustomInputProps>(
  ({
    theme,
    isstylingneeded,
    isgroupingstylingneeded,
    backgroundcolor,
    width,
    paddingmedium = 'no',
    boxshadow,
    borderradius,
    isborderradiusrequired = 'no',
    fontWeight = '400',
  }) => ({
    fontWeight: fontWeight,
    width: isgroupingstylingneeded === 'yes' ? width ?? '100%' : 'fit-content',
    minWidth: isgroupingstylingneeded === 'yes' ? '100%' : 'fit-content',
    'label + &': {
      marginTop: theme?.spacing(0),
    },
    '& .MuiInputBase-input': {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      borderRadius:
        isborderradiusrequired === 'yes'
          ? '0.3rem'
          : isgroupingstylingneeded === 'yes'
          ? borderradius ?? '6px'
          : 4,
      height: '1.56rem !important',
      position: 'relative',
      backgroundColor: theme?.palette.background.paper,
      background:
        isgroupingstylingneeded === 'yes'
          ? backgroundcolor ??
            APP.PALETTE.SELECT_INPUT.BACKGROUND.LINEAR_GRADIENT
          : APP.PALETTE.COMMON.WHITE,
      border:
        isstylingneeded === 'yes'
          ? '1px solid ' + APP.PALETTE.SELECT_INPUT.BORDER_COLOR.PRIMARY
          : 'none',
      fontSize: '1rem',
      padding: paddingmedium == 'yes' ? ' 0.6rem !important' : 'inherit',
      paddingTop: '0.31rem',
      paddingBottom: '0.31rem',
      paddingLeft:
        isstylingneeded === 'yes' ? '0.62rem !important' : '0.31rem !important',
      paddingRight: '1.62rem !important',
      width: isgroupingstylingneeded === 'yes' ? '100%' : 'fit-content',
      transition: theme?.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        minWidth: 'fit-content',
        paddingLeft:
          isstylingneeded === 'yes'
            ? '0.62rem !important'
            : '0.31rem !important',
        paddingRight: '1.62rem !important',
        borderRadius:
          isgroupingstylingneeded === 'yes' ? borderradius ?? '6px' : 4,
        background:
          isgroupingstylingneeded === 'yes'
            ? backgroundcolor ??
              APP.PALETTE.SELECT_INPUT.BACKGROUND.LINEAR_GRADIENT
            : APP.PALETTE.COMMON.WHITE,
        borderColor: APP.PALETTE.GREY.SEMI_GRAY,
        boxShadow:
          boxshadow == 'no'
            ? 'none'
            : isstylingneeded === 'yes'
            ? '0 0 0 0.2rem ' + APP.PALETTE.SELECT_INPUT.BOX_SHADOW.PRIMARY
            : 'none',
      },
    },
  }),
);

export default StyledSelectField;
