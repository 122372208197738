import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  CancelButtonStyled,
  DialogActionsContainer,
  DialogContentStyled,
  SaveButtonDisabledStyled,
  SaveButtonStyled,
  SubTitleStyled,
  TitleStyled,
} from './FormDialogStyle';
import { APP } from 'src/styles/variables';

interface IFormDialogProps {
  isDialogOpen: boolean;
  handleCancel?: () => void;
  titleText: string;
  subTitleText: string;
  submitButtonText: string;
  submitButtonLoadingText?: string;
  cancelButtonText: string;
  onSubmit?: (data: any) => void;
  dialogContent: ReactNode;
  subTitleTextColor?: string;
  width?: string;
  height?: string;
  renderFooterButtonSection?: ReactElement;
  titleFontSize?: string;
  disableSubmitButton?: boolean;
  showSubmitButtonLoader?: boolean;
  isSaveInProgress?: boolean;
  showCloseIcon?: boolean;
  showCancelAndSubmitButton?: boolean;
}

const FormDialog = (props: IFormDialogProps): ReactElement => {
  const {
    isDialogOpen: openDialog,
    handleCancel,
    titleText,
    subTitleText,
    subTitleTextColor = '',
    submitButtonText,
    cancelButtonText,
    width,
    height,
    onSubmit,
    dialogContent,
    renderFooterButtonSection,
    titleFontSize,
    disableSubmitButton = false,
    submitButtonLoadingText = '...',
    isSaveInProgress = false,
    showCloseIcon = true,
    showCancelAndSubmitButton = true,
  } = props;

  const handleCloseDialog = () => {
    handleCancel && handleCancel();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      PaperProps={{
        style: {
          width: width ?? '28rem',
          boxShadow: APP.PALETTE.GREY.BOX_SHADOW.GRAY,
          background: APP.PALETTE.COMMON.WHITE,
          height: height ?? 'auto',
        },
      }}
    >
      {showCloseIcon ? (
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          disabled={isSaveInProgress}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: APP.PALETTE.GREY.TERTIARY_COLOR,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <DialogContentStyled>
        <TitleStyled titleFontSize={titleFontSize}>{titleText}</TitleStyled>
        {subTitleText.length ? (
          <SubTitleStyled
            color={
              subTitleTextColor
                ? subTitleTextColor
                : APP.PALETTE.GREY.TERTIARY_COLOR
            }
          >
            {subTitleText}
          </SubTitleStyled>
        ) : null}
        {dialogContent}
      </DialogContentStyled>
      {renderFooterButtonSection ||
        (showCancelAndSubmitButton && (
          <DialogActionsContainer>
            <CancelButtonStyled
              onClick={handleCloseDialog}
              disabled={isSaveInProgress}
            >
              {cancelButtonText}
            </CancelButtonStyled>
            {!disableSubmitButton ? (
              <SaveButtonStyled
                onClick={onSubmit}
                disabled={disableSubmitButton}
              >
                <span>{submitButtonText}</span>
              </SaveButtonStyled>
            ) : (
              <SaveButtonDisabledStyled disabled={disableSubmitButton}>
                {isSaveInProgress ? submitButtonLoadingText : submitButtonText}
              </SaveButtonDisabledStyled>
            )}
          </DialogActionsContainer>
        ))}
    </Dialog>
  );
};

export default FormDialog;
