export enum QueueMoreOptions {
  START_TASK = 'Start Task',
  COMPLETE_TASK = 'Complete Task',
  CHANGE_TYPE = 'Change Type',
  ARCHIVE = 'Archive',
}
export enum QueueHistoryMoreOptions {
  // TODO: change this option
  MENU_ITEM = 'Menu Item',
}

export enum PriorityTypes {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export enum QueueTaskStatusTypes {
  FLAGGED = 'flagged',
  IN_PROGRESS = 'inprogress',
  ASSIGNED = 'assigned',
  UNASSIGNED = 'unassigned',
  COMPLETED = 'completed',
  UNCLASSIFIED = 'unclassified',
  FAILED = 'failed',
}
