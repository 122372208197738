import CustomChip from '@components/custom-chip/CustomChip';
import styled from '@emotion/styled';
import { Checkbox, Divider } from '@mui/material';
import { APP } from 'src/styles/variables';

export const InputWrapperDiv = styled.div`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    opacity: 1;
  }
`;

export const ErrorTextStyled = styled('div')`
  margin-top: 0.5rem;
  color: ${APP.PALETTE.ERROR.MAIN};
`;
export const FooterTextStyled = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_17PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.H6};
  margin-bottom: 0.5rem;
`;
export const FooterSubTextStyled = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  margin-right: 5rem;
`;
export const CheckboxStyled = styled(Checkbox)`
  margin-left: -0.7rem;
  & .MuiSvgIcon-root {
    font-size: 28;
  }

  color: ${APP.PALETTE.GREY.SEMI_GRAY};
  &.Mui-checked {
    color: ${APP.FONT.FONT_COLOR.SECONDARY};
  }
`;
export const StyledCustomChip = styled(CustomChip)`
  color: ${APP.PALETTE.COMMON.WHITE};
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  background-color: ${APP.PALETTE.BACKGROUND.CHIP_TEAL};
  margin: 0.2rem 0.1rem 0.3rem 0.1rem;
  padding: 0.3rem;
`;

export const DividerStyled = styled(Divider)`
  margin: 1.5rem 0 1rem;
`;
