import PrivateRoutes from 'src/navigation/PrivateRoutes';
import SessionExpireDialog from './session-expire-dialog/SessionExpireDialog';

const PrivateHome = (): JSX.Element => {
  return (
    <div>
      <SessionExpireDialog />
      <PrivateRoutes />
    </div>
  );
};

export default PrivateHome;
