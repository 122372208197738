import styled from '@emotion/styled';
import { APP } from 'src/styles/variables';
import { Box, Grid } from '@mui/material';

export const QueueAssignmentsByTypeBox = styled(Box)`
  border-top: 1px solid ${APP.PALETTE.DASHBOARD.BORDER_COLOR.PRIMARY};
`;

export const ListGridContainer = styled(Grid)`
  overflow-x: scroll;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
