import styled from '@emotion/styled';
import { Avatar, List, ListItemAvatar } from '@mui/material';
import { APP } from 'src/styles/variables';

export const StyledList = styled(List)`
  width: 100%;
  border-bottom: 1px solid ${APP.PALETTE.TABS.GRAY_2};
`;

export const LoadingGridForProfilePic = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
`;

export const PrimaryTextStyled = styled('span')`
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  font-family: ${APP.FONT.FONT_FAMILY.PRIMARY};
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  color: ${APP.PALETTE.COMMON.BLACK};
`;

export const SecondaryTextStyled = styled('span')`
  font-family: ${APP.FONT.FONT_FAMILY.PRIMARY};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
`;

export const AvatarStyled = styled(Avatar)`
  width: 2rem;
  height: 2rem;
`;
export const ListItemAvatarStyled = styled(ListItemAvatar)`
  min-width: 0rem;
  margin-right: 0.5rem;
`;

export const ListItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CurrentTaskCount = styled.div`
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  color: ${APP.PALETTE.COMMON.BLACK};
`;
