export enum StateNsaAllStateEnum {
  STATE = 'state',
  ALL_STATE = 'allState',
  NSA = 'nsa',
}

export enum GetAllUsersFromOurSystemQueryParamFormatEnum {
  BASIC = 'basic',
  DETAILED = 'detailed',
}
