import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoToHomeDiv } from './NotFoundStyles';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';

const NotFound = (): ReactElement => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(PrivateNavigationRoutes.Home.Path);
  };

  return (
    <div>
      <h1>404 - Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <GoToHomeDiv onClick={handleRedirect}>Go to Home page</GoToHomeDiv>
    </div>
  );
};

export default NotFound;
