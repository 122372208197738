import { Grid, TextField, styled } from '@mui/material';
import { APP } from 'src/styles/variables';

export const TextFieldWrapperStyled = styled(TextField)`
  border-radius: 6px;
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
  background: linear-gradient(
    ${APP.PALETTE.COMMON.WHITE},
    ${APP.PALETTE.GREY.SEMI_GRAY}
  );
  .MuiAutocomplete-input {
    font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
    font-size: ${APP.FONT.FONT_SIZE.SUBTITLE2};
  }
`;

export const LoadingGridForSearchableSelectInput = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.563rem;
  width: 1.563rem;
`;

export const LoadingGridForDropdownSelectInput = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  margin-right: 0.063rem;
`;
