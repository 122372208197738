import SearchBar from '@components/search-bar/SearchBar';
import styled from '@emotion/styled';
import { APP } from 'src/styles/variables';

export const LoaderContainer = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DataNotFoundDiv = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 1rem;
  border: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
`;

export const StyledSpan = styled('span')`
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE2};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
`;
export const DescriptionSpan = styled(StyledSpan)`
  font-family: ${APP.FONT.FONT_FAMILY.MEDIUM};
  font-weight: ${APP.FONT.FONT_WEIGHT.H5};
`;

export const EmailTextDiv = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.H6};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  color: ${APP.PALETTE.COMMON.BLACK};
  margin: 1.5rem;
  display: flex;
  align-items: flex-start;
`;

export const EmailNameTextDiv = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE2};
  font-family: ${APP.FONT.FONT_FAMILY.MEDIUM};
`;

export const BodyTextStyle = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE2};
  font-family: ${APP.FONT.FONT_FAMILY.MEDIUM};
`;

export const BodySectionContainer = styled('div')`
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide any content that overflows its container */
  text-overflow: ellipsis;
`;

export const SearchBarStyled = styled(SearchBar)`
  width: 30rem;
  @media screen and (max-width: 768px) {
    width: 17rem;
  }
`;
