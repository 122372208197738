import FormDialog from '@components/dialog/form-dialog/FormDialog';
import { LabelStyled } from '@components/select-input/select-text-input/SelectTextInputStyle';
import { Controller, useForm } from 'react-hook-form';
import {
  DistributeAllContainer,
  InputWrapperDiv,
  SettingsErrorTextStyled,
  DistributeAllLabel,
  DistributeAllButtonText,
  DistributeAllButton,
  DividerStyled,
  MainLoaderContainer,
  QueuePUBucketSettingsDialogContentContainer,
  DialogContentLoaderContainer,
} from './QueuePUBucketSettingsStyle';
import { TextField } from '@mui/material';
import { t } from 'i18next';
import { settingsValidationSchema } from 'src/validation-schema/admin/AdminValidationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { produce } from 'immer';
import { useSnackbar } from 'notistack';
import { getErrorMessage } from '@utils/ErrorUtils';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import {
  distributeUnassignedQueueItems,
  getMaxAssignmentsByTaskCategoryId,
  updateMaxAssignmentsByTaskCategoryId,
} from 'src/services/service-handlers/private/queue-handlers/QueuePrivateService';
import { UpdateMaxAssignmentsByTaskCategoryIdPayloadDto } from 'src/services/service-handlers/private/queue-handlers/QueueServiceMapper';
import Loader from '@components/loaders/Loader';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';

interface IQueuePUBucketSettingsProps {
  handleCancel: () => void;
  categoryName: string;
  isDialogOpen: boolean;
  taskCategoryId: string;
  refreshTaskCountSummaryByUserForCategory: () => void;
}

type QueuePUBucketSettingsStateType = {
  existingMaxAssignments: number;
  newMaxAssignments: number;
  isMaxAssignmentsLoading: boolean;
  isUpdatingMaxAssignments: boolean;
  shouldShowCancelAndSaveButton: boolean;
  isDistributeAllLoading: boolean;
};

const initialQueuePUBucketSettingsState = {
  existingMaxAssignments: 0,
  newMaxAssignments: 0,
  isMaxAssignmentsLoading: false,
  isUpdatingMaxAssignments: false,
  shouldShowCancelAndSaveButton: false,
  isDistributeAllLoading: false,
};

const QueuePUBucketSettings = (
  props: IQueuePUBucketSettingsProps,
): JSX.Element => {
  const {
    categoryName,
    isDialogOpen: openDialog,
    handleCancel,
    taskCategoryId,
    refreshTaskCountSummaryByUserForCategory,
  } = props;

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(settingsValidationSchema),
  });

  const { enqueueSnackbar } = useSnackbar();

  const [queuePUBucketsSettingsState, setQueuePUBucketSettingsState] =
    useState<QueuePUBucketSettingsStateType>(initialQueuePUBucketSettingsState);

  const onClose = () => {
    reset();
    handleCancel();
  };

  // API section starts

  const getMaxAssignmentByTaskCategoryId = async () => {
    setQueuePUBucketSettingsState(
      produce(draft => {
        draft.isMaxAssignmentsLoading = true;
      }),
    );
    try {
      const getMaxAssignmentsByCategoryIdResponse =
        await getMaxAssignmentsByTaskCategoryId(taskCategoryId);
      setQueuePUBucketSettingsState(
        produce(draft => {
          draft.existingMaxAssignments =
            getMaxAssignmentsByCategoryIdResponse.data.maxAssignments;
          draft.newMaxAssignments =
            getMaxAssignmentsByCategoryIdResponse.data.maxAssignments;
        }),
      );
    } catch (errorResponse) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueuePUBucketSettingsState(
        produce(draft => {
          draft.isMaxAssignmentsLoading = false;
        }),
      );
    }
  };

  const updateMaxAssignmentsForCategoryId = async () => {
    setQueuePUBucketSettingsState(
      produce(draft => {
        draft.isUpdatingMaxAssignments = true;
      }),
    );
    try {
      const payload: UpdateMaxAssignmentsByTaskCategoryIdPayloadDto = {
        maxAssignments: queuePUBucketsSettingsState.newMaxAssignments,
      };
      const updateMaxAssignmentsByTaskCategoryIdResponse =
        await updateMaxAssignmentsByTaskCategoryId(taskCategoryId, payload);

      const successMessage = t(
        'ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.SETTINGS_DIALOG.SUCCESSFULLY_UPDATED',
      );
      enqueueSnackbar(successMessage, SnackBarConfig.getSuccess());

      setQueuePUBucketSettingsState(
        produce(draft => {
          draft.existingMaxAssignments =
            updateMaxAssignmentsByTaskCategoryIdResponse?.data
              ?.maxAssignments ?? 0;
        }),
      );
      onClose();
      refreshTaskCountSummaryByUserForCategory();
    } catch (errorResponse) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueuePUBucketSettingsState(
        produce(draft => {
          draft.isUpdatingMaxAssignments = false;
        }),
      );
    }
  };

  const distributeUnassignedQueueItemsByTaskCategoryId = async () => {
    setQueuePUBucketSettingsState(
      produce(draft => {
        draft.isDistributeAllLoading = true;
      }),
    );
    try {
      const distributeUnassignedQueueItemsResponse =
        await distributeUnassignedQueueItems(taskCategoryId);
      const successMessage =
        distributeUnassignedQueueItemsResponse?.data?.message ??
        t(
          'ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.SETTINGS_DIALOG.SUCCESSFULLY_DISTRIBUTED',
        );
      enqueueSnackbar(successMessage, SnackBarConfig.getSuccess());
      setQueuePUBucketSettingsState(
        produce(draft => {
          draft.isDistributeAllLoading = false;
        }),
      );
      onClose();
      refreshTaskCountSummaryByUserForCategory();
    } catch (errorResponse) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
      setQueuePUBucketSettingsState(
        produce(draft => {
          draft.isDistributeAllLoading = false;
        }),
      );
    }
  };

  // API section ends

  useEffect(() => {
    if (queuePUBucketsSettingsState.existingMaxAssignments) {
      setValue(
        AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.MAX_ASSIGNMENT_CONTROLLER,
        queuePUBucketsSettingsState.existingMaxAssignments,
      );
    }
  }, [queuePUBucketsSettingsState.existingMaxAssignments, setValue]);

  const handleInputChange = (event: any) => {
    let inputValue = event.target.value;
    // Remove any non-numeric characters including hyphens
    inputValue = inputValue.replace(/[^\d]/g, '');
    // Update the value of the input field
    event.target.value = inputValue;

    setQueuePUBucketSettingsState(
      produce(draft => {
        draft.newMaxAssignments = parseInt(inputValue);
      }),
    );
  };

  const onSubmit = async () => {
    await updateMaxAssignmentsForCategoryId();
  };

  const init = async () => {
    await getMaxAssignmentByTaskCategoryId();
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDistributeAllClick = async () => {
    await distributeUnassignedQueueItemsByTaskCategoryId();
  };

  const renderDialogContent = () => (
    <div>
      <DistributeAllContainer>
        <DistributeAllLabel>
          {t(
            'ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.SETTINGS_DIALOG.DO_YOU_WANT_DISTRIBUTE_ITEMS',
          )}
        </DistributeAllLabel>
        <DistributeAllButtonText>
          <DistributeAllButton
            onClick={handleDistributeAllClick}
            disabled={
              queuePUBucketsSettingsState.isDistributeAllLoading ||
              queuePUBucketsSettingsState.isMaxAssignmentsLoading ||
              queuePUBucketsSettingsState.isUpdatingMaxAssignments
            }
          >
            <span>
              {t(
                'ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.SETTINGS_DIALOG.YES_DISTRIBUTE',
              )}
            </span>
          </DistributeAllButton>
        </DistributeAllButtonText>
      </DistributeAllContainer>
      <DividerStyled />
      <Controller
        name={AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.MAX_ASSIGNMENT_CONTROLLER}
        control={control}
        defaultValue={queuePUBucketsSettingsState.existingMaxAssignments}
        render={({ field }) => (
          <>
            <LabelStyled>
              {t(
                'ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.SETTINGS_DIALOG.MAX_ASSIGNMENTS',
              )}
            </LabelStyled>
            <InputWrapperDiv>
              <TextField
                id="outlined-number"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: '25rem', height: '3rem' }}
                inputProps={{
                  step: 1, // Set step to 1 for natural numbers,
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  min: '1',
                  onInput: handleInputChange,
                }}
                {...field}
              />
            </InputWrapperDiv>
          </>
        )}
      />
      {errors.maxAssignments && (
        <SettingsErrorTextStyled>
          {t(`${errors.maxAssignments.message}`)}
        </SettingsErrorTextStyled>
      )}
    </div>
  );

  return (
    <QueuePUBucketSettingsDialogContentContainer>
      {queuePUBucketsSettingsState.isDistributeAllLoading ||
      queuePUBucketsSettingsState.isMaxAssignmentsLoading ||
      queuePUBucketsSettingsState.isUpdatingMaxAssignments ? (
        <MainLoaderContainer
          height={
            queuePUBucketsSettingsState.existingMaxAssignments !==
            queuePUBucketsSettingsState.newMaxAssignments
              ? '17.1875rem'
              : '15rem'
          }
        >
          <Loader size={40} />
        </MainLoaderContainer>
      ) : null}
      <FormDialog
        isDialogOpen={openDialog}
        handleCancel={onClose}
        height={
          categoryName.length < 22
            ? queuePUBucketsSettingsState.existingMaxAssignments !==
              queuePUBucketsSettingsState.newMaxAssignments
              ? '24.375rem'
              : '21.5625rem'
            : queuePUBucketsSettingsState.existingMaxAssignments !==
              queuePUBucketsSettingsState.newMaxAssignments
            ? '28rem'
            : '24rem'
        }
        showCancelAndSubmitButton={
          queuePUBucketsSettingsState.existingMaxAssignments !==
          queuePUBucketsSettingsState.newMaxAssignments
        }
        titleText={`${categoryName} ${t(
          'ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.SETTINGS_DIALOG.SETTINGS',
        )}`}
        subTitleText={t(
          'ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.SETTINGS_DIALOG.MANAGE_SETTINGS',
        )}
        dialogContent={
          queuePUBucketsSettingsState.isMaxAssignmentsLoading ? (
            <DialogContentLoaderContainer>
              <Loader size={40} />
            </DialogContentLoaderContainer>
          ) : (
            renderDialogContent()
          )
        }
        submitButtonText={t(
          'ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.SETTINGS_DIALOG.SAVE',
        )}
        cancelButtonText={t(
          'ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.SETTINGS_DIALOG.CANCEL',
        )}
        onSubmit={handleSubmit(onSubmit)}
        disableSubmitButton={
          queuePUBucketsSettingsState.isDistributeAllLoading ||
          queuePUBucketsSettingsState.isMaxAssignmentsLoading ||
          queuePUBucketsSettingsState.isUpdatingMaxAssignments
        }
        showSubmitButtonLoader={
          queuePUBucketsSettingsState.isUpdatingMaxAssignments
        }
        isSaveInProgress={queuePUBucketsSettingsState.isUpdatingMaxAssignments}
        submitButtonLoadingText={t(
          'ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.SETTINGS_DIALOG.SAVING',
        )}
      />
    </QueuePUBucketSettingsDialogContentContainer>
  );
};

export default QueuePUBucketSettings;
