import FormDialog from '@components/dialog/form-dialog/FormDialog';
import {
  LabelStyled,
  LabelStyledForStateOrNsa,
} from '@components/select-input/select-text-input/SelectTextInputStyle';
import { Controller, useForm } from 'react-hook-form';
import { t } from 'i18next';
import {
  DialogLoaderContainerDivStyled,
  ErrorTextStyled,
} from 'src/styles/common/CommonStyles';
import { DividerStyled } from '../../queue-assignments/QueueAssignmentsStyle';
import { useEffect, useState } from 'react';
import {
  getAllStates,
  getAllTaskTypes,
} from 'src/services/service-handlers/private/ref-data-handlers/RefDataPrivateService';
import { getErrorMessage } from '@utils/ErrorUtils';
import { produce } from 'immer';
import { enqueueSnackbar } from 'notistack';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import GroupedSelectInput from '@components/select-input/GroupedSelectInput';
import { APP } from 'src/styles/variables';
import {
  GroupedFilterOptionType,
  DropdownOptionsType,
} from 'src/types/adminTypes';
import { updateTaskType } from 'src/services/service-handlers/private/queue-handlers/QueuePrivateService';
import Loader from '@components/loaders/Loader';
import { DialogActionsContainerStyled } from '../flag-queue-item/FlagQueueItemStyle';
import {
  CancelButtonWidthStyled,
  SaveButtonWidthStyled,
} from './ChangeQueueItemStyle';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import { GetAllStatesDataRow } from 'src/services/service-handlers/private/ref-data-handlers/RefDataServiceMapper';
import SelectInput from '@components/select-input/SelectInput';
import { QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION } from '@constants/global-constants/constants';

interface IChangeQueueItemProps {
  handleCancel: () => void;
  isDialogOpen: boolean;
  bulkSelectedTaskIds?: string[];
  taskId?: string;
  stateId?: string;
  stateNsa?: string;
  taskCategory?: string;
  taskType?: string;
  refreshScreen?: (refreshQueueTableGrid: boolean) => void;
  resetSelectedRows?: () => void;
}

interface IChangeQueueItemState {
  isCategoryDropdownLoading: boolean;
  categoryDropdownOptions: GroupedFilterOptionType[] | DropdownOptionsType[];
  selectedTaskTypeId: string | number | undefined;
  selectedStateOrNsa?: string;
  selectedStateId?: string;
  preSelectedStateNsa: string | null | undefined;
  preSelectedTaskType: string | null | undefined;
  preSelectedTaskTypeId: string | null | undefined;
  preSelectedTaskTypeLayoutType: string | null | undefined;
  isSubmittingForm: boolean;
  stateOrNSADropdownOptions: DropdownOptionsType[];
  stateOrNSADropdownOptionsList: any[];
  isStateOrNSADropdownLoading: boolean;
}
type TaskTypesArray = {
  taskId: string;
  taskLabel: string;
  taskName: string;
  taskType: string;
  categoryLabel?: string;
  layoutType?: string;
};

type FormattedTaskTypesResponse = {
  categoryId?: string;
  categoryLabel?: string;
  categoryNameId?: string;
  taskTypes?: TaskTypesArray[] | null;
  taskId?: string;
  taskLabel?: string;
  taskName?: string;
  taskType?: string;
  layoutType?: string;
};

const ChangeQueueItem = (props: IChangeQueueItemProps): JSX.Element => {
  const {
    control,
    reset,
    formState: { errors },
  } = useForm({});

  const {
    handleCancel,
    isDialogOpen: openDialog,
    bulkSelectedTaskIds = [],
    taskId = '',
    stateId = '',
    stateNsa = '',
    taskCategory = '',
    taskType = '',
    refreshScreen,
    resetSelectedRows,
  } = props;

  const [changeQueueItemState, setChangeQueueItemState] =
    useState<IChangeQueueItemState>({
      isCategoryDropdownLoading: true,
      categoryDropdownOptions: [],
      stateOrNSADropdownOptions: [],
      selectedTaskTypeId: undefined,
      selectedStateOrNsa: undefined,
      selectedStateId: undefined,
      isSubmittingForm: false,
      stateOrNSADropdownOptionsList: [],
      isStateOrNSADropdownLoading: true,
      preSelectedStateNsa: '',
      preSelectedTaskType: '',
      preSelectedTaskTypeId: '',
      preSelectedTaskTypeLayoutType: '',
    });

  const stateOrNSADropdownOptionsFormatter = (
    rowDataArray: GetAllStatesDataRow[],
  ) => {
    return rowDataArray.map(rowItem => {
      const obj: any = {};
      obj.optionId = rowItem.id;
      obj.optionText = rowItem.label;
      return obj;
    });
  };

  const {
    categoryDropdownOptions,
    stateOrNSADropdownOptions,
    isCategoryDropdownLoading,
    selectedTaskTypeId,
    selectedStateOrNsa,
    selectedStateId,
    isSubmittingForm,
    isStateOrNSADropdownLoading,
    preSelectedStateNsa,
    preSelectedTaskType,
    preSelectedTaskTypeId,
    preSelectedTaskTypeLayoutType,
  } = changeQueueItemState;

  const isNonEmailBasedTasks = [
    AdminConstants.QUEUE.DB_QUERY_SHARED_SERVICES,
    AdminConstants.QUEUE.DATA_EXCEPTIONS_SHARED_SERVICES,
    AdminConstants.QUEUE.DATA_EXCEPTIONS_STATE_TEAM,
    AdminConstants.QUEUE.DATA_EXCEPTIONS_ARBIT_TEAM,
  ].includes(taskCategory);

  const fetchCategoryDropdownOptions = async () => {
    try {
      const response = await getAllTaskTypes({
        includeNonEmailTypes: isNonEmailBasedTasks,
      });
      const { data } = response;

      const formattedFlatArray: FormattedTaskTypesResponse[] = [];
      const rowData = [...data.rows];

      // making the nested dropdown options into flat array
      rowData.map(rowItem => {
        const obj: FormattedTaskTypesResponse = { ...rowItem };
        const taskTypesArray = obj.taskTypes;
        obj.taskTypes = null;
        formattedFlatArray.push(obj);
        if (taskTypesArray) {
          taskTypesArray.map(taskTypeItem => {
            const taskTypeItemObj = { ...taskTypeItem };
            taskTypeItemObj.categoryLabel = rowItem.categoryLabel;
            formattedFlatArray.push(taskTypeItemObj);
          });
        }
      });
      // mapping flat list data into dropdown options format to handle it in select input dropdown
      const formattedData = formattedFlatArray.map(flatItem => {
        const finalObj: GroupedFilterOptionType = {
          optionId: '',
          optionText: '',
          listHeader: '',
          layoutType: '',
        };

        if (flatItem.categoryId) {
          finalObj.optionId = flatItem.categoryId;
          finalObj.optionText = null;
          finalObj.listHeader = flatItem.categoryLabel;
          finalObj.layoutType = flatItem.layoutType;
          return finalObj;
        } else {
          finalObj.optionId = flatItem.taskId;
          finalObj.optionText = flatItem.taskLabel;
          finalObj.listHeader = flatItem.categoryLabel;
          finalObj.layoutType = flatItem.layoutType;
          return finalObj;
        }
      });

      const preSelectedTaskTypeOption = formattedData.find(
        option => option.optionText === taskType,
      );

      setChangeQueueItemState(
        produce(draft => {
          draft.categoryDropdownOptions = formattedData;
          draft.isCategoryDropdownLoading = false;
          draft.preSelectedTaskType = preSelectedTaskTypeOption
            ? preSelectedTaskTypeOption.optionText
            : null;
          draft.preSelectedTaskTypeId = preSelectedTaskTypeOption
            ? (preSelectedTaskTypeOption.optionId as string)
            : null;
          draft.preSelectedTaskTypeLayoutType = preSelectedTaskTypeOption
            ? preSelectedTaskTypeOption.layoutType
            : null;
        }),
      );
      return formattedData;
    } catch (e) {
      setChangeQueueItemState(
        produce(draft => {
          draft.isCategoryDropdownLoading = false;
        }),
      );
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    }
  };

  const fetchStateDropdownOptions = async () => {
    try {
      const response = await getAllStates();
      const { data } = response;
      const rowData = [...data.rows];

      let formattedStateOrNSAOptions: any = [];

      // adding NSA dropdown option
      formattedStateOrNSAOptions.push({
        optionId: AdminConstants.USERS.STATENSA,
        optionText: AdminConstants.USERS.NSA,
      });

      let renderValue: string | null | undefined;
      rowData.map(stateOrNSADropdownItem => {
        if (
          stateOrNSADropdownItem.id === stateId &&
          stateNsa &&
          stateNsa.toLowerCase() === AdminConstants.USERS.STATE.toLowerCase()
        ) {
          setChangeQueueItemState(
            produce(draft => {
              draft.selectedStateOrNsa =
                AdminConstants.USERS.STATE.toLowerCase();
              draft.selectedStateId = stateOrNSADropdownItem.id;
            }),
          );
          renderValue = stateOrNSADropdownItem.label;
        } else if (
          stateNsa &&
          stateNsa.toLowerCase() === AdminConstants.USERS.NSA.toLowerCase()
        ) {
          setChangeQueueItemState(
            produce(draft => {
              draft.selectedStateOrNsa = AdminConstants.USERS.NSA.toLowerCase();
            }),
          );
          renderValue = AdminConstants.USERS.NSA;
        }
      });

      // mapping list data into dropdown options format to handle it in select input dropdown
      rowData.map(rowItem => {
        const obj: any = {};
        obj.optionId = rowItem.id;
        obj.optionText = rowItem.label;

        formattedStateOrNSAOptions.push(obj);
      });

      setChangeQueueItemState(
        produce(draft => {
          draft.stateOrNSADropdownOptions = formattedStateOrNSAOptions;
        }),
      );

      formattedStateOrNSAOptions = [
        ...formattedStateOrNSAOptions,
        ...stateOrNSADropdownOptionsFormatter(rowData),
      ];

      setChangeQueueItemState(
        produce(draft => {
          draft.preSelectedStateNsa = renderValue;
        }),
      );

      setChangeQueueItemState(
        produce(draft => {
          draft.isStateOrNSADropdownLoading = false;
        }),
      );
      return formattedStateOrNSAOptions;
    } catch (e) {
      setChangeQueueItemState(
        produce(draft => {
          draft.isStateOrNSADropdownLoading = false;
        }),
      );
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    }
  };

  useEffect(() => {
    fetchCategoryDropdownOptions();
    fetchStateDropdownOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDropdownChange = (
    data: string | HTMLInputElement | undefined,
  ) => {
    const selectedTaskType = categoryDropdownOptions.find(
      categoryOptionItem => categoryOptionItem.optionText === data,
    );
    setChangeQueueItemState(
      produce(draft => {
        draft.selectedTaskTypeId = selectedTaskType?.optionId;
      }),
    );
  };

  const handleStateDropdownChange = (
    data: string | HTMLInputElement | undefined,
  ) => {
    const payload: any = {
      stateOrNsa: '',
      stateId: '',
    };
    changeQueueItemState.stateOrNSADropdownOptions.map(
      (stateOrNSAOptionItem: any) => {
        if (stateOrNSAOptionItem.optionText === data) {
          if (data === AdminConstants.USERS.NSA) {
            payload.stateOrNsa = AdminConstants.USERS.NSA.toLowerCase();
          } else {
            payload.stateOrNsa = AdminConstants.USERS.STATE.toLowerCase();
            payload.stateId = stateOrNSAOptionItem.optionId;
          }
        }
      },
    );
    setChangeQueueItemState(
      produce(draft => {
        draft.selectedStateOrNsa = payload?.stateOrNsa;
        draft.selectedStateId = payload?.stateId;
      }),
    );
  };

  const onClose = () => {
    if (!isSubmittingForm) {
      reset();
      handleCancel();
    }
  };

  const onSubmit = async () => {
    setChangeQueueItemState(
      produce(draft => {
        draft.isSubmittingForm = true;
      }),
    );
    const selectedTaskType = categoryDropdownOptions.find(
      option => option.optionId === selectedTaskTypeId,
    );
    try {
      let payload: any = {
        taskTypeId: selectedTaskTypeId
          ? selectedTaskTypeId
          : preSelectedTaskTypeId,
        ids: bulkSelectedTaskIds.length > 0 ? bulkSelectedTaskIds : [taskId],
      };

      if (
        !(
          selectedTaskType?.layoutType ===
            QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.DB_QUERY ||
          selectedTaskType?.layoutType ===
            QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.DATA_EXCEPTIONS
        )
      ) {
        payload = {
          ...payload,
          stateId: selectedStateId ? selectedStateId : null,
          stateNsa: selectedStateOrNsa,
        };
      }

      const res = await updateTaskType(payload);
      if (res) {
        enqueueSnackbar(res.data.message, SnackBarConfig.getSuccess());
        setChangeQueueItemState(
          produce(draft => {
            draft.isSubmittingForm = false;
          }),
        );
        resetSelectedRows?.();
        refreshScreen?.(true);
        onClose();
      }
    } catch (e) {
      setChangeQueueItemState(
        produce(draft => {
          draft.isSubmittingForm = false;
        }),
      );
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    }
  };

  const isSaveButtonEnabled = () => {
    const selectedTaskType = categoryDropdownOptions.find(
      option => option.optionId === selectedTaskTypeId,
    );
    const selectedState = stateOrNSADropdownOptions.find(
      option => option.optionId === selectedStateId,
    );

    const isStateSelected =
      selectedStateOrNsa?.toLowerCase() ===
      AdminConstants.USERS.STATE.toLowerCase();
    const isNsaSelected =
      selectedStateOrNsa?.toLowerCase() ===
      AdminConstants.USERS.NSA.toLowerCase();
    const hasStateChanged =
      selectedState?.optionText?.toLowerCase() !==
      preSelectedStateNsa?.toLowerCase();
    const hasNsaChanged =
      selectedStateOrNsa?.toLowerCase() !== preSelectedStateNsa?.toLowerCase();

    if (!isCategoryDropdownLoading && !isStateOrNSADropdownLoading) {
      if (
        selectedTaskType &&
        (selectedTaskType.layoutType ===
          QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.DATA_EXCEPTIONS ||
          selectedTaskType.layoutType ===
            QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.DB_QUERY)
      ) {
        return true;
      } else if (
        selectedTaskType &&
        selectedTaskType.layoutType !==
          QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.DATA_EXCEPTIONS &&
        selectedTaskType.layoutType !==
          QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.DB_QUERY &&
        selectedStateOrNsa
      ) {
        return true;
      } else if (
        !selectedTaskType &&
        ((isStateSelected && hasStateChanged) ||
          (isNsaSelected && hasNsaChanged))
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const renderFooterSection = () => {
    return (
      <DialogActionsContainerStyled>
        <CancelButtonWidthStyled onClick={onClose} disabled={isSubmittingForm}>
          {t('ADMIN.QUEUE_TABS.FLAG_QUEUE_ITEM.CANCEL')}
        </CancelButtonWidthStyled>
        <SaveButtonWidthStyled
          type="submit"
          onClick={onSubmit}
          disabled={!isSaveButtonEnabled()}
        >
          {isSubmittingForm ? (
            <Loader size={32} />
          ) : (
            t('ADMIN.QUEUE_TABS.CHANGE_QUEUE_TYPE.SAVE_AND_UPDATE')
          )}
        </SaveButtonWidthStyled>
      </DialogActionsContainerStyled>
    );
  };

  const titleText =
    bulkSelectedTaskIds.length > 0
      ? t(
          'ADMIN.QUEUE_TABS.BULK_ACTION_DIALOG.BULK_CHANGE_QUEUE_TYPE_DIALOG.TITLE',
        )
      : t('ADMIN.QUEUE_TABS.CHANGE_QUEUE_TYPE.CHANGE_QUEUE_TYPE');

  const subTitleText =
    bulkSelectedTaskIds.length > 0
      ? t(
          'ADMIN.QUEUE_TABS.BULK_ACTION_DIALOG.BULK_CHANGE_QUEUE_TYPE_DIALOG.SUBTITLE',
        )
      : t('ADMIN.QUEUE_TABS.CHANGE_QUEUE_TYPE.SELECT_NEW_TYPE');

  const shouldDisplayStateOrNSADropdown = ![
    QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.DB_QUERY,
    QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.DATA_EXCEPTIONS,
  ].includes(
    categoryDropdownOptions.find(
      option => option.optionId === selectedTaskTypeId,
    )?.layoutType ||
      preSelectedTaskTypeLayoutType ||
      '',
  );

  const dialogHeight =
    bulkSelectedTaskIds.length > 0
      ? 'auto'
      : shouldDisplayStateOrNSADropdown
      ? 'auto'
      : '20rem';

  return (
    <FormDialog
      width="27.5rem" // 440 px
      height={dialogHeight}
      isDialogOpen={openDialog}
      handleCancel={onClose}
      titleText={titleText}
      subTitleText={subTitleText}
      isSaveInProgress={isSubmittingForm}
      dialogContent={
        isCategoryDropdownLoading ? (
          <DialogLoaderContainerDivStyled>
            <Loader />
          </DialogLoaderContainerDivStyled>
        ) : (
          <div>
            <Controller
              name={'changeQueueItem'}
              control={control}
              render={({ field }) => (
                <>
                  <LabelStyled>
                    {t('ADMIN.QUEUE_TABS.FLAG_QUEUE_ITEM.SELECT_TASK_TYPE')}
                  </LabelStyled>
                  <GroupedSelectInput
                    groupedFilterOptions={categoryDropdownOptions}
                    isgroupingstylingneeded={'yes'}
                    preSelectedValue={preSelectedTaskType ?? ''}
                    handleOptionSelect={data => handleDropdownChange(data)}
                    backgroundColor={APP.PALETTE.BACKGROUND.PAPER}
                    borderRadius={'0'}
                    width={'100%'}
                    paddingMedium={'yes'}
                    boxShadow={'no'}
                    isBorderRadiusRequired={'yes'}
                  />
                  {shouldDisplayStateOrNSADropdown &&
                    (isStateOrNSADropdownLoading ? (
                      <DialogLoaderContainerDivStyled>
                        <Loader />
                      </DialogLoaderContainerDivStyled>
                    ) : (
                      <>
                        <LabelStyledForStateOrNsa>
                          {t(
                            'ADMIN.QUEUE_TABS.FLAG_QUEUE_ITEM.SELECT_NSA_OR_STATE',
                          )}
                        </LabelStyledForStateOrNsa>
                        <SelectInput
                          dropdownOptions={stateOrNSADropdownOptions}
                          preSelectedValue={preSelectedStateNsa ?? ''}
                          isgroupingstylingneeded={'yes'}
                          backgroundColor={APP.PALETTE.BACKGROUND.PAPER}
                          borderRadius={'0'}
                          width={'100%'}
                          paddingMedium={'yes'}
                          boxShadow={'no'}
                          isBorderRadiusRequired={'yes'}
                          showSelectedOption={true}
                          isDefaultValuePresent={true}
                          handleChange={data => handleStateDropdownChange(data)}
                        />
                      </>
                    ))}
                </>
              )}
            />

            {errors.changeQueueItem && (
              <ErrorTextStyled>
                {t(`${errors.changeQueueItem.message}`)}
              </ErrorTextStyled>
            )}
            <DividerStyled />
          </div>
        )
      }
      submitButtonText={''}
      cancelButtonText={''}
      onSubmit={onSubmit}
      renderFooterButtonSection={renderFooterSection()}
    />
  );
};

export default ChangeQueueItem;
