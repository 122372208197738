import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EmailSearchAndFilterPayloadType, EmailStateType } from './EmailTypes';

const initialState: EmailStateType = {
  emailSearchAndFilterOptions: {},
};

const EmailSlice = createSlice({
  initialState,
  name: 'emails',
  reducers: {
    setEmailSearchAndFilterValues(
      state,
      action: PayloadAction<EmailSearchAndFilterPayloadType>,
    ) {
      state.emailSearchAndFilterOptions = action.payload.data;
    },
  },
});

export const { setEmailSearchAndFilterValues } = EmailSlice.actions;

export default EmailSlice.reducer;
