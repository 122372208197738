import AppInitializer from './AppInitializer';
import { useEffect } from 'react';
import Services from './services/Services';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { config } from '@environment/environment';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, EventType } from '@azure/msal-browser';

// This component is just going to serve as a wrapper for the Main Application
// Any route independent global thing can be put here

const App = (): JSX.Element => {
  const msalInstance = new PublicClientApplication(config.msalConfig);
  msalInstance.addEventCallback((event: any) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      msalInstance.setActiveAccount(event?.payload?.account);
    }
  });

  const queryClient = new QueryClient();
  useEffect(() => {
    Services.init();
  }, []);

  return (
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <AppInitializer />
      </QueryClientProvider>
    </MsalProvider>
  );
};

export default App;
