const USER_PORTAL_SEARCH_AND_FILTER_CONFIGURATIONS = {
  QUEUE_SEARCH_AND_FILTER_CONFIG: {
    SEARCH_BY_OPTIONS: [
      { label: 'All', value: 'all' },
      { label: 'Case #', value: 'caseNumber' },
      { label: 'Customer', value: 'customerName' },
      { label: 'Dispute #', value: 'disputeNumber' },
      { label: 'Email', value: 'email' },
      { label: 'Payor', value: 'payorName' },
      { label: 'Payor Claim #', value: 'payorClaimNumber' },
    ],
    FILTER_OPTIONS: [
      { label: 'Status', value: 'taskStatus', type: 'dropdown' },
      { label: 'Priority', value: 'taskPriority', type: 'dropdown' },
      { label: 'Task Category', value: 'taskCategoryId', type: 'dropdown' },
      { label: 'Task Type', value: 'taskTypeId', type: 'dropdown' },
      { label: 'NSA/State', value: 'nsaState', type: 'dropdown' },
    ],
  },
  HISTORY_SEARCH_AND_FILTER_CONFIG: {
    SEARCH_BY_OPTIONS: [
      { label: 'All', value: 'all' },
      { label: 'Case #', value: 'caseNumber' },
      { label: 'Customer', value: 'customerName' },
      { label: 'Dispute #', value: 'disputeNumber' },
      { label: 'Email', value: 'email' },
      { label: 'Payor', value: 'payorName' },
      { label: 'Payor Claim #', value: 'payorClaimNumber' },
    ],
    FILTER_OPTIONS: [
      { label: 'Task Category', value: 'taskCategoryId', type: 'dropdown' },
      { label: 'Task Type', value: 'taskTypeId', type: 'dropdown' },
      { label: 'NSA/State', value: 'nsaState', type: 'dropdown' },
    ],
  },
};

export default USER_PORTAL_SEARCH_AND_FILTER_CONFIGURATIONS;
