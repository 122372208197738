import FormDialog from '@components/dialog/form-dialog/FormDialog';
import { SelectChangeEvent } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

// import CrossIcon from '@images/Close.svg';
import { produce } from 'immer';
import {
  DialogContentContainer,
  DividerStyled,
  Form,
  RoleSelectionDropdown,
  RoleSelectionDropdownContainer,
  TitleAndRoleLabel,
  TitleAndRoleSelectionDropdownContainer,
  TitleSelectionDropdown,
  TitleSelectionDropdownContainer,
  MainLoaderContainer,
} from './UpdateTitleAndRoleDialogStyle';
import { APP } from 'src/styles/variables';
import SelectTextInput from '@components/select-input/select-text-input/SelectTextInput';
import { useSnackbar } from 'notistack';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import Loader from '@components/loaders/Loader';
import { GetAllRolesDataRow } from 'src/services/service-handlers/private/roles-handlers/RolesServiceMapper';
import {
  getAllRoles,
  getAllTitles,
} from 'src/services/service-handlers/private/roles-handlers/RolesPrivateService';
import { IUpdatedTitleAndRole } from '../UsersSettings';
import { RoleIdType } from 'src/services/service-handlers/private/users-handlers/UsersServiceMapper';
import { getErrorMessage } from '@utils/ErrorUtils';

interface IUpdateTitleAndRoleDialogProps {
  handleClose: () => void;
  isDialogOpen: boolean;
  onSubmitTitleAndRoleSelection: (
    updateTitleAndRoleFormState: IUpdatedTitleAndRole,
  ) => void;
  currentSelectedRoles: RoleIdType[];
  currentSelectedTitle: {
    id: string;
    label: string;
  };
}

const mapDropdownOptionToDropdownOptionsType = (
  optionsList: GetAllRolesDataRow[],
) => {
  return optionsList.map(option => ({
    value: option.id,
    label: option.label,
  }));
};

const updateTitleAndRoleInitialFormState: IUpdatedTitleAndRole = {
  title: {
    id: '',
    label: '',
  },
  roles: [],
};

interface IAvailableFormData {
  availableAllTitles: GetAllRolesDataRow[];
  availableAllRoles: GetAllRolesDataRow[];
}

const UpdateTitleAndRoleDialog = (
  updateTitleAndRoleDialogProps: IUpdateTitleAndRoleDialogProps,
): JSX.Element => {
  const {
    handleClose,
    isDialogOpen,
    onSubmitTitleAndRoleSelection,
    currentSelectedRoles,
    currentSelectedTitle,
  } = updateTitleAndRoleDialogProps;
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit } = useForm({});

  const [updateTitleAndRoleFormState, setUpdateTitleAndRoleFormState] =
    useState<IUpdatedTitleAndRole>(updateTitleAndRoleInitialFormState);

  // TODO: use this when implementing multiple roles feature
  //   const [newCurrentSelectedRoles, setNewCurrentSelectedRoles] =
  //     useState(currentSelectedRoles);

  const [apiLoadersState, setApiLoadersState] = useState({
    isFetchingAllTitles: false,
    isFetchingAllRoles: false,
    isInitialFetchInProgress: false,
    isUpdatingTitleAndRole: false,
  });

  const [availableFormDataState, setAvailableFormDataState] =
    useState<IAvailableFormData>({
      availableAllTitles: [],
      availableAllRoles: [],
    });

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  const fetchAllTitles = async () => {
    setApiLoadersState(
      produce(draft => {
        draft.isFetchingAllTitles = true;
      }),
    );
    try {
      const getAllTitlesResponse = await getAllTitles();
      const allTitles = getAllTitlesResponse.data.rows;
      setAvailableFormDataState(
        produce(draft => {
          draft.availableAllTitles = allTitles;
        }),
      );
    } catch (errorResponse: any) {
      const errorMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    } finally {
      setApiLoadersState(
        produce(draft => {
          draft.isFetchingAllTitles = false;
        }),
      );
    }
  };

  const fetchAllRoles = async () => {
    setApiLoadersState(
      produce(draft => {
        draft.isFetchingAllRoles = true;
      }),
    );
    try {
      const getAllRolesResponse = await getAllRoles();
      const allRoles = getAllRolesResponse.data.rows;
      setAvailableFormDataState(
        produce(draft => {
          draft.availableAllRoles = allRoles;
        }),
      );
    } catch (errorResponse: any) {
      const errorMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    } finally {
      setApiLoadersState(
        produce(draft => {
          draft.isFetchingAllRoles = false;
        }),
      );
    }
  };

  const init = async () => {
    setApiLoadersState(
      produce(draft => {
        draft.isInitialFetchInProgress = true;
      }),
    );

    try {
      await Promise.all([fetchAllTitles(), fetchAllRoles()]);
      setUpdateTitleAndRoleFormState(
        produce(draft => {
          draft.title = currentSelectedTitle;
          draft.roles = currentSelectedRoles;
        }),
      );
    } catch (errorResponse: any) {
      const errorMessage = t('ADMIN.INIT_ERROR_MESSAGE');
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    } finally {
      setApiLoadersState(
        produce(draft => {
          draft.isInitialFetchInProgress = false;
        }),
      );
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateUpdateTitleAndRoleForm = (): boolean => {
    if (updateTitleAndRoleFormState.title.id.length <= 2) {
      enqueueSnackbar(
        t('ADMIN.USERS_TAB.ADD_USER_FORM.VALIDATION_MESSAGES.SELECT_A_TITLE'),
        SnackBarConfig.getError(),
      );
      return false;
    }

    if (
      updateTitleAndRoleFormState.roles[0] &&
      updateTitleAndRoleFormState.roles[0]?.roleId?.length <= 2
    ) {
      enqueueSnackbar(
        t('ADMIN.USERS_TAB.ADD_USER_FORM.VALIDATION_MESSAGES.SELECT_A_ROLE'),
        SnackBarConfig.getError(),
      );
      return false;
    }

    return true;
  };

  const handleTitleSelectChange = (
    event: SelectChangeEvent<string | string[]>,
  ) => {
    const selectedTitleId = event.target.value as string;
    const selectedTitleLabel = availableFormDataState.availableAllTitles.filter(
      title => title.id === selectedTitleId,
    )[0].label;

    setUpdateTitleAndRoleFormState(
      produce(draft => {
        draft.title.id = selectedTitleId;
        draft.title.label = selectedTitleLabel;
      }),
    );

    setIsSaveButtonDisabled(false);
  };

  const handleRoleSelectChange = (
    event: SelectChangeEvent<string | string[]>,
  ) => {
    const selectedRoleId = event.target.value as string;
    const selectedRole = availableFormDataState.availableAllRoles.filter(
      role => role.id === selectedRoleId,
    )[0];

    const roleIdTypeId = currentSelectedRoles.find(
      role => role.roleId === selectedRoleId,
    );

    let newRole: RoleIdType = {
      roleLabel: '',
      roleId: '',
    };
    if (roleIdTypeId) {
      newRole = roleIdTypeId;
      // TODO: use this when implementing multiple roles feature
      //   const newCurrentSelectedRolesAfterFilter = currentSelectedRoles.filter(
      //     currentSelectedRole => currentSelectedRole.roleId !== selectedRoleId,
      //   );
      //   setNewCurrentSelectedRoles([...newCurrentSelectedRolesAfterFilter]);
    } else {
      newRole = {
        roleLabel: selectedRole.label,
        roleId: selectedRole.id,
      };
    }

    setUpdateTitleAndRoleFormState(
      produce(draft => {
        // TODO: use this when implementing multiple roles feature
        // draft.roles = [...newCurrentSelectedRoles, newRole];
        draft.roles = [newRole];
      }),
    );

    setIsSaveButtonDisabled(false);
  };

  const renderTitleAndRoleSelectionDropdown = () => {
    return (
      <TitleAndRoleSelectionDropdownContainer>
        <TitleSelectionDropdownContainer>
          <TitleAndRoleLabel>
            {t('ADMIN.USERS_TAB.ADD_USER_FORM.TITLE_REQUIRED')}
          </TitleAndRoleLabel>
          <TitleSelectionDropdown>
            <SelectTextInput
              options={mapDropdownOptionToDropdownOptionsType(
                availableFormDataState.availableAllTitles,
              )}
              disabled={apiLoadersState.isInitialFetchInProgress}
              onChange={handleTitleSelectChange}
              variant={'filled'}
              placeholder={t('ADMIN.USERS_TAB.ADD_USER_FORM.SELECT')}
              label={t('ADMIN.USERS_TAB.ADD_USER_FORM.TITLE_REQUIRED')}
              selectValue={updateTitleAndRoleFormState.title.id}
            />
          </TitleSelectionDropdown>
        </TitleSelectionDropdownContainer>
        <RoleSelectionDropdownContainer>
          <TitleAndRoleLabel>
            {t('ADMIN.USERS_TAB.ADD_USER_FORM.ROLE_REQUIRED')}
          </TitleAndRoleLabel>
          <RoleSelectionDropdown>
            <SelectTextInput
              options={mapDropdownOptionToDropdownOptionsType(
                availableFormDataState.availableAllRoles,
              )}
              disabled={apiLoadersState.isInitialFetchInProgress}
              showPlaceHolder={true}
              onChange={handleRoleSelectChange}
              variant={'filled'}
              placeholder={t('ADMIN.USERS_TAB.ADD_USER_FORM.SELECT')}
              label={t('ADMIN.USERS_TAB.ADD_USER_FORM.ROLE_REQUIRED')}
              selectValue={updateTitleAndRoleFormState.roles[0]?.roleId ?? ''}
            />
          </RoleSelectionDropdown>
        </RoleSelectionDropdownContainer>
        <DividerStyled />
      </TitleAndRoleSelectionDropdownContainer>
    );
  };

  const onSubmit = () => {
    const validationResult = validateUpdateTitleAndRoleForm();
    if (validationResult) {
      onSubmitTitleAndRoleSelection(updateTitleAndRoleFormState);
    }
  };

  const renderDialogContent = () => {
    return (
      <DialogContentContainer>
        {apiLoadersState.isInitialFetchInProgress ? (
          <MainLoaderContainer>
            <Loader size={40} />
          </MainLoaderContainer>
        ) : (
          <Form
            disabled={
              apiLoadersState.isInitialFetchInProgress ||
              apiLoadersState.isUpdatingTitleAndRole
            }
          >
            {renderTitleAndRoleSelectionDropdown()}
          </Form>
        )}
      </DialogContentContainer>
    );
  };

  return (
    <FormDialog
      isDialogOpen={isDialogOpen}
      handleCancel={handleClose}
      titleText={t('ADMIN.USERS_TAB.USER_SETTINGS.EDIT_USER_DETAILS')}
      titleFontSize={APP.FONT.FONT_SIZE.H5}
      subTitleText=""
      dialogContent={renderDialogContent()}
      submitButtonText={t('ADMIN.USERS_TAB.USER_SETTINGS.SAVE')}
      submitButtonLoadingText={t('ADMIN.USERS_TAB.USER_SETTINGS.SAVING')}
      cancelButtonText={t('ADMIN.USERS_TAB.USER_SETTINGS.CANCEL')}
      onSubmit={handleSubmit(onSubmit)}
      isSaveInProgress={apiLoadersState.isUpdatingTitleAndRole}
      disableSubmitButton={
        isSaveButtonDisabled || apiLoadersState.isUpdatingTitleAndRole
      }
    />
  );
};

export default UpdateTitleAndRoleDialog;
