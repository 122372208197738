import {ReactElement} from 'react';
import {IconButtonProps} from '@mui/material';
import {CustomIconButton} from './IconButtonStyle';

interface IconButtonInputProps extends IconButtonProps {
  children: ReactElement;
  size?: 'small' | 'medium' | 'large';
}

const IconButton = (props: IconButtonInputProps): ReactElement => {
  const {children, size = 'small', ...rest} = props;

  return (
    <CustomIconButton size={size} {...rest}>
      {children}
    </CustomIconButton>
  );
};

export default IconButton;
