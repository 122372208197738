import styled from '@emotion/styled';
import { Grid } from '@mui/material';

export const MainContainer = styled.div``;

export const TopNavBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.75rem;
  border-bottom: 1px solid #d8d8d8;
`;

export const LogoContainer = styled.div`
  margin-left: 1.3125rem;
  cursor: pointer;
`;

export const CustomImg = styled('img')`
  height: 2.2rem;
  width: 10.345625rem;
`;

export const NavBarMenuItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
`;

interface NavBarMenuItemProps {
  isActive: boolean;
}

export const NavBarMenuItem = styled.div<NavBarMenuItemProps>`
  height: 2.5rem;
  width: 7.083333333rem;
  border-radius: 0.625rem;
  background-color: ${props => (props.isActive ? '#4480A633' : 'none')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const MenuItemName = styled.div<NavBarMenuItemProps>`
  opacity: 1;
  color: ${props =>
    props.isActive
      ? props.theme.palette.secondary.main
      : props.theme.palette.grey.A700};
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.3125rem;
  text-align: center;
`;

export const ProfilePictureAndWelcomeTextContainer = styled.div`
  margin-right: 0.875rem;
  display: flex;
`;

export const WelcomeTextAndProfileNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 0.5rem;
`;

export const WelcomeText = styled.div``;

export const ProfileName = styled.div`
  font-weight: 700;
`;

export const ProfilePictureContainer = styled.div`
  cursor: pointer;
`;

export const ProfilePicture = styled.div`
  height: 2.875rem;
  width: 2.875rem;
`;

export const BodyContainer = styled.div``;

export const LoadingGridForProfilePic = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
`;
