import styled from '@emotion/styled';
import { OutlinedInput } from '@mui/material';

export const CustomOutlinedInput = styled(OutlinedInput)`
  .MuiOutlinedInput-input {
    &:focus {
      background-color: transparent;
    }
  }
`;
