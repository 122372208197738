import { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useTranslation from 'i18next';
import { produce } from 'immer';

import {
  EmailDetailsContentSectionGrid,
  EmailDetailsWrapperGrid,
  EmailViewerSectionGrid,
  EmailDetailsHeadingSectionGrid,
  NavigationTypography,
  TopBarContentGrid,
  EmailDetailsLoadingGrid,
  EmailDetailsNoDataGrid,
  EmailDetailsTypography,
  TaskDetailTypographyLabel,
  TaskDetailTypographyValue,
  TaskDetailContentGrid,
  TaskDetailContentWrapperGrid,
  TaskDetailsHeaderGrid,
  TaskDetailsHeaderTypography,
  TaskDetailsSectionGrid,
  TaskDetailsLoadingGrid,
  TaskDetailsNoDataMessageTypography,
  TaskDetailsNoDataGrid,
} from './EmailDetailsStyle';
import TopBarWrapperGrid from '@components/top-bar-wrapper-grid/TopBarWrapperGrid';
import Loader from '@components/loaders/Loader';
import EmailRichTextViewer from '@components/email-rich-text-viewer/EmailRichTextViewer';
import { getEmailDetailsById } from 'src/services/service-handlers/private/email-handlers/EmailPrivateService';
import {
  GetEmailDetailsByIdDataDto,
  // GetEmailDetailsByIdFileDto,
  GetEmailDetailsByIdTaskDetailDto,
} from 'src/services/service-handlers/private/email-handlers/EmailServiceMapper';
import { capitalize } from '@utils/miscellaneousUtils';
import { getErrorMessage } from '@utils/ErrorUtils';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { enqueueSnackbar } from 'notistack';
import { formatDate } from '@modules/home/utils/Utils';
import { StateNsaAllStateEnum } from 'src/enums/admin-enums/AdminEnums';

interface IEmailAttachment {
  id: string;
  name: string;
  type: string;
}
interface IEmailDetails {
  id: string;
  emailSubject: string;
  emailFromValue: string;
  emailToValue: string;
  cc: string;
  emailReceivedAt: string;
  emailAttachments: IEmailAttachment[];
  emailBodyValue: string;
}

interface IEmailDetailsState {
  isTaskDetailsLoading: boolean;
  taskDetails: GetEmailDetailsByIdTaskDetailDto;
  isEmailDetailsLoading: boolean;
  emailDetails: IEmailDetails;
  shouldDialogOpen: boolean;
  isDialogContentLoading: boolean;
  dialogTitle: string;
  dialogContent: string;
  isInitialDataLoading: boolean;
}

// const mapEmailAttachments = (files: GetEmailDetailsByIdFileDto[] = []) => {
//   const newEmailAttachments = files.map(file => {
//     return {
//       id: file.id,
//       name: file.name,
//       type: file.type,
//     };
//   });
//   return newEmailAttachments;
// };

const mapEmailDetailsByIdApiResponseDataToEmailDetailsState = (
  data: GetEmailDetailsByIdDataDto,
) => {
  const newEmailDetails: IEmailDetails = {
    id: data.id,
    emailSubject: data?.subject ?? '',
    emailFromValue: data?.fromEmail ?? '',
    emailToValue: data?.toEmail ?? '',
    cc: data?.cc ?? '',
    emailReceivedAt: data?.receivedAt ?? '',
    emailAttachments: data?.files || [],
    emailBodyValue: data?.body ?? '',
  };
  return newEmailDetails;
};

const EmailDetails = (): ReactElement => {
  const { t } = useTranslation;
  const navigate = useNavigate();
  const { emailMessageId } = useParams();

  const [state, setState] = useState<IEmailDetailsState>({
    isTaskDetailsLoading: false,
    taskDetails: {
      id: '',
      caseId: '',
      taskType: {
        id: '',
        label: '',
      },
      customerName: '',
      stateNsa: {
        stateNsa: '',
        id: '',
        code: '',
        label: '',
      },
      payorName: '',
      assignedUser: '',
      dueDate: '',
      status: '',
    },
    isEmailDetailsLoading: false,
    emailDetails: {
      id: '',
      emailSubject: '',
      emailFromValue: '',
      emailToValue: '',
      cc: '',
      emailReceivedAt: '',
      emailAttachments: [],
      emailBodyValue: '',
    },
    shouldDialogOpen: false,
    isDialogContentLoading: false,
    dialogTitle: '',
    dialogContent: '',
    isInitialDataLoading: false,
  });

  const fetchEmailActivity = async () => {
    setState(
      produce(draft => {
        draft.isInitialDataLoading = true;
        draft.isEmailDetailsLoading = true;
        draft.isTaskDetailsLoading = true;
      }),
    );

    try {
      const getEmailDetailsByIdApiResponse = await getEmailDetailsById(
        emailMessageId ?? '',
      );
      const mappedEmailDetails =
        mapEmailDetailsByIdApiResponseDataToEmailDetailsState(
          getEmailDetailsByIdApiResponse.data,
        );
      setState(
        produce(draft => {
          draft.emailDetails = mappedEmailDetails;
          draft.taskDetails = getEmailDetailsByIdApiResponse.data.taskDetail;
        }),
      );
    } catch (errorResponse: any) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setState(
        produce(draft => {
          draft.isInitialDataLoading = false;
          draft.isEmailDetailsLoading = false;
          draft.isTaskDetailsLoading = false;
        }),
      );
    }
  };

  const init = async () => {
    if (emailMessageId) {
      await fetchEmailActivity();
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTopBar = () => {
    return (
      <TopBarWrapperGrid
        alignItems="center"
        justifyContent="center"
        height="5rem"
      >
        <TopBarContentGrid>
          <NavigationTypography onClick={() => navigate(-1)}>
            {t('PRIVATE.EMAILS.EMAIL_DETAILS.BACK_TO_EMAILS')}
          </NavigationTypography>
        </TopBarContentGrid>
      </TopBarWrapperGrid>
    );
  };

  const renderTaskDetailsContent = () => {
    const stateOrNsa = state.taskDetails?.stateNsa?.stateNsa
      ? state.taskDetails?.stateNsa?.stateNsa?.toLowerCase() ===
        StateNsaAllStateEnum.STATE
        ? state.taskDetails.stateNsa.label
        : state.taskDetails.stateNsa?.stateNsa?.toUpperCase()
      : '';

    if (state.taskDetails && !state.isTaskDetailsLoading) {
      const taskDetails = [
        { label: 'Case #', value: state.taskDetails.caseId },
        {
          label: 'Type',
          value: state.taskDetails.taskType.label,
        },
        {
          label: 'Customer',
          value: state.taskDetails.customerName,
        },
        {
          label: 'NSA/State',
          value: stateOrNsa,
        },
        {
          label: 'Payor',
          value: state.taskDetails.payorName,
        },
        {
          label: 'Assigned',
          value: state.taskDetails.assignedUser,
        },
        {
          label: 'Due Date',
          value: state.taskDetails.dueDate?.length
            ? formatDate(state.taskDetails.dueDate)
            : '',
        },
        {
          label: 'Status',
          value: capitalize(state.taskDetails.status),
        },
      ];

      return taskDetails.map(taskDetailItem => {
        return (
          <TaskDetailContentGrid key={taskDetailItem.label}>
            <TaskDetailTypographyLabel>
              {taskDetailItem.label}
            </TaskDetailTypographyLabel>
            <TaskDetailTypographyValue>
              {taskDetailItem.value}
            </TaskDetailTypographyValue>
          </TaskDetailContentGrid>
        );
      });
    } else if (state.isTaskDetailsLoading) {
      return (
        <TaskDetailsLoadingGrid>
          <Loader />
        </TaskDetailsLoadingGrid>
      );
    } else {
      return (
        <TaskDetailsNoDataGrid>
          <TaskDetailsNoDataMessageTypography>
            {t('PRIVATE.EMAILS.EMAIL_DETAILS.NO_TASK_DETAILS')}
          </TaskDetailsNoDataMessageTypography>
        </TaskDetailsNoDataGrid>
      );
    }
  };

  const renderTaskDetails = () => {
    return (
      <>
        <TaskDetailsHeaderGrid>
          <TaskDetailsHeaderTypography>
            {t('PRIVATE.EMAILS.EMAIL_DETAILS.TASK_DETAILS')}
          </TaskDetailsHeaderTypography>
        </TaskDetailsHeaderGrid>
        <TaskDetailContentWrapperGrid>
          {renderTaskDetailsContent()}
        </TaskDetailContentWrapperGrid>
      </>
    );
  };

  const renderEmailViewer = () => {
    if (!state.isEmailDetailsLoading && state.emailDetails.emailSubject) {
      return (
        <EmailRichTextViewer
          fromEmail={state.emailDetails.emailFromValue}
          toEmail={state.emailDetails.emailToValue}
          cc={state.emailDetails.cc ?? ''}
          files={state.emailDetails.emailAttachments ?? []}
          subject={state.emailDetails.emailSubject}
          body={state.emailDetails.emailBodyValue ?? ''}
          receivedAt={state.emailDetails.emailReceivedAt}
        />
      );
    } else if (state.isEmailDetailsLoading) {
      return (
        <EmailDetailsLoadingGrid>
          <Loader />
        </EmailDetailsLoadingGrid>
      );
    } else {
      return (
        <EmailDetailsNoDataGrid>
          <EmailDetailsTypography>
            {t('PRIVATE.EMAILS.EMAIL_DETAILS.NO_EMAIL_DETAILS')}
          </EmailDetailsTypography>
        </EmailDetailsNoDataGrid>
      );
    }
  };

  return (
    <EmailDetailsWrapperGrid>
      <EmailDetailsHeadingSectionGrid>
        {renderTopBar()}
      </EmailDetailsHeadingSectionGrid>

      <EmailDetailsContentSectionGrid>
        <EmailViewerSectionGrid>{renderEmailViewer()}</EmailViewerSectionGrid>

        <TaskDetailsSectionGrid>{renderTaskDetails()}</TaskDetailsSectionGrid>
      </EmailDetailsContentSectionGrid>
    </EmailDetailsWrapperGrid>
  );
};

export default EmailDetails;
