import FormDialog from '@components/dialog/form-dialog/FormDialog';
import { LabelStyled } from '@components/select-input/select-text-input/SelectTextInputStyle';
import { Controller, useForm } from 'react-hook-form';
import {
  CancelButtonWidthStyled,
  DialogActionsContainerStyled,
  SaveButtonWidthStyled,
} from './FlagQueueItemStyle';
import { t } from 'i18next';
import { APP } from 'src/styles/variables';
import SelectTextInput from '@components/select-input/select-text-input/SelectTextInput';
import {
  DialogLoaderContainerDivStyled,
  ErrorTextStyled,
} from 'src/styles/common/CommonStyles';
import { ChangeEvent, useEffect, useState } from 'react';
import { getErrorMessage } from '@utils/ErrorUtils';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { enqueueSnackbar } from 'notistack';
import Loader from '@components/loaders/Loader';
import { produce } from 'immer';
import { updateFlagTask } from 'src/services/service-handlers/private/queue-handlers/QueuePrivateService';

interface Option {
  value: string;
  label: string;
}

interface IFlagQueueItemProps {
  handleCancel: () => void;
  isDialogOpen: boolean;
  taskId?: string;
  handleSave?: () => void;
  refreshScreen?: (refreshQueueTableGrid: boolean) => void;
}

interface IFlagQueueItemState {
  selectReason: string | null;
  isSubmitLoader: boolean;
  isFlagItemOptionsLoader: boolean;
  flagReasons: Option[];
}

const FlagQueueItem = (props: IFlagQueueItemProps): JSX.Element => {
  const {
    control,
    reset,
    formState: { errors },
  } = useForm({});
  const {
    isDialogOpen: openDialog,
    handleCancel,
    taskId,
    handleSave,
    refreshScreen,
  } = props;

  const [flagQueueItemState, setFlagQueueItemState] =
    useState<IFlagQueueItemState>({
      selectReason: '',
      isSubmitLoader: false,
      isFlagItemOptionsLoader: true,
      flagReasons: [],
    });

  const { selectReason, isSubmitLoader, isFlagItemOptionsLoader, flagReasons } =
    flagQueueItemState;

  const onClose = () => {
    reset();
    handleCancel();
  };

  const onSubmit = async () => {
    try {
      if (taskId) {
        setFlagQueueItemState(
          produce(draft => {
            draft.isSubmitLoader = true;
          }),
        );
        const payload = {
          reason: selectReason,
        };
        const res = await updateFlagTask(taskId, payload);
        if (res) {
          enqueueSnackbar(res.data.message, SnackBarConfig.getSuccess());
          setFlagQueueItemState(
            produce(draft => {
              draft.isSubmitLoader = false;
            }),
          );
          onClose();
          handleSave?.();
          refreshScreen?.(true);
        }
      } else {
        enqueueSnackbar(
          t('USER.ERROR_MESSAGE.UNABLE_TO_FIND_TASK_ID'),
          SnackBarConfig.getError(),
        );
        return;
      }
    } catch (e) {
      setFlagQueueItemState(
        produce(draft => {
          draft.isSubmitLoader = false;
        }),
      );
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    }
  };

  // TODO: change flagReasonsList array in furture
  const flagReasonsList = [
    {
      id: '1',
      reason: 'Manager Review',
      description: 'Select if you want your Manager to review this item.',
    },
    {
      id: '2',
      reason: 'Incorrect Classification',
      description: 'Mark that the item is classified incorrectly.',
    },
  ];

  const renderFlagItemOptions = () => {
    try {
      const flagReasonList = flagReasonsList.map(item => ({
        value: item.reason,
        label: item.reason,
      }));

      setFlagQueueItemState(
        produce(draft => {
          draft.flagReasons = flagReasonList;
          draft.isFlagItemOptionsLoader = false;
        }),
      );
    } catch (e) {
      setFlagQueueItemState(
        produce(draft => {
          draft.isFlagItemOptionsLoader = false;
        }),
      );
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    }
  };

  useEffect(() => {
    renderFlagItemOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderFooterSection = () => {
    return (
      <DialogActionsContainerStyled>
        <CancelButtonWidthStyled onClick={onClose} disabled={isSubmitLoader}>
          {t('ADMIN.QUEUE_TABS.FLAG_QUEUE_ITEM.CANCEL')}
        </CancelButtonWidthStyled>
        <SaveButtonWidthStyled
          type="submit"
          onClick={onSubmit}
          disabled={selectReason == ''}
        >
          {isSubmitLoader ? (
            <Loader size={32} />
          ) : (
            t('ADMIN.QUEUE_TABS.FLAG_QUEUE_ITEM.SUBMIT_FLAG')
          )}
        </SaveButtonWidthStyled>
      </DialogActionsContainerStyled>
    );
  };
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFlagQueueItemState(
      produce(draft => {
        draft.selectReason = event.target.value;
      }),
    );
  };

  return (
    <FormDialog
      width="34.25rem" // 548px
      isDialogOpen={openDialog}
      handleCancel={onClose}
      titleText={t('ADMIN.QUEUE_TABS.FLAG_QUEUE_ITEM.FLAG_QUEUE_ITEM')}
      subTitleText={t('ADMIN.QUEUE_TABS.FLAG_QUEUE_ITEM.DESCRIPTION')}
      subTitleTextColor={APP.PALETTE.COMMON.BLACK}
      isSaveInProgress={isSubmitLoader}
      dialogContent={
        <div>
          {isFlagItemOptionsLoader ? (
            <DialogLoaderContainerDivStyled>
              <Loader />
            </DialogLoaderContainerDivStyled>
          ) : (
            <Controller
              name={'flagQueueItem'}
              control={control}
              render={({ field }) => (
                <>
                  <LabelStyled>
                    {`${t('ADMIN.QUEUE_TABS.FLAG_QUEUE_ITEM.SELECT')}*`}
                  </LabelStyled>
                  <SelectTextInput
                    renderValue={value =>
                      selectReason ||
                      t('ADMIN.QUEUE_TABS.FLAG_QUEUE_ITEM.SELECT')
                    }
                    placeholder=""
                    onChange={(e: any) => handleOnChange(e)}
                    defaultValue={field.value}
                    options={flagReasons}
                    variant={'filled'}
                    style={{ width: '31.5rem', marginBottom: '1rem' }}
                  />
                </>
              )}
            />
          )}
          {errors.flagQueueItem && (
            <ErrorTextStyled>
              {t(`${errors.flagQueueItem.message}`)}
            </ErrorTextStyled>
          )}
        </div>
      }
      submitButtonText={''}
      cancelButtonText={''}
      onSubmit={onSubmit}
      renderFooterButtonSection={renderFooterSection()}
    />
  );
};

export default FlagQueueItem;
