import styled from '@emotion/styled';
import { APP } from 'src/styles/variables';

export const LabelStyled = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
  color: ${APP.PALETTE.COMMON.BLACK};
  margin-bottom: 0.2rem;
`;

export const LabelStyledForStateOrNsa = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
  color: ${APP.PALETTE.COMMON.BLACK};
  margin-top: 1.5rem;
  margin-bottom: 0.2rem;
`;
