import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { APP } from 'src/styles/variables';

export const FilterDropdownWithBadgeCountContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
  position: relative;
`;

export const FilterContainer = styled.div`
  display: flex;
`;

export const FilterIcon = styled.div``;

export const SelectFilterGrid = styled(Grid)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BadgeCountContainer = styled.div`
  background-color: ${APP.PALETTE.BACKGROUND.CHIP_RED};
  /* min-width: 1.5rem; */
  width: 19px;
  /* height: 1.5rem; */
  height: 19px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 0.375rem; */
`;

export const BadgeCount = styled.div`
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_10PX};
  color: ${APP.PALETTE.COMMON.WHITE};
`;

interface IChildrenContainer {
  top?: string;
  left?: string;
}

export const ChildrenContainer = styled.div<IChildrenContainer>`
  position: absolute;
  top: ${props => props.top ?? '31px'};
  left: ${props => props.left ?? '5px'};
`;
