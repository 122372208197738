import { baseUrl } from '../../common/ApiEndPoint';

const queueBaseUrl = `${baseUrl}/queue`;

export const queueAIBaseUrl = `${queueBaseUrl}/ai`;
export const getAllQueueListUrl = `${queueBaseUrl}/all`;
export const getAllQueueHistoryListUrl = `${queueBaseUrl}/history`;
export const getAllQueueUnclassifiedListUrl = `${queueBaseUrl}/unclassified`;
export const updateAssignedUserUrl = `${queueBaseUrl}/user`;
export const updateFlagTaskUrl = `${queueBaseUrl}/:id/flag`;
export const updateTaskTypeUrl = `${queueBaseUrl}/task-type`;

// queue assignments API URLs
const queueAssignmentsBaseUrl = `${queueBaseUrl}/assignments`;
export const getTaskAssignmentsSummaryByUserUrl = `${queueAssignmentsBaseUrl}/by-user/summary`;

const queueAssignmentsByTaskCategoryIdBaseUrl = `${queueAssignmentsBaseUrl}/by-category/:taskCategoryId`;
export const getMaxTaskAssignmentsByTaskCategoryIdUrl = `${queueAssignmentsByTaskCategoryIdBaseUrl}/max-assignment`;
export const updateMaxTaskAssignmentsByTaskCategoryIdUrl = `${queueAssignmentsByTaskCategoryIdBaseUrl}/max-assignment`;
export const getTasksCountSummaryByTaskCategoryIdUrl = `${queueAssignmentsByTaskCategoryIdBaseUrl}/summary`;
export const getTasksCountForUsersSummaryByTaskCategoryIdUrl = `${queueAssignmentsByTaskCategoryIdBaseUrl}/users`;
export const getAllNonAssignedUsersByTaskCategoryIdUrl = `${queueAssignmentsByTaskCategoryIdBaseUrl}/users/level/assignable/all`;
export const getAssignableUsersInCategoryByTaskCategoryIdUrl = `${queueAssignmentsByTaskCategoryIdBaseUrl}/users/level/assignable`;
export const addUsersUnderTaskCategoryByTaskCategoryIdUrl = `${queueAssignmentsByTaskCategoryIdBaseUrl}/users`;
export const distributeUnassignedQueueItemsUrl = `${queueAssignmentsByTaskCategoryIdBaseUrl}/distribute-queue`;
export const archiveUnclassifiedUnFlaggedQueueItemByTaskCategoryIdUrl = `${queueBaseUrl}/:taskCategoryId/archive`;
