import TextButton from '@components/buttons/text-button/TextButton';
import styled from '@emotion/styled';
import { ButtonGroup, Divider, Grid, IconButton } from '@mui/material';
import { APP } from 'src/styles/variables';

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
`;

export const ButtonGroupContainerStyled = styled('div')`
  display: flex;
  width: '100%';
  justify-content: space-between;
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
  height: 2.5rem;
  padding: 0 0.4rem;
`;

export const LeftButtonGroupDiv = styled('div')``;

export const RightButtonGroupDiv = styled('div')`
  padding-left: 3.5rem;
`;

export const TextButtonStyled = styled(TextButton)`
  font-family: ${APP.FONT.FONT_FAMILY.PRIMARY};
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE2};
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  padding: 0 0.8rem;
  margin: 0;
  height: 100%;
`;
export const ButtonTextStyled = styled('span')`
  margin-right: 0.5rem;
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
`;
export const ButtonSubTextStyled = styled('span')`
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
`;

export const InputWrapperDiv = styled.div`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    opacity: 1;
  }
`;

export const DividerStyled = styled(Divider)`
  border-color: ${APP.PALETTE.GREY.SEMI_GRAY};
  border-width: 0 0 0 2px;
  height: 1.5rem;
`;

export const ButtonGroupStyled = styled(ButtonGroup)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActionIconButtonStyled = styled(IconButton)`
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  padding: 0.4rem;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionButtonGroupStyled = styled(ButtonGroup)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TypeHeaderStyled = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.H6};
  font-family: ${APP.FONT.FONT_FAMILY.PRIMARY};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  display: flex;
  padding: 2rem 1rem 1rem 1rem;
  align-items: center;
  justify-content: flex-start;
`;

export const DotStyled = styled('div')`
  width: ${APP.FONT.FONT_SIZE.BODY3};
  height: ${APP.FONT.FONT_SIZE.BODY3};
  background-color: ${({ bgColor }: { bgColor?: string }) =>
    bgColor || APP.PALETTE.TABS.MAIN};
  border-radius: 50%;
  margin-right: 0.5rem;
`;

export const GridColumnStyled = styled(Grid)`
  border-right: 2px solid ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
`;

export const NoUserFound = styled.div`
  font-family: ${APP.FONT.FONT_FAMILY.SEMIBOLD};
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE2};
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  text-align: center;
  padding-top: 1rem;
`;
