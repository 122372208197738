import {
  AzureLoginParams,
  LoginParams,
  LoginResponseMapper,
} from './LoginServiceMapper';
import ServiceRequest from '../../../ServiceRequest';
import { azureLoginUrl, loginUrl } from './LoginApiEndPoints';

export const loginService = async (
  values: LoginParams,
): Promise<LoginResponseMapper> => {
  return await ServiceRequest.post(loginUrl, {
    payload: values,
  });
};

export const azureLoginService = async (
  data: AzureLoginParams,
): Promise<any> => {
  return await ServiceRequest.post(azureLoginUrl, { payload: data });
};
