// import { EmailsDummyData } from '@modules/home/main/admin/emails/EmailsDummyData';
import ServiceRequest from 'src/services/ServiceRequest';
import {
  getAllEmailSettingsURL,
  getAllEmailsUrl,
  getEmailDetailsByIdUrl,
  getEmailSettingsDetailURL,
  updateEmailSettingsDetailURL,
} from './EmailApiEndPoints';
import {
  GetAllEmailQueryParams,
  GetAllEmailSettingsParams,
  GetAllEmailSettingsResponseData,
  GetAllEmailsResDto,
  GetEmailDetailsByIdResDto,
  GetEmailSettingsDetailParams,
  GetEmailSettingsDetailResponseData,
  UpdateBulkEmailSettingsDetailParams,
  UpdateEmailSettingsDetailParams,
  UpdateEmailSettingsDetailResponseData,
} from './EmailServiceMapper';

export const fetchAllEmailSettingsList = async (
  data?: GetAllEmailSettingsParams,
): Promise<GetAllEmailSettingsResponseData> => {
  return await ServiceRequest.get(getAllEmailSettingsURL, { payload: data });
};

export const fetchEmailSettingsDetail = async (
  data: GetEmailSettingsDetailParams,
): Promise<GetEmailSettingsDetailResponseData> => {
  const formedURL = getEmailSettingsDetailURL + '/' + data;
  return await ServiceRequest.get(formedURL);
};

export const updateEmailSettingsDetail = async (
  data: UpdateEmailSettingsDetailParams,
): Promise<UpdateEmailSettingsDetailResponseData> => {
  const { bodyParams, pathParam } = data;
  const formedURL = updateEmailSettingsDetailURL + '/' + pathParam;
  return await ServiceRequest.put(formedURL, {
    payload: bodyParams,
  });
};

export const updateBulkEmailSettingsDetailURL = async (
  data: UpdateBulkEmailSettingsDetailParams,
): Promise<UpdateEmailSettingsDetailResponseData> => {
  return await ServiceRequest.put(updateEmailSettingsDetailURL, {
    payload: data,
  });
};

// emails apis
export const getAllEmails = async (
  data: GetAllEmailQueryParams,
): Promise<GetAllEmailsResDto> => {
  return await ServiceRequest.get(getAllEmailsUrl, { payload: data });
};

export const getEmailDetailsById = async (
  id: string,
): Promise<GetEmailDetailsByIdResDto> => {
  return await ServiceRequest.get(getEmailDetailsByIdUrl.replace(':id', id));
};
