import ServiceRequest from 'src/services/ServiceRequest';
import {
  getTaskDetailsByIdUrl,
  getArbitByTaskIdUrl,
  getEmailByTaskIdUrl,
  updateIdentifiersByTaskIdUrl,
  updateTaskStatusByIdUrl,
  getProgressBarUrl,
  getTaskExceptionsByTaskIdUrl,
  getEmailIdentifiersByTaskIdUrl,
  // getDBQueryDataByTaskIdUrl, // TODO: update once APIs are ready
  getActionsTakenDetailsByIdUrl,
  getDBQueryDataByTaskIdUrl,
  getReassignUsersListByTaskIdUrl,
  retryFailedQueueAITaskUrl,
} from './TasksApiEndPoints';
import {
  ArbitResponseData,
  EmailResDto,
  GetActionsTakenDetailsResDto,
  GetDBQueryDetailsByTaskIdResDto,
  GetEmailIdentifiersByTaskIdResDto,
  GetReassignUsersListByTaskIdResDto,
  GetTaskExceptionsByTaskIdResDto,
  GetProgressQueryParams,
  TaskDetailsResponse,
  UpdateEmailIdentifiersParams,
  UpdateTaskStatusByIdParams,
  GetProgressDataResDto,
  RetryFailedQueueAITaskResDto,
} from './TasksServiceMapper';

export const getTaskDetailsById = async (
  id = '',
): Promise<TaskDetailsResponse> => {
  return await ServiceRequest.get(getTaskDetailsByIdUrl.replace(':id', id));
};

export const getArbitByTaskId = async (id = ''): Promise<ArbitResponseData> => {
  return await ServiceRequest.get(getArbitByTaskIdUrl.replace(':id', id));
};

export const getEmailByTaskId = async (id = ''): Promise<EmailResDto> => {
  return await ServiceRequest.get(getEmailByTaskIdUrl.replace(':id', id));
};

export const getEmailIdentifiersByTaskId = async (
  id = '',
): Promise<GetEmailIdentifiersByTaskIdResDto> => {
  return await ServiceRequest.get(
    getEmailIdentifiersByTaskIdUrl.replace(':id', id),
  );
};

export const updateIdentifiersByTaskId = async (
  id = '',
  data: UpdateEmailIdentifiersParams,
): Promise<any> => {
  return await ServiceRequest.put(
    updateIdentifiersByTaskIdUrl.replace(':id', id),
    {
      payload: data,
    },
  );
};

export const updateTaskStatusById = async (
  id = '',
  data: UpdateTaskStatusByIdParams,
): Promise<any> => {
  return await ServiceRequest.put(updateTaskStatusByIdUrl.replace(':id', id), {
    payload: data,
  });
};

export const getTaskProgress = async (
  data: GetProgressQueryParams,
): Promise<GetProgressDataResDto> => {
  return await ServiceRequest.get(getProgressBarUrl, {
    payload: data,
  });
};

export const getTaskExceptionsByTaskId = async (
  id = '',
): Promise<GetTaskExceptionsByTaskIdResDto> => {
  return await ServiceRequest.get(
    getTaskExceptionsByTaskIdUrl.replace(':id', id),
  );
};

export const getDBQueryDetailsByTaskId = async (
  id = '',
): Promise<GetDBQueryDetailsByTaskIdResDto> => {
  return await ServiceRequest.get(getDBQueryDataByTaskIdUrl.replace(':id', id));
};

export const getActionsTakenDetailsById = async (
  id = '',
): Promise<GetActionsTakenDetailsResDto> => {
  return await ServiceRequest.get(
    getActionsTakenDetailsByIdUrl.replace(':id', id),
  );
};

export const getReassignUsersListByTaskId = async (
  id = '',
): Promise<GetReassignUsersListByTaskIdResDto> => {
  return await ServiceRequest.get(
    getReassignUsersListByTaskIdUrl.replace(':id', id),
  );
};

export const retryFailedQueueAITask = async (
  taskId = '',
): Promise<RetryFailedQueueAITaskResDto> => {
  return await ServiceRequest.put(
    retryFailedQueueAITaskUrl.replace(':id', taskId),
  );
};
