import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import {
  BodyContainerWrapper,
  QueueTopBarStyled,
} from './QueueDetailsViewLayoutStyle';

interface QueueDetailsViewLayoutProps {
  topBarChildren: ReactNode;
  leftSectionChildren: ReactNode;
  rightSectionChildren?: ReactNode;
}

const QueueDetailsViewLayout: FC<QueueDetailsViewLayoutProps> = ({
  topBarChildren,
  leftSectionChildren,
  rightSectionChildren,
}) => {
  return (
    <Box>
      <QueueTopBarStyled>{topBarChildren}</QueueTopBarStyled>
      <BodyContainerWrapper>
        {leftSectionChildren}
        {rightSectionChildren}
      </BodyContainerWrapper>
    </Box>
  );
};

export default QueueDetailsViewLayout;
