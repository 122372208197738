import { ReactElement } from 'react';
import { ButtonProps } from '@mui/material';
import { CustomTextButton } from './TextButtonStyle';

interface TextButtonProps extends ButtonProps {
  fontSize?: number | string;
  isDisabled?: boolean;
  variant?: 'contained' | 'outlined' | 'text';
}

const TextButton = (props: TextButtonProps): ReactElement => {
  const {
    variant = 'text',
    fontSize = '1rem',
    isDisabled = false,
    ...rest
  } = props;

  return (
    <CustomTextButton
      variant={variant}
      fontSize={fontSize}
      disabled={isDisabled}
      {...rest}
    />
  );
};

export default TextButton;
