import { ReactElement, useEffect, useState, useRef } from 'react';
import { InteractionType } from '@azure/msal-browser';
import { useMsalAuthentication } from '@azure/msal-react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import AuthUtils from '@utils/AuthUtils';
import { login } from '../../../home/state/auth/AuthStateSlice';
import AppLogo from '@assets/images/AppLogo.png';
import {
  Container,
  StyledPaper,
  LogoContainer,
  HeaderStyled,
  LabelDivStyled,
  ContainerDivStyled,
  ButtonWrapperStyled,
  PasswordContainerStyled,
} from './LoginStyle';
import { t } from 'i18next';
import {
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CircularProgressStyled,
  ErrorTextStyled,
} from 'src/styles/common/CommonStyles';
import { loginValidationSchema } from 'src/validation-schema/common/CommonValidationSchema';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { getErrorMessage } from '@utils/ErrorUtils';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import { useNavigate } from 'react-router-dom';
import {
  azureLoginService,
  loginService,
} from 'src/services/service-handlers/public/login-handler/LoginPublicService';
import Services from 'src/services/Services';

const Login = (): ReactElement => {
  const [showPassword, setShowPassword] = useState(false);
  const [renderLoginForm, setRenderLoginForm] = useState(false);
  const [isLogging, setIsLogging] = useState(false);
  const [isLoggingWithAzure, setIsLoggingWithAzure] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginValidationSchema),
  });

  const dispatch = useDispatch();
  const { login: msalLogin, result } = useMsalAuthentication(
    InteractionType.Silent,
  );
  const timerRef = useRef<number | null>(null);
  const token = AuthUtils.getAuthToken();

  const handleTogglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const onLoginWithAzure = async () => {
    setIsLogging(true);
    setIsLoggingWithAzure(true);
    msalLogin(InteractionType.Redirect);
  };

  useEffect(() => {
    // Render the login form after 4500 milliseconds if no token is set
    timerRef.current = window.setTimeout(() => {
      setRenderLoginForm(true);
      timerRef.current = null;
    }, 2500);

    // Clear the timer when the component unmounts or when a token is set
    return () => {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }
    };
  }, [token]);

  useEffect(() => {
    const init = async () => {
      const idToken = result?.idToken || '';
      if (idToken) {
        try {
          const payload = {
            accessToken: result?.accessToken,
            idToken: result?.idToken,
            tokenType: result?.tokenType,
            uniqueId: result?.uniqueId,
            username: result?.account?.username,
            requestId: result?.requestId,
            tenantId: result?.tenantId,
          };
          const res = await azureLoginService(payload);
          if (res) {
            dispatch(login());
            Services.initAfterAuth({ token: res?.data?.accessToken });
            AuthUtils.setAuthToken(res?.data?.accessToken);
            if (timerRef.current !== null) {
              clearTimeout(timerRef.current);
            }
            setRenderLoginForm(true);
            dispatch(login());
            navigate(PrivateNavigationRoutes.Landing.Path);
          }
        } catch (e) {
          const errMessage = getErrorMessage(e);
          enqueueSnackbar(errMessage, SnackBarConfig.getError());
        } finally {
          setIsLogging(false);
          setIsLoggingWithAzure(false);
        }
      }
    };
    init();
  }, [
    dispatch,
    enqueueSnackbar,
    navigate,
    result?.accessToken,
    result?.account?.username,
    result?.idToken,
    result?.requestId,
    result?.tenantId,
    result?.tokenType,
    result?.uniqueId,
  ]);

  const onSubmit = async (values: { email: string; password: string }) => {
    setIsLogging(true);
    const payload = {
      email: values.email,
      password: values.password,
    };
    try {
      const response = await loginService(payload);
      const { data } = response;
      if (data?.accessToken !== null) {
        Services.initAfterAuth({ token: data.accessToken });
        AuthUtils.setAuthToken(data.accessToken);
        if (timerRef.current !== null) {
          clearTimeout(timerRef.current);
        }
        dispatch(login());
        navigate(PrivateNavigationRoutes.Landing.Path);
      }
    } catch (error) {
      const errMessage = getErrorMessage(error);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setIsLogging(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const renderLoginSection = () => {
    if (!renderLoginForm) {
      return (
        <CircularProgressStyled>
          <CircularProgress />
        </CircularProgressStyled>
      );
    }
    return (
      <form onSubmit={handleKeyDown}>
        <StyledPaper elevation={3}>
          <LogoContainer>
            <img src={AppLogo} alt="App Logo" />
          </LogoContainer>
          <HeaderStyled>{t('PUBLIC.LOG_IN.PORTAL_LOGIN')}</HeaderStyled>
          <div>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div>
                  <LabelDivStyled>{t('PUBLIC.LOG_IN.EMAIL')}</LabelDivStyled>
                  <TextField {...field} label="" variant="outlined" fullWidth />
                </div>
              )}
            />
          </div>
          {errors.email && (
            <ErrorTextStyled>{t(`${errors.email.message}`)}</ErrorTextStyled>
          )}
          <PasswordContainerStyled>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div>
                  <LabelDivStyled>{t('PUBLIC.LOG_IN.PASSWORD')}</LabelDivStyled>
                  <TextField
                    {...field}
                    label=""
                    variant="outlined"
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              )}
            />
            {errors.password && (
              <ErrorTextStyled>
                {t(`${errors.password.message}`)}
              </ErrorTextStyled>
            )}
          </PasswordContainerStyled>

          <ContainerDivStyled>
            <ButtonWrapperStyled
              type="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={isLogging}
            >
              {isLogging && !isLoggingWithAzure
                ? t('PUBLIC.LOG_IN.LOGGING_IN')
                : t('PUBLIC.LOG_IN.SUBMIT')}
            </ButtonWrapperStyled>

            <Divider>{t('PUBLIC.LOG_IN.OR')}</Divider>

            <ButtonWrapperStyled
              type="submit"
              onClick={onLoginWithAzure}
              disabled={isLogging}
            >
              {t('PUBLIC.LOG_IN.SUBMIT_WITH_AZURE')}
            </ButtonWrapperStyled>
          </ContainerDivStyled>
        </StyledPaper>
      </form>
    );
  };

  return <Container>{renderLoginSection()}</Container>;
};

export default Login;
