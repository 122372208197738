import TopBarWrapperGrid from '@components/top-bar-wrapper-grid/TopBarWrapperGrid';

import { useEffect, useState } from 'react';

import {
  AddUserButtonContainer,
  AddUserText,
  CustomAddIcon,
  RightMenuOptionsContainer,
  TabItem,
  TabItemText,
  TabsContainer,
  UsersMainContainer,
  UsersTabBodyContainer,
} from './UsersStyle';

import { t } from 'i18next';
import { UsersTabByStatusType } from 'src/types/adminTypes';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import UsersPuAddNewUserDialog from './users-pu-add-new-user-dialog/UsersPuAddNewUserDialog';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import { produce } from 'immer';
import FilterDropdownWithBadgeCount from '@components/filter-dropdown-with-badge-count/FilterDropdownWithBadgeCount';
import SearchAndFilterDialog from '@components/dialog/search-and-filter-dialog/SearchAndFilterDialog';
import { IsSearchAndFilterAppliedPayloadType } from '@modules/home/state/users/UsersTypes';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsSearchAndFilterApplied,
  setUserSearchAndFilterValues,
} from '@modules/home/state/users/UsersSlice';
import { RootState } from 'src/store/Store';
import { executeAfterTimeout } from '@modules/home/utils/Utils';

type UsersStateType = {
  isLoading: boolean;
  showSearchAndFilterDropdown: boolean;
};

const initialUsersState = {
  isLoading: false,
  showSearchAndFilterDropdown: false,
};

const USERS_SEARCH_AND_FILTER_OPTIONS_CONFIG = {
  SEARCH_BY_OPTIONS: [
    { label: 'All', value: 'all' },
    { label: 'Name', value: 'name' },
    { label: 'Email', value: 'email' },
  ],
  FILTER_OPTIONS: [
    { label: 'Title', value: 'title', type: 'dropdown' },
    // { label: 'Role', value: 'role', type: 'dropdown' }, // TODO: enable once API has this feature
  ],
};

const Users = (): JSX.Element => {
  const [showAddNewUserDialog, setShowAddNewUserDialog] = useState(false);
  const [isCurrentPathForUserSettings, setIsCurrentPathForUserSettings] =
    useState(false);
  const [usersState, setUsersState] =
    useState<UsersStateType>(initialUsersState);

  const location = useLocation();

  const dispatch = useDispatch();
  const { userSearchAndFilterOptions } = useSelector(
    (state: RootState) => state.users,
  );

  const state = location?.state;

  const usersTabByStatus: UsersTabByStatusType[] = [
    {
      name: t('ADMIN.USERS_TAB.ACTIVE'),
      isActive:
        location.pathname ===
        PrivateNavigationRoutes.Admins.ChildRoutes.Users.ChildRoutes.ActiveUsers
          .FullPath,
      navigationURL:
        PrivateNavigationRoutes.Admins.ChildRoutes.Users.ChildRoutes.ActiveUsers
          .Path,
    },
    {
      name: t('ADMIN.USERS_TAB.INACTIVE'),
      isActive:
        location.pathname ===
        PrivateNavigationRoutes.Admins.ChildRoutes.Users.ChildRoutes
          .InactiveUsers.FullPath,
      navigationURL:
        PrivateNavigationRoutes.Admins.ChildRoutes.Users.ChildRoutes
          .InactiveUsers.Path,
    },
  ];

  const [usersTabByStatusList, setUsersTabByStatusList] =
    useState(usersTabByStatus);

  const navigate = useNavigate();

  const setCurrentActiveTab = (tabItemName: string) => {
    const newUsersTabByStatusList = usersTabByStatusList.map(
      (newUserTabByStatus: UsersTabByStatusType) => ({
        ...newUserTabByStatus,
        isActive:
          newUserTabByStatus.name.toLowerCase() === tabItemName.toLowerCase(),
      }),
    );
    setUsersTabByStatusList(newUsersTabByStatusList);
  };

  useEffect(() => {
    if (state) {
      if (state?.addedNewUserSuccessfully) {
        setCurrentActiveTab(AdminConstants.USERS.ACTIVE);
        state.addedNewUserSuccessfully = false;
        navigate(
          PrivateNavigationRoutes.Admins.ChildRoutes.Users.ChildRoutes
            .ActiveUsers.FullPath,
        );
      } else if (state.updatedUserSettings && state.userActivityStatus) {
        if (state.userActivityStatus === AdminConstants.USERS.ACTIVE) {
          setCurrentActiveTab(AdminConstants.USERS.ACTIVE);
          state.updatedUserSettings = false;
          state.userActivityStatus = '';
          navigate(
            PrivateNavigationRoutes.Admins.ChildRoutes.Users.ChildRoutes
              .ActiveUsers.FullPath,
          );
        } else if (state.userActivityStatus === AdminConstants.USERS.INACTIVE) {
          setCurrentActiveTab(AdminConstants.USERS.INACTIVE);
          state.updatedUserSettings = false;
          state.userActivityStatus = '';
          navigate(
            PrivateNavigationRoutes.Admins.ChildRoutes.Users.ChildRoutes
              .InactiveUsers.FullPath,
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state,
    state?.addedNewUserSuccessfully,
    state?.updatedUserSettings,
    state?.userActivityStatus,
  ]);

  const renderUserTabItemByStatus = (
    userTabItemByStatus: UsersTabByStatusType,
    index: number,
  ) => (
    <TabItem
      key={`${userTabItemByStatus.name}-${index}`}
      isActive={userTabItemByStatus.isActive}
      onClick={() => {
        setCurrentActiveTab(userTabItemByStatus.name);
        navigate(userTabItemByStatus.navigationURL, {
          replace: true,
        });

        dispatch(setUserSearchAndFilterValues({ data: {} }));
      }}
    >
      <TabItemText isActive={userTabItemByStatus.isActive}>
        {userTabItemByStatus.name}
      </TabItemText>
    </TabItem>
  );

  const renderUsersTabsByStatus = () => (
    <TabsContainer>
      {usersTabByStatus.map(
        (userTabByStatusItem: UsersTabByStatusType, index: number) =>
          renderUserTabItemByStatus(userTabByStatusItem, index),
      )}
    </TabsContainer>
  );

  // search filter dropdown starts

  const handleFilterIconClick = () => {
    setUsersState(
      produce(draft => {
        draft.showSearchAndFilterDropdown = !draft.showSearchAndFilterDropdown;
      }),
    );
  };

  const handleSearchAndFilterDialogCloseBtnClick = () => {
    setUsersState(
      produce(draft => {
        draft.showSearchAndFilterDropdown = false;
      }),
    );
  };

  const handleSearchAndFilterDialogApplyBtnClick = (data: any) => {
    if (!data?.searchValue) {
      delete data?.searchKey;
      delete data?.searchValue;
    }

    const values = Object.values(data);

    for (let i = 0; i < values.length; i++) {
      const valueItem = values[i];
      if (valueItem) {
        dispatch(setUserSearchAndFilterValues({ data: data }));

        const isSearchAndFilterAppliedPayload: IsSearchAndFilterAppliedPayloadType =
          { isSearchAndFilterApplied: true };
        dispatch(setIsSearchAndFilterApplied(isSearchAndFilterAppliedPayload));
        break;
      }
    }

    executeAfterTimeout(() => {
      setUsersState(
        produce(draft => {
          draft.showSearchAndFilterDropdown = false;
        }),
      );
    }, 100);
  };

  const handleSearchAndFilterDialogClearBtnClick = () => {
    dispatch(setUserSearchAndFilterValues({ data: {} }));

    if (
      userSearchAndFilterOptions !== undefined &&
      Object.entries(userSearchAndFilterOptions).length !== 0
    ) {
      const isSearchAndFilterAppliedPayload: IsSearchAndFilterAppliedPayloadType =
        { isSearchAndFilterApplied: true };
      dispatch(setIsSearchAndFilterApplied(isSearchAndFilterAppliedPayload));
    }

    executeAfterTimeout(() => {
      setUsersState(
        produce(draft => {
          draft.showSearchAndFilterDropdown = false;
        }),
      );
    }, 100);
  };

  const renderSearchAndFilterDialog = () => {
    const dialogTitle = t('ADMIN.USERS_TAB.SEARCH_AND_FILTER_DIALOG_TITLE');
    return (
      <SearchAndFilterDialog
        open={usersState.showSearchAndFilterDropdown}
        dialogTitle={dialogTitle}
        handleCloseBtnClick={handleSearchAndFilterDialogCloseBtnClick}
        handleApplyFilterClick={data =>
          handleSearchAndFilterDialogApplyBtnClick(data)
        }
        handleClearFilterClick={handleSearchAndFilterDialogClearBtnClick}
        configuration={USERS_SEARCH_AND_FILTER_OPTIONS_CONFIG}
      />
    );
  };

  const renderFilterIcon = () => (
    <div onClick={handleFilterIconClick}>
      <FilterDropdownWithBadgeCount isBadgeCountRequired={false} />
    </div>
  );

  // search and filter dropdown ends

  const renderAddUserButton = () => (
    <AddUserButtonContainer onClick={() => setShowAddNewUserDialog(true)}>
      <CustomAddIcon />
      <AddUserText>Users</AddUserText>
    </AddUserButtonContainer>
  );

  const renderRightMenuOptions = () => (
    <RightMenuOptionsContainer>
      {renderFilterIcon()}
      {renderAddUserButton()}
    </RightMenuOptionsContainer>
  );

  const renderAddNewUserDialog = () => (
    <UsersPuAddNewUserDialog
      handleClose={() => {
        setShowAddNewUserDialog(false);
      }}
      onSubmitDialogClose={() => {
        setShowAddNewUserDialog(false);
      }}
      isDialogOpen={showAddNewUserDialog}
    />
  );

  useEffect(() => {
    const checkForUserSettingsScreenPath = () => {
      const subPathNames = location.pathname.split('/');
      const isCurrentRouteForUserSettings =
        subPathNames[subPathNames.length - 1] ===
        AdminConstants.USERS.USER_SETTINGS.SETTINGS;
      setIsCurrentPathForUserSettings(isCurrentRouteForUserSettings);
    };
    checkForUserSettingsScreenPath();
  }, [location.pathname]);

  return (
    <UsersMainContainer>
      {!isCurrentPathForUserSettings ? (
        <TopBarWrapperGrid
          height="5rem"
          alignItems="center"
          justifyContent="space-between"
        >
          {renderUsersTabsByStatus()}
          {renderRightMenuOptions()}
        </TopBarWrapperGrid>
      ) : null}

      <UsersTabBodyContainer>
        <Outlet />
      </UsersTabBodyContainer>
      {showAddNewUserDialog &&
        !isCurrentPathForUserSettings &&
        renderAddNewUserDialog()}
      {renderSearchAndFilterDialog()}
    </UsersMainContainer>
  );
};

export default Users;
