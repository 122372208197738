import { OptionsObject, SnackbarOrigin } from 'notistack';
class SnackBarConfig {
  static MAX_SNACKBARS = 5;
  static DEFAULT_DURATION = 5000;
  static DEFAULT_ANCHOR: SnackbarOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  };
  static DEFAULT_CONFIG: OptionsObject = {
    variant: 'default',
    autoHideDuration: this.DEFAULT_DURATION,
    anchorOrigin: this.DEFAULT_ANCHOR,
  };
  static getSuccess(): OptionsObject {
    return { ...this.DEFAULT_CONFIG, variant: 'success' };
  }
  static getError(): OptionsObject {
    return { ...this.DEFAULT_CONFIG, variant: 'error' };
  }
  static getWarning(): OptionsObject {
    return { ...this.DEFAULT_CONFIG, variant: 'warning' };
  }
  static getInfo(): OptionsObject {
    return { ...this.DEFAULT_CONFIG, variant: 'info' };
  }
  static getDefault(): OptionsObject {
    return { ...this.DEFAULT_CONFIG, variant: 'default' };
  }
}
export default SnackBarConfig;
