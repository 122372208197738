import { config } from '@environment/environment';
import * as CryptoJS from 'crypto-js';

const key = config.ENCRYPT_LOCAL_STORAGE_KEY ?? '';

export const encrypt = (value: string): string => {
  return key ? CryptoJS.AES.encrypt(value, key).toString() : value;
};

export const decrypt = (value: string | null): string => {
  try {
    return value
      ? key
        ? CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8)
        : value
      : '';
  } catch (e) {
    return value || '';
  }
};
