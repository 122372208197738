import FormDialog from '@components/dialog/form-dialog/FormDialog';
import { useForm } from 'react-hook-form';
import { t } from 'i18next';
import { ErrorTextStyled } from 'src/styles/common/CommonStyles';
import { DividerStyled } from '../../queue-assignments/QueueAssignmentsStyle';
import {
  AssignedUser,
  UpdateAssignedUserPayloadDto,
} from 'src/services/service-handlers/private/queue-handlers/QueueServiceMapper';
import SearchableSelectInput from '@components/select-input/select-text-input/searchable-select-input/SearchableSelectInput';
import { useEffect, useState } from 'react';
import { getErrorMessage } from '@utils/ErrorUtils';
import { enqueueSnackbar } from 'notistack';
import { fetchAllUsersFromOurSystem } from 'src/services/service-handlers/private/users-handlers/UsersPrivateService';
import { GetAllUsersFromOurSystemQueryParamFormatEnum } from 'src/enums/admin-enums/AdminEnums';
import { GetAllUsersFromOurSystemQueryParam } from 'src/services/service-handlers/private/users-handlers/UsersServiceMapper';
import { produce } from 'immer';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { LoaderContainer } from './BulkReAssignUserDialogStyle';
import Loader from '@components/loaders/Loader';
import { updateAssignedUser } from 'src/services/service-handlers/private/queue-handlers/QueuePrivateService';
import { ProfilePicRefType } from 'src/types/Types';

interface IBulkReAssignUserDialogProps {
  handleCancel: () => void;
  isDialogOpen: boolean;
  BulkSelectedTaskIds: string[];
  refreshScreen?: (value: boolean) => void;
  resetSelectedRows: () => void;
  fetchedProfilePicRef: React.MutableRefObject<ProfilePicRefType>;
  setFetchProfilePicRef: (userId: string) => void;
}

interface IBulkReAssignUserState {
  isReAssignUserModalOpen: boolean;
  isUsersListLoading: boolean;
  isBulkUpdateProcessing: boolean;
  usersList: [] | AssignedUser[];
  limit: number;
  offset: number;
  totalCount: number;
  selectedUser: AssignedUser;
}

const initialBulkReAssignUserState: IBulkReAssignUserState = {
  isReAssignUserModalOpen: false,
  isUsersListLoading: false,
  isBulkUpdateProcessing: false,
  usersList: [],
  limit: 50,
  offset: 0,
  totalCount: 0,
  selectedUser: {
    contactEmail: null,
    email: '',
    id: '1',
    name: '',
    profilePicId: null,
  },
};

const BulkReAssignUserDialog = (
  props: IBulkReAssignUserDialogProps,
): JSX.Element => {
  const {
    reset,
    formState: { errors },
  } = useForm({});
  const {
    isDialogOpen: openDialog,
    handleCancel,
    BulkSelectedTaskIds,
    refreshScreen,
    resetSelectedRows,
    fetchedProfilePicRef,
    setFetchProfilePicRef,
  } = props;

  const [bulkReAssignUserState, setBulkReAssignUserState] =
    useState<IBulkReAssignUserState>(initialBulkReAssignUserState);

  const onClose = () => {
    if (!bulkReAssignUserState.isBulkUpdateProcessing) {
      reset();
      handleCancel();
    }
  };

  const onSubmitBulkReAssignUser = async () => {
    setBulkReAssignUserState(
      produce(draft => {
        draft.isBulkUpdateProcessing = true;
      }),
    );
    try {
      const payload: UpdateAssignedUserPayloadDto = {
        ids: BulkSelectedTaskIds,
        userId: bulkReAssignUserState.selectedUser.id,
      };
      const updateBulkReAssignUserForSelectedTasksApiResponse =
        await updateAssignedUser(payload);
      const successMessage =
        updateBulkReAssignUserForSelectedTasksApiResponse.data.message;
      enqueueSnackbar(successMessage, SnackBarConfig.getSuccess());
      setBulkReAssignUserState(
        produce(draft => {
          draft.isBulkUpdateProcessing = false;
        }),
      );
      resetSelectedRows();
      refreshScreen?.(true);
      onClose();
    } catch (errorResponse) {
      const errorMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
      setBulkReAssignUserState(
        produce(draft => {
          draft.isBulkUpdateProcessing = false;
        }),
      );
    }
  };

  const fetchData = async () => {
    try {
      setBulkReAssignUserState(
        produce(draft => {
          draft.isUsersListLoading = true;
        }),
      );
      const payLoadData: GetAllUsersFromOurSystemQueryParam = {
        isActive: true,
        format: GetAllUsersFromOurSystemQueryParamFormatEnum.BASIC,
        limit: bulkReAssignUserState.limit,
        offset: bulkReAssignUserState.offset,
      };

      const userListRes = await fetchAllUsersFromOurSystem(payLoadData);
      setBulkReAssignUserState(
        produce(draft => {
          draft.usersList = userListRes?.data?.rows ?? [];
          draft.totalCount = userListRes.data.count;
          draft.offset = userListRes?.data?.rows
            ? userListRes?.data?.rows.length + 1
            : 0;
        }),
      );
    } catch (e) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setBulkReAssignUserState(
        produce(draft => {
          draft.isUsersListLoading = false;
        }),
      );
    }
  };

  const init = async () => {
    await fetchData();
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePopOverOpenClose = (isModalOpen = false) => {
    setBulkReAssignUserState(
      produce(draft => {
        draft.isReAssignUserModalOpen = isModalOpen;
      }),
    );
  };

  const handleOptionSelectChange = (newValue: AssignedUser) => {
    setBulkReAssignUserState(
      produce(draft => {
        draft.selectedUser = newValue;
      }),
    );
  };

  const renderSearchableSelectInput = () => {
    return (
      <div>
        <SearchableSelectInput
          width=" 24.5rem"
          options={bulkReAssignUserState.usersList}
          handlePopOverOpenClose={(isModalOpen?: boolean) => {
            handlePopOverOpenClose(isModalOpen);
          }}
          openDropdown={bulkReAssignUserState.isReAssignUserModalOpen}
          isDefaultOptionRequired={false}
          handleOptionSelectChange={handleOptionSelectChange}
          shouldLoadProfilePicsForBulkReAssign={true}
          fetchedProfilePicRef={fetchedProfilePicRef}
          setFetchProfilePicRef={setFetchProfilePicRef}
        />
      </div>
    );
  };

  const renderFormDialog = () => (
    <FormDialog
      width="27.5rem" // 440 px
      isDialogOpen={openDialog}
      handleCancel={onClose}
      titleText={t(
        'ADMIN.QUEUE_TABS.BULK_ACTION_DIALOG.BULK_RE_ASSIGN_USER_DIALOG.TITLE',
      )}
      subTitleText={t(
        'ADMIN.QUEUE_TABS.BULK_ACTION_DIALOG.BULK_RE_ASSIGN_USER_DIALOG.SUBTITLE',
      )}
      dialogContent={
        <div>
          {bulkReAssignUserState.isUsersListLoading ? (
            <LoaderContainer>
              <Loader size={40} />
            </LoaderContainer>
          ) : (
            renderSearchableSelectInput()
          )}

          {errors.changeQueueItem && (
            <ErrorTextStyled>
              {t(`${errors.changeQueueItem.message}`)}
            </ErrorTextStyled>
          )}
          <DividerStyled style={{ marginTop: '2.5rem' }} />
        </div>
      }
      submitButtonText={t(
        'ADMIN.QUEUE_TABS.BULK_ACTION_DIALOG.BULK_RE_ASSIGN_USER_DIALOG.SAVE_AND_UPDATE',
      )}
      cancelButtonText={t(
        'ADMIN.QUEUE_TABS.BULK_ACTION_DIALOG.BULK_RE_ASSIGN_USER_DIALOG.CANCEL',
      )}
      disableSubmitButton={
        bulkReAssignUserState.isUsersListLoading ||
        bulkReAssignUserState.isBulkUpdateProcessing
      }
      onSubmit={onSubmitBulkReAssignUser}
    />
  );

  return renderFormDialog();
};

export default BulkReAssignUserDialog;
