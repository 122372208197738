import styled from '@emotion/styled';
import { APP } from 'src/styles/variables';

export const QueueTopBarStyled = styled('div')`
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-right: 0.5rem;
`;

export const BodyContainerWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(100vh - 6rem);
`;

export const LeftSectionDivStyled = styled('div')`
  width: 62%;
`;

export const RightSectionDivStyled = styled('div')`
  width: 38%;
`;

export const RightTopSectionChildrenStyled = styled('div')`
  height: 70%;
`;

export const RightBottomSectionChildrenStyled = styled('div')`
  height: 30%;
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
`;
