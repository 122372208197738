import { ReactElement, useState, useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthUtils from './utils/AuthUtils';
import Services from './services/Services';
import { RootState } from './store/Store';
import { login, logout } from '@modules/home/state/auth/AuthStateSlice';
import PrivateHome from '@modules/home/container/PrivateHome';
import PublicHome from '@modules/public/main/public-home/PublicHome';

const AppInitializer = (): ReactElement => {
  const [isAppReady, setAppReady] = useState(false);
  const dispatch = useDispatch();
  const token = AuthUtils.getAuthToken();
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );

  const attachAppListeners = (): void => {
    // Any app listeners to be added
  };
  const removeAppListeners = (): void => {
    // App app listeners to be removed
  };

  useEffect(() => {
    const onMount = async () => {
      if (token) {
        dispatch(login());
        attachAppListeners();
        Services.initAfterAuth({ token });
      } else {
        dispatch(logout());
      }
      setTimeout(() => {
        setAppReady(true);
      }, 100);
    };
    onMount();
    return () => {
      removeAppListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderRoutes = (): JSX.Element => {
    if (!isAppReady) {
      return <div>...loading</div>;
    } else if (isAuthenticated) {
      return <PrivateHome />;
    } else {
      return <PublicHome />;
    }
  };
  return <Suspense>{renderRoutes()}</Suspense>;
};
export default AppInitializer;
