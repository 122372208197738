import IconButton from '@components/buttons/icon-button/IconButton';
import styled from '@emotion/styled';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { APP } from 'src/styles/variables';

export const DialogHeaderSectionGrid = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: ${APP.PALETTE.GREY.SEMI_GRAY};
  padding: 0 0.625rem;
`;

export const DialogTitleTypography = styled(DialogTitle)`
  padding: 0 0 0 0.5rem;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${APP.FONT.FONT_SIZE.H5}
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
  white-space: nowrap;
  width: 84%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DownloadButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.312rem;
  border-radius: 4px;
  border: ${APP.PALETTE.GREY.SEMI_GRAY};
  background: ${APP.PALETTE.COMMON.WHITE};
  padding: 0.5rem 0.875rem;

  &:hover {
    background-color: ${APP.PALETTE.COMMON.WHITE};
  }
`;

export const DownloadIconImg = styled('img')`
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
`;

export const DownloadTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
`;

export const DialogContentWrapperGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 1.375rem 2.125rem;
  overflow: auto;
`;

export const DialogContentDiv = styled(DialogContent)`
  padding: 0;
  width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const AttachmentLoaderWrapperGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.625rem;
`;

export const AttachmentViewerIFrame = styled('iframe')`
  border: 0;
  height: 70vh !important;
  min-width: 100% !important;
`;

export const DialogContentHeaderTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${APP.FONT.FONT_SIZE.H5};
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
  width: 100%;
  text-align: left;
`;

export const DialogActionsSectionGrid = styled(DialogActions)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1.125rem;
  padding: 0.625rem;
`;

export const CloseIconImg = styled('img')`
  cursor: pointer;
  height: 0.987rem;
  width: 0.987rem;
`;

export const NoPreviewAvailable = styled.div`
  font-size: ${APP.FONT.FONT_SIZE.H6};
  font-weight: ${APP.FONT.FONT_WEIGHT.H6};
`;
