import React from 'react';
import Chip from '@mui/material/Chip';
import { Box } from '@mui/material';

interface CustomChipProps {
  label: string;
  variant: 'filled' | 'outlined' | undefined;
  onClick?: () => void;
  onDelete?: () => void;
  deleteIcon?: React.ReactElement;
}

const CustomChip: React.FC<CustomChipProps> = props => {
  const { label, variant, onClick, onDelete, deleteIcon, ...rest } = props;

  return (
    <Box>
      <Chip
        {...rest}
        label={label}
        variant={variant}
        onClick={onClick}
        onDelete={onDelete}
        deleteIcon={deleteIcon}
      />
    </Box>
  );
};

export default CustomChip;
