import CustomTextInput from '@components/text-input/TextInput';
import styled from '@emotion/styled';
import { APP } from 'src/styles/variables';
import { ReactComponent as ClearIcon } from '@images/X.svg';

export const CustomTextInputDiv = styled(CustomTextInput)`
  border: 0px solid ${APP.PALETTE.GREY.SEMI_GRAY_1};
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};

  & .MuiInputBase-input::placeholder {
    font-size: ${APP.FONT.FONT_SIZE.BODY1};
    font-family: ${APP.FONT.FONT_FAMILY.SEMIBOLD};
  }
  & .MuiOutlinedInput-input {
    height: ${({ height }: { height?: number }) => height ?? '2rem'};
  }
`;

export const CustomClearIcon = styled(ClearIcon)`
  cursor: pointer;
  /* width: 1.25rem; */

  &:focus {
    outline: none;
    border: none;
  }
`;
