import ServiceRequest from 'src/services/ServiceRequest';

import {
  getAllTaskCategoriesUrl,
  getAllAssignmentLevelsUrl,
} from './TaskCategoriesApiEndPoints';

import {
  GetAllTaskCategoriesQueryParam,
  GetAllTaskCategoriesResDto,
  GetAllAssignmentLevelsResDto,
} from './TaskCategoriesServiceMapper';

export const getAllTaskCategories = async (
  data?: GetAllTaskCategoriesQueryParam,
): Promise<GetAllTaskCategoriesResDto> => {
  return await ServiceRequest.get(getAllTaskCategoriesUrl, {
    payload: data,
  });
};

export const getAllAssignmentLevels =
  async (): Promise<GetAllAssignmentLevelsResDto> => {
    return await ServiceRequest.get(getAllAssignmentLevelsUrl);
  };
