/* eslint-disable max-len */
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  Tooltip,
} from '@mui/material';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  EditButtonStyled,
  EmailRichTextViewerWrapper,
  IframeContainerStyled,
  IframeStyled,
  KeyItem,
  KeyItemsContainer,
  KeyItemsDivStyled,
  MarkButtonStyled,
  QueueTopBarStyled,
  AutoCompletedTypography,
  TooltipStyled,
  TaskExceptionDetails,
  TaskExceptionLabel,
  TaskExceptionValue,
  Hr,
  TaskExceptionsMainContainer,
  TaskExceptionDetailsContainer,
  EmailViewerAndKeyIdentifiersContainer,
  EmailViewerContainer,
  KeyIdentifiersContainer,
  DBQueryMainContainer,
  DBQueryDetails,
  DBQueryMainDetails,
  DBQueryLabel,
  DBQueryValue,
  TaskDetailsAndActionTakenContainer,
  TaskDetailsSection,
  ActionTakenSection,
  TaskDetailContentGrid,
  TaskDetailTypographyLabel,
  TaskDetailTypographyValue,
  TaskDetailsLoadingGrid,
  TaskDetailsNoDataGrid,
  TaskDetailsNoDataMessageTypography,
  TaskDetailsDiv,
  TaskDetailsHeaderGrid,
  TaskDetailsHeaderTypography,
  TaskDetailContentWrapperGrid,
  ActionDetailsDiv,
  DropdownSectionWrapperGrid,
  DropdownInputsWrapperGrid,
  DropDownInputSectionTypography,
  DropDownInputGrid,
  DropdownLabelTypography,
  DropdownLoaderGrid,
  DropDownSubmitButtonWrapperGrid,
  SaveTextButton,
  TaskDetailsLoadingGridInQueueDetails,
  EmailDetailsNotFoundViewerWrapper,
  EmailDetailsNotFoundTypography,
  EmailDetailsNotFoundLoadingGrid,
  StatusReasonMainContainer,
  StatusReasonHeadingTypography,
  StatusReasonValue,
  StatusReasonMainContainerForHistoryTab,
  ActionTakenDetailGrid,
  ActionTakenDetailTypographyLabel,
  ActionTakenDetailTypographyValue,
  ActionTakenDetailsLoadingGrid,
  ActionTakenDetailsNoDataGrid,
  ActionTakenDetailsNoDataMessageTypography,
  ActionTakenMainDetails,
  ActionTakenDetailLabel,
  ActionTakenDetailContentGrid,
  TextArea,
  RetryButtonStyled,
  ArchiveButtonLoader,
  ArchiveButtonStyled,
  KeyIdentifiersDiv,
  KeyIdentifiersHeaderGrid,
  KeyIdentifiersHeaderTypography,
  KeyIdentifiersNoDataGrid,
  KeyIdentifiersNoDataMessageTypography,
  KeyIdentifiersContentWrapperGrid,
  ActionTakenContentWrapperGrid,
  ActionTakenHeaderGrid,
  ActionTakenHeaderTypography,
  KeyIdentifiersInfoStyledList,
  KeyIdentifiersInfoStyledListItem,
  KeyIdentifiersInfoContainer,
  StyledInfoIcon,
  KeyIdentifiersHeaderAndInfoContainer,
  KeyIdentifiersHeaderContainer,
  LoaderContainer,
  KeyIdentifiersSection,
} from './QueueDetailsStyle';
import {
  MenuItemStyled,
  MoreIconStyled,
  RowStyleDiv,
  RowStyledBackLinkDiv,
  RowStyledDiv,
  StyledChip,
  TypeCellSubTextStyle,
} from '../queue-table/QueueTableStyle';
import {
  AssignedUser,
  EmailIdentifiersParams,
} from 'src/services/service-handlers/private/queue-handlers/QueueServiceMapper';
import SearchableSelectInput from '@components/select-input/select-text-input/searchable-select-input/SearchableSelectInput';
import { APP } from 'src/styles/variables';
import { produce } from 'immer';
import {
  QueueMoreOptions,
  QueueTaskStatusTypes,
} from '../queue-constants/QueueConstants';
import FlagQueueItem from '../queue-table/flag-queue-item/FlagQueueItem';
import ChangeQueueItem from '../queue-table/change-queue-item/ChangeQueueItem';
import moreSvgIcon from '@assets/images/More.svg';
import {
  formatDate,
  isUserPortal,
  statusFormat,
} from '@modules/home/utils/Utils';
import { t } from 'i18next';
import QueueDetailsViewLayout from './queue-details-view-layout/QueueDetailsViewLayout';
import EmailRichTextViewer from '@components/email-rich-text-viewer/EmailRichTextViewer';
import contentCopyIcon from '@assets/images/copy.svg';
import { Controller, useForm } from 'react-hook-form';
import {
  getArbitByTaskId,
  getEmailByTaskId,
  getTaskDetailsById,
  updateTaskStatusById,
  updateIdentifiersByTaskId,
  getTaskExceptionsByTaskId,
  getEmailIdentifiersByTaskId,
  getActionsTakenDetailsById,
  getDBQueryDetailsByTaskId,
  retryFailedQueueAITask,
} from 'src/services/service-handlers/private/tasks-handlers/TasksPrivateService';
import {
  archiveUnclassifiedUnFlaggedQueueItemByTaskCategoryId,
  updateAssignedUser,
  updateTaskType,
} from 'src/services/service-handlers/private/queue-handlers/QueuePrivateService';
import { getErrorMessage } from '@utils/ErrorUtils';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { enqueueSnackbar } from 'notistack';
import { CircularProgressStyled } from 'src/styles/common/CommonStyles';
import {
  QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION,
  USER_TYPES,
} from '@constants/global-constants/constants';
import AuthUtils from '@utils/AuthUtils';
import { UserQueueMoreOptions } from '@modules/home/main/user/user-queue/user-queue-constants';
import {
  GetActionTakenByTaskIdEntryRowDto,
  GetDBQueryDetailsByTaskIdDataDto,
  GetEmailIdentifiersByTaskIdDataRowDto,
  GetTaskExceptionsByTaskIdDataDto,
  TaskDetailsDataDto,
} from 'src/services/service-handlers/private/tasks-handlers/TasksServiceMapper';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import {
  QueueDetailsLayoutViewerType,
  queueDetailsViewLayoutConfiguration,
} from './QueueDetailsViewLayoutConfiguration';
import {
  getAllStates,
  getAllTaskTypes,
} from 'src/services/service-handlers/private/ref-data-handlers/RefDataPrivateService';
import Loader from '@components/loaders/Loader';
import SelectInput from '@components/select-input/SelectInput';
import GroupedSelectInput from '@components/select-input/GroupedSelectInput';
import { changeTableName } from '@modules/home/state/queue/QueueSlice';
import { ProfilePicRefType } from 'src/types/Types';
import moment from 'moment';
import { capitalize } from '@utils/miscellaneousUtils';
import { StateNsaAllStateEnum } from 'src/enums/admin-enums/AdminEnums';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/Store';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';

interface UpdateTaskTypeAndStateNsaPayloadInterface {
  ids: Array<string>;
  taskTypeId: string;
  stateNsa: string;
  stateId?: string;
}

interface IEmailDetails {
  id: string;
  emailSubject: string;
  emailFromValue: string;
  emailToValue: string;
  cc?: string;
  stateNsa: string;
  emailReceivedAt: string;
  emailAttachments?: any[];
  emailBodyValue?: string;
}

interface IQueueDetailsState {
  isTaskDetailsLoading: boolean;
  taskDetails: TaskDetailsDataDto;
  showFlagQueueItem: boolean;
  showChangeQueueItem: boolean;
  isScreenEditable: boolean;
  isEmailDetailsLoading: boolean;
  emailDetails: IEmailDetails;
  isUsersListLoading: boolean;
  userList: AssignedUser[];
  isArbitDataUrlLoading: boolean;
  arbitDataUrl: string;
  isEmailIdentifiersLoading: boolean;
  isQueueAIDetails: boolean;
  isTaskExceptionDetailsLoading: boolean;
  taskExceptionDetails: GetTaskExceptionsByTaskIdDataDto;
  unclassifiedUnFlaggedDropdownValues: {
    taskCategoryDropdownOptions: any[];
    taskCategoryDropdownValue: string;
    stateOrNsaDropdownOptions: any[];
    stateOrNsaDropdownValue: string;
  };
  isCategoryAndTaskDropdownLoading: boolean;
  isStateOrNSADropdownLoading: boolean;
  isTaskAndStateUpdating: boolean;
  isActionsTakenDetailsLoading: boolean;
  actionsTakenDetails: GetActionTakenByTaskIdEntryRowDto[];
  isDBQueryDetailsLoading: boolean;
  dBQueryDetails: GetDBQueryDetailsByTaskIdDataDto;
  taskLayoutViewerConfig: QueueDetailsLayoutViewerType;
  isReAssignUserModalOpen?: boolean;
  networkErrorState?: boolean;
  isRetryInProgress?: boolean;
  isArchiveInProgress?: boolean;
  isUpdatingTaskStatus?: boolean;
}

interface UpdateTaskTypeAndStateNsaPayloadInterface {
  ids: Array<string>;
  taskTypeId: string;
  stateNsa: string;
  stateId?: string;
}

const initialQueueDetailsState: IQueueDetailsState = {
  isTaskDetailsLoading: false,
  taskDetails: {
    id: '',
    assignedUser: undefined,
    caseId: '',
    customerName: '',
    payorName: '',
    cpt: '',
    dueDate: '',
    priority: '',
    status: '',
    statusReason: '',
    taskCategory: {
      id: '',
      label: '',
    },
    taskType: {
      id: '',
      label: '',
    },
    stateNsa: {
      stateNsa: null,
      id: null,
      code: null,
      label: null,
    },
    taskLayoutType: '',
  },
  showFlagQueueItem: false,
  showChangeQueueItem: false,
  isUsersListLoading: false,
  userList: [],
  isArbitDataUrlLoading: false,
  arbitDataUrl: '',
  isEmailIdentifiersLoading: false,
  isScreenEditable: true,
  isEmailDetailsLoading: false,
  emailDetails: {
    id: '',
    emailSubject: '',
    emailFromValue: '',
    emailToValue: '',
    cc: '',
    stateNsa: '',
    emailReceivedAt: '',
    emailAttachments: [],
    emailBodyValue: '',
  },
  isTaskExceptionDetailsLoading: false,
  taskExceptionDetails: {
    exceptionType: '',
    action: '',
    logs: [],
  },
  isQueueAIDetails: false,
  unclassifiedUnFlaggedDropdownValues: {
    taskCategoryDropdownOptions: [],
    taskCategoryDropdownValue: '',
    stateOrNsaDropdownOptions: [],
    stateOrNsaDropdownValue: '',
  },
  isCategoryAndTaskDropdownLoading: true,
  isStateOrNSADropdownLoading: true,
  isTaskAndStateUpdating: false,
  isActionsTakenDetailsLoading: false,
  actionsTakenDetails: [],
  isDBQueryDetailsLoading: false,
  dBQueryDetails: {
    description: '-',
    instructions: '-',
    data: [],
  },
  taskLayoutViewerConfig: {
    top: QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.TASK_DETAILS,
    left: QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.ARBIT,
    right:
      QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.EMAIL_VIEWER_AND_KEY_IDENTIFIERS,
  },
  isReAssignUserModalOpen: false,
  networkErrorState: false,
  isRetryInProgress: false,
  isArchiveInProgress: false,
  isUpdatingTaskStatus: false,
};

const QueueDetails = (): ReactElement => {
  const { control } = useForm({});
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const userType = AuthUtils.getUserType();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [editMode, setEditMode] = useState(false);
  const open = Boolean(anchorEl);
  const [tooltipStates, setTooltipStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [isLoading, setIsLoading] = useState(false);

  const [queueDetailsState, setQueueDetailsState] =
    useState<IQueueDetailsState>(initialQueueDetailsState);

  const [identifiersData, setIdentifiersData] =
    useState<GetEmailIdentifiersByTaskIdDataRowDto[]>();

  const {
    showFlagQueueItem,
    showChangeQueueItem,
    isScreenEditable,
    emailDetails,
    userList,
    isQueueAIDetails,
    dBQueryDetails,
    isActionsTakenDetailsLoading,
    actionsTakenDetails,
    isTaskDetailsLoading,
    taskExceptionDetails,
    arbitDataUrl,
    taskDetails,
    isReAssignUserModalOpen,
    networkErrorState,
    isRetryInProgress,
    isArchiveInProgress,
    isUpdatingTaskStatus,
  } = queueDetailsState;

  const { parentQueueTabPathName } = useSelector(
    (state: RootState) => state.queue,
  );

  const adminQueueMoreItemList = [
    { id: 2, value: QueueMoreOptions.CHANGE_TYPE },
  ];

  const userQueueMoreItemList = [
    { id: 2, value: UserQueueMoreOptions.FLAG_ITEM },
  ];

  const fetchedProfilePicRef = useRef<ProfilePicRefType>({});

  const setFetchProfilePicRef = (userId: string) => {
    fetchedProfilePicRef.current[userId] = true;
  };

  // API region starts

  const fetchTaskDetails = async () => {
    setQueueDetailsState(
      produce(draft => {
        draft.isTaskDetailsLoading = true;
      }),
    );
    try {
      const queueDetailsByIdResponse = await getTaskDetailsById(params.taskId);
      setQueueDetailsState(
        produce(draft => {
          draft.taskDetails = queueDetailsByIdResponse.data;
        }),
      );
      return queueDetailsByIdResponse;
    } catch (errorResponse) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueueDetailsState(
        produce(draft => {
          draft.isTaskDetailsLoading = false;
        }),
      );
    }
  };

  const fetchArbitDataUrlByTaskId = async () => {
    setQueueDetailsState(
      produce(draft => {
        draft.isArbitDataUrlLoading = true;
      }),
    );
    try {
      const arbitData = await getArbitByTaskId(params.taskId);
      setQueueDetailsState(
        produce(draft => {
          draft.arbitDataUrl = arbitData?.data?.url ?? '';
        }),
      );
    } catch (errorResponse) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueueDetailsState(
        produce(draft => {
          draft.isArbitDataUrlLoading = false;
        }),
      );
    }
  };

  const fetchTaskExceptionDetailsByTaskId = async () => {
    setQueueDetailsState(
      produce(draft => {
        draft.isTaskExceptionDetailsLoading = true;
      }),
    );
    try {
      const taskExceptionsByTaskIdResponse = await getTaskExceptionsByTaskId(
        params.taskId,
      );
      setQueueDetailsState(
        produce(draft => {
          draft.taskExceptionDetails = taskExceptionsByTaskIdResponse.data;
        }),
      );
    } catch (errorResponse) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueueDetailsState(
        produce(draft => {
          draft.isTaskExceptionDetailsLoading = false;
        }),
      );
    }
  };

  const fetchEmailIdentifiersByTaskId = async () => {
    setQueueDetailsState(
      produce(draft => {
        draft.isEmailIdentifiersLoading = true;
      }),
    );
    try {
      const identifiers = await getEmailIdentifiersByTaskId(params.taskId);
      setIdentifiersData(identifiers?.data?.rows ?? []);
    } catch (errorResponse) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueueDetailsState(
        produce(draft => {
          draft.isEmailIdentifiersLoading = false;
        }),
      );
    }
  };

  const fetchEmailDetailsByTaskId = async () => {
    setQueueDetailsState(
      produce(draft => {
        draft.isEmailDetailsLoading = true;
      }),
    );
    try {
      const getEmailByTaskIdResponse = await getEmailByTaskId(params.taskId);
      setQueueDetailsState(
        produce(draft => {
          draft.emailDetails.id = getEmailByTaskIdResponse?.data?.id ?? '';
          draft.emailDetails.emailSubject =
            getEmailByTaskIdResponse?.data?.subject ?? '';
          draft.emailDetails.emailFromValue =
            getEmailByTaskIdResponse?.data?.fromEmail ?? '';
          draft.emailDetails.emailToValue =
            getEmailByTaskIdResponse?.data?.toEmail ?? '';
          draft.emailDetails.emailReceivedAt =
            getEmailByTaskIdResponse?.data?.receivedAt ?? '';
          draft.emailDetails.cc = getEmailByTaskIdResponse?.data?.cc ?? '';
          draft.emailDetails.stateNsa =
            getEmailByTaskIdResponse?.data?.stateNsa ?? '';
          draft.emailDetails.emailAttachments =
            getEmailByTaskIdResponse?.data?.files ?? [];
          draft.emailDetails.emailBodyValue =
            getEmailByTaskIdResponse?.data?.body ?? '';
        }),
      );
    } catch (errorResponse) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueueDetailsState(
        produce(draft => {
          draft.isEmailDetailsLoading = false;
        }),
      );
    }
  };

  const fetchActionsTakenByTaskId = async () => {
    setQueueDetailsState(
      produce(draft => {
        draft.isActionsTakenDetailsLoading = true;
      }),
    );
    try {
      const actionsTakenDetailsByIdResponse = await getActionsTakenDetailsById(
        params.taskId,
      );
      setQueueDetailsState(
        produce(draft => {
          draft.actionsTakenDetails =
            actionsTakenDetailsByIdResponse?.data?.rows;
        }),
      );
    } catch (errorResponse: any) {
      if (
        errorResponse.statusCode === 404 ||
        errorResponse.statusCode === 500
      ) {
        setQueueDetailsState(
          produce(draft => {
            draft.networkErrorState = true;
          }),
        );
      }
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueueDetailsState(
        produce(draft => {
          draft.isActionsTakenDetailsLoading = false;
        }),
      );
    }
  };

  const fetchCategoryDropdownOptions = async (
    taskDetailsRespData?: TaskDetailsDataDto,
  ) => {
    const isNonEmailBasedTasks = [
      AdminConstants.QUEUE.DB_QUERY_SHARED_SERVICES,
      AdminConstants.QUEUE.DATA_EXCEPTIONS_SHARED_SERVICES,
      AdminConstants.QUEUE.DATA_EXCEPTIONS_STATE_TEAM,
    ].includes(taskDetails.taskCategory.label);

    try {
      const response = await getAllTaskTypes({
        includeNonEmailTypes: isNonEmailBasedTasks,
      });
      const { data } = response;
      const formattedFlatArray: any[] = [];
      const rowData = [...data.rows];
      // making the nested dropdown options into flat array
      rowData.map(rowItem => {
        const obj: any = { ...rowItem };
        const taskTypesArray = obj.taskTypes;
        obj.taskTypes = null;
        formattedFlatArray.push(obj);
        if (taskTypesArray) {
          taskTypesArray.map((taskTypeItem: any) => {
            const taskTypeItemObj = { ...taskTypeItem };
            taskTypeItemObj.categoryLabel = rowItem.categoryLabel;
            formattedFlatArray.push(taskTypeItemObj);
          });
        }
      });
      // mapping flat list data into dropdown options format to handle it in select input dropdown
      const formattedData = formattedFlatArray.map(flatItem => {
        const finalObj: any = {
          optionId: '',
          optionText: '',
          listHeader: '',
        };
        if (flatItem.categoryId) {
          finalObj.optionId = flatItem.categoryId;
          finalObj.optionText = null;
          finalObj.listHeader = flatItem.categoryLabel;
          return finalObj;
        } else {
          finalObj.optionId = flatItem.taskId;
          finalObj.optionText = flatItem.taskLabel;
          finalObj.listHeader = flatItem.categoryLabel;
          return finalObj;
        }
      });
      // To display pre-selected category
      if (
        formattedData &&
        formattedData.length &&
        taskDetailsRespData?.taskType
      ) {
        formattedData.map(categoryOptionItem => {
          if (
            categoryOptionItem.optionId === taskDetailsRespData?.taskType.id
          ) {
            if (categoryOptionItem.optionText) {
              setQueueDetailsState(
                produce(draft => {
                  draft.unclassifiedUnFlaggedDropdownValues.taskCategoryDropdownValue =
                    categoryOptionItem.optionText;
                }),
              );
            }
          }
        });
      }
      setQueueDetailsState(
        produce(draft => {
          draft.unclassifiedUnFlaggedDropdownValues.taskCategoryDropdownOptions =
            formattedData;
        }),
      );
    } catch (e) {
      setQueueDetailsState(
        produce(draft => {
          draft.isCategoryAndTaskDropdownLoading = false;
        }),
      );
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueueDetailsState(
        produce(draft => {
          draft.isCategoryAndTaskDropdownLoading = false;
        }),
      );
    }
  };

  const fetchStateDropdownOptions = async (
    taskDetailsRespData?: TaskDetailsDataDto,
  ) => {
    try {
      const response = await getAllStates();
      const { data } = response;
      const RowData = [...data.rows];
      const formattedStateOrNSAOptions: any = [];
      // adding NSA dropdown option
      formattedStateOrNSAOptions.push({
        optionId: AdminConstants.USERS.STATENSA,
        optionText: AdminConstants.USERS.NSA,
      });
      // mapping list data into dropdown options format to handle it in select input dropdown
      RowData.map(rowItem => {
        const obj: any = {};
        obj.optionId = rowItem.id;
        obj.optionText = rowItem.label;
        formattedStateOrNSAOptions.push(obj);
      });

      // to display pre-selected option
      if (formattedStateOrNSAOptions && formattedStateOrNSAOptions.length) {
        formattedStateOrNSAOptions.map((stateOrNSAOptionItem: any) => {
          if (
            stateOrNSAOptionItem.optionId === taskDetailsRespData?.stateNsa?.id
          ) {
            if (stateOrNSAOptionItem.optionText) {
              setQueueDetailsState(
                produce(draft => {
                  draft.unclassifiedUnFlaggedDropdownValues.stateOrNsaDropdownValue =
                    stateOrNSAOptionItem.optionText;
                }),
              );
            }
          } else if (
            stateOrNSAOptionItem.optionText.toLowerCase() ===
            taskDetailsRespData?.stateNsa?.stateNsa?.toLowerCase()
          ) {
            setQueueDetailsState(
              produce(draft => {
                draft.unclassifiedUnFlaggedDropdownValues.stateOrNsaDropdownValue =
                  stateOrNSAOptionItem.optionText;
              }),
            );
          }
        });
      }

      setQueueDetailsState(
        produce(draft => {
          draft.unclassifiedUnFlaggedDropdownValues.stateOrNsaDropdownOptions =
            formattedStateOrNSAOptions;
        }),
      );
    } catch (e) {
      setQueueDetailsState(
        produce(draft => {
          draft.isStateOrNSADropdownLoading = false;
        }),
      );
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueueDetailsState(
        produce(draft => {
          draft.isStateOrNSADropdownLoading = false;
        }),
      );
    }
  };

  const fetchDBQueryDetails = async () => {
    setQueueDetailsState(
      produce(draft => {
        draft.isDBQueryDetailsLoading = true;
      }),
    );

    try {
      const getDBQueriesDetails = await getDBQueryDetailsByTaskId(
        params.taskId,
      );
      setQueueDetailsState(
        produce(draft => {
          draft.dBQueryDetails = getDBQueriesDetails?.data;
        }),
      );
    } catch (errorResponse) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueueDetailsState(
        produce(draft => {
          draft.isDBQueryDetailsLoading = false;
        }),
      );
    }
  };

  // API region ends

  const executeSecondaryAPICallsBasedOnTaskLayoutType = async (
    taskDetailsRespData?: TaskDetailsDataDto,
  ) => {
    const taskLayoutType = taskDetailsRespData?.taskLayoutType ?? '';

    const status = taskDetailsRespData?.status ?? '';

    const statusReason = taskDetailsRespData?.statusReason ?? '';

    let taskLayoutViewerConfig =
      queueDetailsViewLayoutConfiguration[
        QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.DEFAULT
      ];
    if (statusReason === t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.STATUS_ARCHIVED')) {
      taskLayoutViewerConfig =
        queueDetailsViewLayoutConfiguration[
          QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.STATUS_ARCHIVED
        ];
    } else {
      if (taskLayoutType !== QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.DEFAULT) {
        if (
          taskLayoutType ===
          QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.UNCLASSIFIED
        ) {
          if (status !== QueueTaskStatusTypes.FLAGGED) {
            taskLayoutViewerConfig =
              queueDetailsViewLayoutConfiguration[
                QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.UNCLASSIFIED
              ];
          }
        } else {
          taskLayoutViewerConfig =
            queueDetailsViewLayoutConfiguration[taskLayoutType];
        }
      }
    }

    setQueueDetailsState(
      produce(draft => {
        draft.taskLayoutViewerConfig = taskLayoutViewerConfig;
      }),
    );

    // top section starts

    if (
      taskLayoutViewerConfig?.top ===
      QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.TASK_DETAILS
    ) {
      // await fetchUsersList();
    }

    // top section ends

    // left section starts

    if (
      taskLayoutViewerConfig?.left ===
      QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.ARBIT
    ) {
      await fetchArbitDataUrlByTaskId();
    } else if (
      taskLayoutViewerConfig?.left ===
      QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.EMAIL_VIEWER
    ) {
      await fetchEmailDetailsByTaskId();
    }

    // left section ends

    // right section starts

    if (
      taskLayoutViewerConfig?.right ===
      QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.EMAIL_VIEWER_AND_KEY_IDENTIFIERS
    ) {
      // check if email details api has already been called
      if (queueDetailsState?.emailDetails?.id?.length < 2) {
        await fetchEmailDetailsByTaskId();
      }

      await fetchEmailIdentifiersByTaskId();
    } else if (
      taskLayoutViewerConfig?.right ===
      QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.TASK_DETAILS_AND_ACTION_TAKEN_AND_KEY_IDENTIFIERS
    ) {
      await fetchActionsTakenByTaskId();
      await fetchEmailIdentifiersByTaskId();
    } else if (
      taskLayoutViewerConfig?.right ===
      QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.TASK_TYPE_AND_STATE_NSA_DROPDOWN
    ) {
      await fetchCategoryDropdownOptions(taskDetailsRespData);
      await fetchStateDropdownOptions(taskDetailsRespData);
    } else if (
      taskLayoutViewerConfig?.right ===
      QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.EXCEPTION_TYPE_ACTION_TAKEN_AND_ERROR_LOGS
    ) {
      await fetchTaskExceptionDetailsByTaskId();
    } else if (
      taskLayoutViewerConfig?.right ===
      QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.DB_CONNECTOR_NAME_DESCRIPTION_AND_DATA
    ) {
      await fetchDBQueryDetails();
    }

    // right section ends
  };

  const init = async () => {
    setIsLoading(true);
    try {
      const queueDetailsByIdResponse = await fetchTaskDetails();
      await executeSecondaryAPICallsBasedOnTaskLayoutType(
        queueDetailsByIdResponse?.data,
      );
    } catch (e) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setIsLoading(false);
    }
  };

  const handleAssignedUserChange = async (
    data: { id: string },
    rowId: string,
  ) => {
    try {
      const payload = {
        userId: data?.id,
        ids: [rowId],
      };
      const res = await updateAssignedUser(payload);
      await fetchTaskDetails();
      enqueueSnackbar(res.data.message, SnackBarConfig.getSuccess());
    } catch (e) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    }
  };
  useEffect(() => {
    if (location?.state) {
      const {
        isScreenEditable: isScreenEditableValue,
        isQueueAIDetails: isQueueAIDetailsValue,
      } = location.state;
      if (isScreenEditableValue !== undefined) {
        setQueueDetailsState(
          produce(draft => {
            draft.isScreenEditable = location.state.isScreenEditable;
          }),
        );
      }

      if (isQueueAIDetailsValue !== undefined) {
        setQueueDetailsState(
          produce(draft => {
            draft.isQueueAIDetails = location.state.isQueueAIDetails;
          }),
        );
      }
    }

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTypeValue = (taskCategory: string, taskType: string) => {
    return (
      <div>
        <RowStyleDiv>{taskType}</RowStyleDiv>
        <TypeCellSubTextStyle>{`(${taskCategory})`}</TypeCellSubTextStyle>
      </div>
    );
  };

  const handlePopOverOpenClose = (isModalOpen = false) => {
    setQueueDetailsState(
      produce(draft => {
        draft.isReAssignUserModalOpen = isModalOpen;
      }),
    );
  };

  const renderSearchableSelectInput = (value: AssignedUser | undefined) => {
    return (
      <div>
        <SearchableSelectInput
          options={userList}
          defaultOption={value}
          disabled={isScreenEditable === false || isUserPortal() ? true : false}
          handleOnChange={(data: any) =>
            handleAssignedUserChange(data, params.taskId || '')
          }
          shouldLoadDefaultProfilePic={true}
          shouldFetchUsersOptionsListByTaskId={true}
          taskId={taskDetails?.id ?? ''}
          handlePopOverOpenClose={(isModalOpen?: boolean) => {
            handlePopOverOpenClose(isModalOpen);
          }}
          openDropdown={isReAssignUserModalOpen}
          fetchedProfilePicRef={fetchedProfilePicRef}
          setFetchProfilePicRef={setFetchProfilePicRef}
        />
      </div>
    );
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setQueueDetailsState(
      produce(draft => {
        draft.showFlagQueueItem = false;
        draft.showChangeQueueItem = false;
      }),
    );
  };

  const handleSave = () => {
    init();
  };

  const handleChangeQueueItem = () => {
    setAnchorEl(null);
    setQueueDetailsState(
      produce(draft => {
        draft.showChangeQueueItem = true;
      }),
    );
  };

  const handleFlagQueueItem = () => {
    setAnchorEl(null);
    setQueueDetailsState(
      produce(draft => {
        draft.showFlagQueueItem = true;
      }),
    );
  };

  const handleUpdateTaskStatusById = async () => {
    setQueueDetailsState(
      produce(draft => {
        draft.isUpdatingTaskStatus = true;
      }),
    );
    try {
      const payload = {
        status:
          taskDetails?.status === QueueTaskStatusTypes.IN_PROGRESS
            ? QueueTaskStatusTypes.COMPLETED
            : taskDetails?.status === QueueTaskStatusTypes.ASSIGNED ||
              taskDetails?.status === QueueTaskStatusTypes.FLAGGED ||
              taskDetails?.status === QueueTaskStatusTypes.UNASSIGNED
            ? QueueTaskStatusTypes.IN_PROGRESS
            : '',
      };
      const res = await updateTaskStatusById(taskDetails?.id, payload);
      if (taskDetails?.status === QueueTaskStatusTypes.IN_PROGRESS) {
        if (parentQueueTabPathName.length > 0) {
          navigate(parentQueueTabPathName);
        } else {
          navigate(
            PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
              .QueueTable.FullPath,
          );
        }
        changeTableName();
      }
      if (res) {
        enqueueSnackbar(res.data.message, SnackBarConfig.getSuccess());
        init();
      }
    } catch (e) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueueDetailsState(
        produce(draft => {
          draft.isUpdatingTaskStatus = false;
        }),
      );
    }
  };

  const renderFlagQueueItem = () => {
    return (
      <FlagQueueItem
        isDialogOpen={showFlagQueueItem}
        handleCancel={handleCloseMenu}
        taskId={params.taskId || ''}
        handleSave={handleSave}
      />
    );
  };

  const renderChangeQueueItem = () => {
    return (
      <ChangeQueueItem
        isDialogOpen={showChangeQueueItem}
        handleCancel={handleCloseMenu}
        taskId={params.taskId || ''}
        refreshScreen={handleSave}
        taskCategory={taskDetails.taskCategory.label}
        taskType={taskDetails.taskType.label}
        stateId={taskDetails?.stateNsa?.id ?? ''}
        stateNsa={taskDetails?.stateNsa?.stateNsa ?? ''}
      />
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBack = () => {
    if (parentQueueTabPathName.length > 0) {
      navigate(parentQueueTabPathName);
    } else {
      navigate(
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes.QueueTable
          .FullPath,
      );
    }
    setQueueDetailsState(
      produce(draft => {
        draft.isScreenEditable = false;
      }),
    );
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  const renderMoreMenuSection = () => {
    const moreItemList =
      userType == USER_TYPES.USER
        ? userQueueMoreItemList
        : adminQueueMoreItemList;
    return (
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreIconStyled src={moreSvgIcon} alt="more-icon" />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          slotProps={{
            paper: {
              style: {
                backgroundColor: APP.PALETTE.COMMON.BLACK,
                width: '12.5rem',
                flexShrink: 0,
              },
            },
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
        >
          {moreItemList.map((item, index) => {
            return (
              <MenuItemStyled
                key={item.id}
                sx={
                  index === moreItemList.length - 1
                    ? { borderBottom: 'none !important' }
                    : {}
                }
                onClick={() =>
                  item.value === UserQueueMoreOptions.FLAG_ITEM
                    ? handleFlagQueueItem()
                    : handleChangeQueueItem()
                }
              >
                {item.value}
              </MenuItemStyled>
            );
          })}
        </Menu>
      </div>
    );
  };

  const handleRetryClick = async () => {
    setQueueDetailsState(
      produce(draft => {
        draft.isRetryInProgress = true;
      }),
    );
    try {
      const response = await retryFailedQueueAITask(taskDetails.id);
      enqueueSnackbar(response.data.message, SnackBarConfig.getSuccess());
    } catch (e: any) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueueDetailsState(
        produce(draft => {
          draft.isRetryInProgress = false;
        }),
      );
      await init();
    }
  };

  const handleArchiveUnclassifiedAndUnFlaggedTaskById = async () => {
    setQueueDetailsState(
      produce(draft => {
        draft.isArchiveInProgress = true;
      }),
    );
    try {
      const response =
        await archiveUnclassifiedUnFlaggedQueueItemByTaskCategoryId(
          taskDetails.id,
        );
      enqueueSnackbar(response.data.message, SnackBarConfig.getSuccess());
      navigate(-1);
      changeTableName();
    } catch (e: any) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueueDetailsState(
        produce(draft => {
          draft.isArchiveInProgress = false;
        }),
      );
    }
  };

  const renderBackButtonOnlyInTopBar = () => {
    return (
      <QueueTopBarStyled>
        <RowStyledBackLinkDiv onClick={() => handleBack()}>
          {t('ADMIN.COMMON.BACK')}
        </RowStyledBackLinkDiv>
      </QueueTopBarStyled>
    );
  };

  const renderBackButtonAndRetryButtonForAIFailedTasksInTopBar = () => {
    return (
      <QueueTopBarStyled>
        <RowStyledBackLinkDiv onClick={() => handleBack()}>
          {t('ADMIN.COMMON.BACK')}
        </RowStyledBackLinkDiv>
        {taskDetails.taskLayoutType ===
          QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.AI &&
          taskDetails.status === QueueTaskStatusTypes.FAILED &&
          (isRetryInProgress ? (
            <RetryButtonStyled disabled={isRetryInProgress}>
              <Loader size={30} />
            </RetryButtonStyled>
          ) : (
            <RetryButtonStyled onClick={() => handleRetryClick()}>
              <span>{t('PRIVATE.QUEUE_AI.RETRY')}</span>
            </RetryButtonStyled>
          ))}
      </QueueTopBarStyled>
    );
  };

  const renderBackButtonAndArchiveButtonForUnClassifiedAndUnFlaggedTasksInTopBar =
    () => {
      return (
        <QueueTopBarStyled>
          <RowStyledBackLinkDiv onClick={() => handleBack()}>
            {t('ADMIN.COMMON.BACK')}
          </RowStyledBackLinkDiv>
          {taskDetails.taskLayoutType ===
            QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.UNCLASSIFIED &&
            taskDetails.status === QueueTaskStatusTypes.UNCLASSIFIED &&
            (isArchiveInProgress ? (
              <ArchiveButtonLoader>
                <Loader size={40} />
              </ArchiveButtonLoader>
            ) : (
              <ArchiveButtonStyled
                disabled={queueDetailsState.isTaskAndStateUpdating}
                onClick={() => handleArchiveUnclassifiedAndUnFlaggedTaskById()}
              >
                <span>{QueueMoreOptions.ARCHIVE}</span>
              </ArchiveButtonStyled>
            ))}
        </QueueTopBarStyled>
      );
    };

  const renderTopBarSection = () => {
    const stateOrNsa = taskDetails?.stateNsa?.stateNsa
      ? taskDetails?.stateNsa?.stateNsa?.toLowerCase() ===
        StateNsaAllStateEnum.STATE
        ? taskDetails.stateNsa.label
        : taskDetails.stateNsa?.stateNsa?.toUpperCase()
      : '';

    return isTaskDetailsLoading ? (
      <TaskDetailsLoadingGridInQueueDetails>
        <Loader size={30} />
      </TaskDetailsLoadingGridInQueueDetails>
    ) : (
      <QueueTopBarStyled>
        <RowStyledBackLinkDiv onClick={() => handleBack()}>
          {t('ADMIN.COMMON.BACK')}
        </RowStyledBackLinkDiv>
        <RowStyledDiv>
          {taskDetails?.caseId ? `#${taskDetails?.caseId}` : '-'}
        </RowStyledDiv>
        <RowStyledDiv>
          {renderTypeValue(
            taskDetails?.taskCategory?.label ?? '-',
            taskDetails?.taskType?.label ?? '-',
          )}
        </RowStyledDiv>
        <RowStyledDiv>{taskDetails?.customerName ?? '-'}</RowStyledDiv>
        <RowStyledDiv>{stateOrNsa ?? '-'}</RowStyledDiv>
        <RowStyledDiv>{taskDetails?.payorName}</RowStyledDiv>
        <StyledChip label={taskDetails?.priority ?? '-'} variant="filled" />
        <RowStyledDiv>
          {taskDetails?.dueDate ? formatDate(taskDetails?.dueDate) : '-'}
        </RowStyledDiv>
        <RowStyledDiv>
          {taskDetails?.status ? statusFormat(taskDetails?.status) : '-'}
        </RowStyledDiv>
        {!isQueueAIDetails &&
          renderSearchableSelectInput(taskDetails?.assignedUser)}
        {isScreenEditable ? (
          <RowStyledDiv style={{ flexDirection: 'row', display: 'flex' }}>
            {taskDetails?.status !== QueueTaskStatusTypes.FLAGGED ? (
              <MarkButtonStyled
                variant="outlined"
                onClick={handleUpdateTaskStatusById}
              >
                {taskDetails?.status === QueueTaskStatusTypes.ASSIGNED ||
                taskDetails?.status === QueueTaskStatusTypes.UNASSIGNED
                  ? QueueMoreOptions.START_TASK
                  : t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.MARK_COMPLETE')}
              </MarkButtonStyled>
            ) : null}
            {renderMoreMenuSection()}
          </RowStyledDiv>
        ) : null}
        {isQueueAIDetails && (
          <AutoCompletedTypography>
            {t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.AUTO_COMPLETED')}
          </AutoCompletedTypography>
        )}
      </QueueTopBarStyled>
    );
  };

  const renderIframe = () => {
    return (
      <IframeContainerStyled>
        <IframeStyled src={arbitDataUrl} title="Iframe" allowFullScreen />
      </IframeContainerStyled>
    );
  };

  const renderEmailViewer = (isEmailViewerOnLeft = false) => {
    if (
      !queueDetailsState.isEmailDetailsLoading &&
      queueDetailsState.emailDetails.emailSubject
    ) {
      return (
        <EmailRichTextViewerWrapper isEmailViewerOnLeft={isEmailViewerOnLeft}>
          <EmailRichTextViewer
            fromEmail={emailDetails.emailFromValue}
            toEmail={emailDetails.emailToValue}
            cc={emailDetails.cc ?? ''}
            files={emailDetails.emailAttachments ?? []}
            subject={emailDetails.emailSubject}
            body={emailDetails.emailBodyValue ?? ''}
            receivedAt={emailDetails.emailReceivedAt}
            SubjectFontSize={1}
            FromEmailFontSize={0.875}
            ToEmailFontSize={0.875}
            padding="1.2rem"
          />
        </EmailRichTextViewerWrapper>
      );
    } else if (queueDetailsState.isEmailDetailsLoading) {
      return (
        <EmailDetailsNotFoundLoadingGrid>
          <Loader />
        </EmailDetailsNotFoundLoadingGrid>
      );
    } else {
      return (
        <EmailDetailsNotFoundViewerWrapper>
          <EmailDetailsNotFoundTypography>
            {t('PRIVATE.EMAILS.EMAIL_DETAILS.NO_EMAIL_DETAILS')}
          </EmailDetailsNotFoundTypography>
        </EmailDetailsNotFoundViewerWrapper>
      );
    }
  };

  const handleCopy = (value: string, itemId: number) => {
    navigator.clipboard.writeText(value);
    setTooltipStates(prevState => ({
      ...prevState,
      [itemId]: true,
    }));
    setTimeout(() => {
      setTooltipStates(prevState => ({
        ...prevState,
        [itemId]: false,
      }));
    }, 1000);
  };

  const handleSaveEdit = async () => {
    try {
      const payload = {
        data: identifiersData as EmailIdentifiersParams[],
      };
      const res = await updateIdentifiersByTaskId(params.taskId, payload);
      if (res) {
        const message = res.data.message;
        enqueueSnackbar(message, SnackBarConfig.getSuccess());
      }
    } catch (e) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    }
    setEditMode(false);
  };

  const handleChange = (targetValue: string, item: any) => {
    const { id } = item;
    setIdentifiersData(
      (
        prevIdentifiersData:
          | GetEmailIdentifiersByTaskIdDataRowDto[]
          | undefined,
      ) => {
        if (!prevIdentifiersData) {
          return prevIdentifiersData;
        }
        const itemIndex = prevIdentifiersData.findIndex(
          (i: { id: any }) => i.id === id,
        );
        if (itemIndex !== -1) {
          const updatedRowData = [
            ...prevIdentifiersData.slice(0, itemIndex),
            { ...prevIdentifiersData[itemIndex], value: targetValue },
            ...prevIdentifiersData.slice(itemIndex + 1),
          ];
          return updatedRowData;
        }
        return prevIdentifiersData;
      },
    );
  };

  const renderKeyIdentifiersInfo = () => (
    <KeyIdentifiersInfoStyledList>
      <KeyIdentifiersInfoStyledListItem>
        {t('PRIVATE.QUEUE_AI.KEY_IDENTIFIERS_INFO.DATE_INFO')}
      </KeyIdentifiersInfoStyledListItem>
      <KeyIdentifiersInfoStyledListItem>
        {t('PRIVATE.QUEUE_AI.KEY_IDENTIFIERS_INFO.CURRENCY_OR_AMOUNT_INFO')}
      </KeyIdentifiersInfoStyledListItem>
    </KeyIdentifiersInfoStyledList>
  );

  const renderKeyItems = () => {
    const isQueueAIDetailsTab =
      taskDetails.taskLayoutType === QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.AI;
    const isQueueAITaskFailed =
      taskDetails.status === QueueTaskStatusTypes.FAILED;
    return (
      <KeyItemsContainer isQueueAiDetailsTab={isQueueAIDetailsTab}>
        {identifiersData?.length && identifiersData?.length > 0 ? (
          <KeyItemsDivStyled>
            <KeyIdentifiersHeaderAndInfoContainer>
              <KeyIdentifiersHeaderContainer>
                {t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.KEY_ITEMS')}
              </KeyIdentifiersHeaderContainer>
              {!isQueueAIDetailsTab ||
              (isQueueAIDetailsTab && isQueueAITaskFailed) ? (
                <KeyIdentifiersInfoContainer>
                  <Tooltip title={renderKeyIdentifiersInfo()} placement="top">
                    <StyledInfoIcon />
                  </Tooltip>
                </KeyIdentifiersInfoContainer>
              ) : null}
            </KeyIdentifiersHeaderAndInfoContainer>
            {isScreenEditable ? (
              editMode ? (
                <div>
                  <EditButtonStyled onClick={handleCancelEdit}>
                    {t('ADMIN.COMMON.CANCEL')}
                  </EditButtonStyled>
                  <EditButtonStyled onClick={handleSaveEdit}>
                    {t('ADMIN.COMMON.SAVE')}
                  </EditButtonStyled>
                </div>
              ) : (
                <EditButtonStyled
                  disabled={isQueueAIDetailsTab && !isQueueAITaskFailed}
                  variant="text"
                  onClick={handleEdit}
                >
                  {t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.EDIT')}
                </EditButtonStyled>
              )
            ) : null}
          </KeyItemsDivStyled>
        ) : null}
        {identifiersData?.length && identifiersData?.length > 0 ? (
          identifiersData.map((item: any) => (
            <KeyItem key={item.id} isEditMode={editMode}>
              <span className="key">{item.key}</span>

              {isScreenEditable
                ? !editMode && (
                    <img
                      src={contentCopyIcon}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleCopy(item.value, item.id)}
                      onKeyDown={event => {
                        if (event.key === 'Enter') {
                          handleCopy(item.value, item.id);
                        }
                      }}
                      alt="copy"
                    />
                  )
                : ''}
              {editMode ? (
                <Controller
                  name={'keyItemValue'}
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      rows={1}
                      placeholder=""
                      defaultValue={item.value}
                      value={field.value}
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >,
                      ) => handleChange(e.target.value, item)}
                    />
                  )}
                />
              ) : (
                <span className="value">{item.value}</span>
              )}

              <TooltipStyled
                style={{
                  visibility: tooltipStates[item.id] ? 'visible' : 'hidden',
                }}
              >
                {t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.COPIED')}
              </TooltipStyled>
            </KeyItem>
          ))
        ) : isQueueAIDetailsTab ? (
          <KeyIdentifiersNoDataGrid>
            <KeyIdentifiersNoDataMessageTypography>
              {t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.NO_KEY_IDENTIFIERS')}
            </KeyIdentifiersNoDataMessageTypography>
          </KeyIdentifiersNoDataGrid>
        ) : (
          <div>{t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.NO_KEY_IDENTIFIERS')}</div>
        )}
      </KeyItemsContainer>
    );
  };

  const handleTaskTypeAndStateNsaDropdownChange = (
    data: string | HTMLInputElement | undefined,
  ) => {
    let isStateOrNSADropdownSelected;
    const foundObject =
      queueDetailsState.unclassifiedUnFlaggedDropdownValues.taskCategoryDropdownOptions.find(
        obj => obj.optionText === data,
      );
    if (foundObject) {
      isStateOrNSADropdownSelected = false;
    } else {
      isStateOrNSADropdownSelected = true;
    }
    if (isStateOrNSADropdownSelected) {
      setQueueDetailsState(
        produce(draft => {
          draft.unclassifiedUnFlaggedDropdownValues.stateOrNsaDropdownValue =
            data as string;
        }),
      );
    } else {
      setQueueDetailsState(
        produce(draft => {
          draft.unclassifiedUnFlaggedDropdownValues.taskCategoryDropdownValue =
            data as string;
        }),
      );
    }
  };

  const handleUpdateTaskTypeAndStateNsa = async () => {
    setQueueDetailsState(
      produce(draft => {
        draft.isTaskAndStateUpdating = true;
      }),
    );
    const payload: UpdateTaskTypeAndStateNsaPayloadInterface = {
      ids: [params.taskId as string],
      taskTypeId: '',
      stateNsa: '',
      stateId: '',
    };
    queueDetailsState.unclassifiedUnFlaggedDropdownValues.stateOrNsaDropdownOptions.map(
      (stateOrNSAOptionItem: any) => {
        if (
          stateOrNSAOptionItem.optionText ===
          queueDetailsState.unclassifiedUnFlaggedDropdownValues
            .stateOrNsaDropdownValue
        ) {
          if (
            queueDetailsState.unclassifiedUnFlaggedDropdownValues
              .stateOrNsaDropdownValue === AdminConstants.USERS.NSA
          ) {
            payload.stateNsa = AdminConstants.USERS.NSA.toLowerCase();
            delete payload.stateId;
          } else {
            payload.stateNsa = AdminConstants.USERS.STATE.toLowerCase();
            payload.stateId = stateOrNSAOptionItem.optionId;
          }
        }
      },
    );
    queueDetailsState.unclassifiedUnFlaggedDropdownValues.taskCategoryDropdownOptions.map(
      categoryOptionItem => {
        if (
          categoryOptionItem.optionText ===
          queueDetailsState.unclassifiedUnFlaggedDropdownValues
            .taskCategoryDropdownValue
        ) {
          payload.taskTypeId = categoryOptionItem.optionId;
        }
      },
    );
    try {
      const updateTaskTypeResponse = await updateTaskType(payload);
      const successMessage = updateTaskTypeResponse.data.message;
      enqueueSnackbar(successMessage, SnackBarConfig.getSuccess());
      await init();
    } catch (e) {
      setQueueDetailsState(
        produce(draft => {
          draft.isTaskAndStateUpdating = false;
        }),
      );
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueueDetailsState(
        produce(draft => {
          draft.isTaskAndStateUpdating = false;
        }),
      );
    }
  };

  const renderStatusReason = (isHistoryTab?: boolean) => {
    const isStatusReasonAvailable =
      taskDetails.statusReason && taskDetails.statusReason.length > 2
        ? taskDetails.statusReason
        : '-';

    const IsHistoryTabQueueDetails = isHistoryTab
      ? StatusReasonMainContainerForHistoryTab
      : StatusReasonMainContainer;

    return (
      <IsHistoryTabQueueDetails>
        <StatusReasonHeadingTypography>
          {t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.STATUS_REASON')}
        </StatusReasonHeadingTypography>
        <StatusReasonValue>{isStatusReasonAvailable}</StatusReasonValue>
      </IsHistoryTabQueueDetails>
    );
  };

  const renderUnclassifiedUnFlaggedDetailsUI = () => {
    return (
      <DropdownInputsWrapperGrid>
        <DropDownInputSectionTypography>
          {t(
            'ADMIN.QUEUE_TABS.QUEUE_DETAILS.UNCLASSIFIED_UNFLAGGED_DROPDOWN_HEADING',
          )}
        </DropDownInputSectionTypography>
        <DropDownInputGrid>
          <DropdownLabelTypography>
            {t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.CLASSIFICATION_NAME')}
          </DropdownLabelTypography>
          {queueDetailsState.isCategoryAndTaskDropdownLoading ? (
            <DropdownLoaderGrid>
              <Loader size={25} />
            </DropdownLoaderGrid>
          ) : (
            <GroupedSelectInput
              groupedFilterOptions={
                queueDetailsState.unclassifiedUnFlaggedDropdownValues
                  .taskCategoryDropdownOptions
              }
              isgroupingstylingneeded={'yes'}
              preSelectedValue={
                queueDetailsState.unclassifiedUnFlaggedDropdownValues
                  .taskCategoryDropdownValue ?? ''
              }
              // handleOptionSelect={() => {}}
              handleOptionSelect={data =>
                handleTaskTypeAndStateNsaDropdownChange(data)
              }
              backgroundColor={APP.PALETTE.BACKGROUND.PAPER}
              borderRadius={'0'}
              width={'100%'}
            />
          )}
        </DropDownInputGrid>
        <DropDownInputGrid>
          <DropdownLabelTypography>
            {t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.STATE_OR_NSA')}
          </DropdownLabelTypography>
          {queueDetailsState.isCategoryAndTaskDropdownLoading ? (
            <DropdownLoaderGrid>
              <Loader size={25} />
            </DropdownLoaderGrid>
          ) : (
            <SelectInput
              dropdownOptions={
                queueDetailsState.unclassifiedUnFlaggedDropdownValues
                  .stateOrNsaDropdownOptions
              }
              showSelectedOption={true}
              preSelectedValue={
                queueDetailsState.unclassifiedUnFlaggedDropdownValues
                  .stateOrNsaDropdownValue ?? ''
              }
              isgroupingstylingneeded={'yes'}
              backgroundColor={APP.PALETTE.BACKGROUND.PAPER}
              isDefaultValuePresent={true}
              borderRadius={'0'}
              width={'100%'}
              handleChange={data =>
                handleTaskTypeAndStateNsaDropdownChange(data)
              }
            />
          )}
        </DropDownInputGrid>
        <DropDownSubmitButtonWrapperGrid>
          <SaveTextButton
            disabled={
              !queueDetailsState.unclassifiedUnFlaggedDropdownValues
                .stateOrNsaDropdownValue ||
              !queueDetailsState.unclassifiedUnFlaggedDropdownValues
                .taskCategoryDropdownValue ||
              queueDetailsState.isTaskAndStateUpdating
                ? true
                : false
            }
            onClick={handleUpdateTaskTypeAndStateNsa}
          >
            {queueDetailsState.isTaskAndStateUpdating ? (
              <Loader size={28} />
            ) : (
              t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.UPDATE_TASK')
            )}
          </SaveTextButton>
        </DropDownSubmitButtonWrapperGrid>
      </DropdownInputsWrapperGrid>
    );
  };

  const renderTaskExceptions = () => {
    return (
      <TaskExceptionsMainContainer>
        {/* Exception Type */}
        <TaskExceptionDetails>
          <TaskExceptionLabel>
            {t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.TASK_EXCEPTIONS.EXCEPTION_TYPE')}
          </TaskExceptionLabel>
          <TaskExceptionValue>
            {taskExceptionDetails?.exceptionType ?? ''}
          </TaskExceptionValue>
        </TaskExceptionDetails>
        {/* Action */}
        <TaskExceptionDetails>
          <TaskExceptionLabel>
            {t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.TASK_EXCEPTIONS.ACTION')}
          </TaskExceptionLabel>
          <TaskExceptionValue>
            {taskExceptionDetails?.action ?? ''}
          </TaskExceptionValue>
        </TaskExceptionDetails>
        <Hr />
        {taskExceptionDetails?.logs
          ? taskExceptionDetails.logs.map((taskException, index: number) => {
              return (
                <div key={taskException?.errorLogId ?? index}>
                  <TaskExceptionDetailsContainer>
                    {/* Error log id */}
                    <TaskExceptionDetails>
                      <TaskExceptionLabel>
                        {t(
                          'ADMIN.QUEUE_TABS.QUEUE_DETAILS.TASK_EXCEPTIONS.ERROR_LOG_ID',
                        )}
                      </TaskExceptionLabel>
                      <TaskExceptionValue>
                        {taskException.errorLogId}
                      </TaskExceptionValue>
                    </TaskExceptionDetails>
                    {/* Created on */}
                    <TaskExceptionDetails>
                      <TaskExceptionLabel>
                        {t(
                          'ADMIN.QUEUE_TABS.QUEUE_DETAILS.TASK_EXCEPTIONS.CREATED_ON',
                        )}
                      </TaskExceptionLabel>
                      <TaskExceptionValue>
                        {formatDate(
                          taskException?.createdOn ?? new Date().toString(),
                        )}
                      </TaskExceptionValue>
                    </TaskExceptionDetails>
                    {/* Error Data */}
                    <TaskExceptionDetails>
                      <TaskExceptionLabel>
                        {t(
                          'ADMIN.QUEUE_TABS.QUEUE_DETAILS.TASK_EXCEPTIONS.ERROR_DATA',
                        )}
                      </TaskExceptionLabel>
                      <TaskExceptionValue>
                        {taskException?.errorData ?? ''}
                      </TaskExceptionValue>
                    </TaskExceptionDetails>
                    {/* System Message */}
                    <TaskExceptionDetails>
                      <TaskExceptionLabel>
                        {t(
                          'ADMIN.QUEUE_TABS.QUEUE_DETAILS.TASK_EXCEPTIONS.SYSTEM_MESSAGE',
                        )}
                      </TaskExceptionLabel>
                      <TaskExceptionValue>
                        {taskException?.systemMessage ?? ''}
                      </TaskExceptionValue>
                    </TaskExceptionDetails>
                  </TaskExceptionDetailsContainer>
                  <Hr />
                </div>
              );
            })
          : t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.TASK_EXCEPTIONS.NO_LOGS_FOUND')}
      </TaskExceptionsMainContainer>
    );
  };

  const renderEmailViewerAndKeyIdentifiers = () => {
    return (
      <EmailViewerAndKeyIdentifiersContainer>
        <EmailViewerContainer>{renderEmailViewer()}</EmailViewerContainer>
        <KeyIdentifiersContainer>{renderKeyItems()}</KeyIdentifiersContainer>
      </EmailViewerAndKeyIdentifiersContainer>
    );
  };

  const renderTaskDetails = () => {
    if (taskDetails && !isTaskDetailsLoading) {
      const stateOrNsa = taskDetails?.stateNsa?.stateNsa
        ? taskDetails?.stateNsa?.stateNsa?.toLowerCase() ===
          StateNsaAllStateEnum.STATE
          ? taskDetails.stateNsa.label
          : taskDetails.stateNsa?.stateNsa?.toUpperCase()
        : '';

      const taskDetailsArray = [
        {
          label: t(
            'ADMIN.QUEUE_TABS.QUEUE_DETAILS.AI_TASK_DETAILS_LABELS.CASE_#',
          ),
          value: taskDetails?.caseId ?? '',
        },
        {
          label: t(
            'ADMIN.QUEUE_TABS.QUEUE_DETAILS.AI_TASK_DETAILS_LABELS.TYPE',
          ),
          value: taskDetails?.taskType?.label ?? '',
        },
        {
          label: t(
            'ADMIN.QUEUE_TABS.QUEUE_DETAILS.AI_TASK_DETAILS_LABELS.CUSTOMER',
          ),
          value: taskDetails?.customerName ?? '',
        },
        {
          label: t(
            'ADMIN.QUEUE_TABS.QUEUE_DETAILS.AI_TASK_DETAILS_LABELS.NSA_STATE',
          ),
          value: stateOrNsa ?? '-',
        },
        {
          label: t(
            'ADMIN.QUEUE_TABS.QUEUE_DETAILS.AI_TASK_DETAILS_LABELS.PAYOR',
          ),
          value: taskDetails?.payorName ?? '',
        },
        {
          label: t(
            'ADMIN.QUEUE_TABS.QUEUE_DETAILS.AI_TASK_DETAILS_LABELS.DUE_DATE',
          ),
          value: taskDetails?.dueDate
            ? moment(taskDetails?.dueDate).format('YYYY-MM-DD')
            : '',
        },
        {
          label: t(
            'ADMIN.QUEUE_TABS.QUEUE_DETAILS.AI_TASK_DETAILS_LABELS.STATUS',
          ),
          value: taskDetails?.status ? capitalize(taskDetails?.status) : '',
        },
      ];

      return taskDetailsArray.map(taskDetailItem => {
        return (
          <TaskDetailContentGrid key={taskDetailItem.label}>
            <TaskDetailTypographyLabel>
              {taskDetailItem.label}
            </TaskDetailTypographyLabel>
            <TaskDetailTypographyValue>
              {taskDetailItem.value}
            </TaskDetailTypographyValue>
          </TaskDetailContentGrid>
        );
      });
    } else if (isTaskDetailsLoading) {
      return (
        <TaskDetailsLoadingGrid>
          <Loader size={30} />
        </TaskDetailsLoadingGrid>
      );
    } else {
      return (
        <TaskDetailsNoDataGrid>
          <TaskDetailsNoDataMessageTypography>
            {t('PRIVATE.EMAILS.EMAIL_DETAILS.NO_TASK_DETAILS')}
          </TaskDetailsNoDataMessageTypography>
        </TaskDetailsNoDataGrid>
      );
    }
  };

  const renderActionDetails = () => {
    if (isActionsTakenDetailsLoading) {
      return (
        <ActionTakenDetailsLoadingGrid>
          <Loader size={30} />
        </ActionTakenDetailsLoadingGrid>
      );
    } else {
      if (!networkErrorState && actionsTakenDetails?.length > 0) {
        return (
          <ActionTakenDetailGrid>
            {actionsTakenDetails.map((actionDetail, actionIndex) => (
              <ActionTakenDetailContentGrid key={actionIndex}>
                <ActionTakenDetailLabel>
                  {actionDetail?.label ?? ''}
                </ActionTakenDetailLabel>

                {actionDetail?.data?.length > 0 &&
                  actionDetail.data.map((dataPair, dataIndex) => {
                    const dataPairValue =
                      dataPair?.value?.toString().length > 0
                        ? dataPair.value.toString()
                        : '-';

                    return (
                      <ActionTakenMainDetails
                        key={`${actionIndex}-${dataIndex}`}
                      >
                        <ActionTakenDetailTypographyLabel>
                          {dataPair?.key ?? ''}
                        </ActionTakenDetailTypographyLabel>
                        <ActionTakenDetailTypographyValue>
                          {dataPairValue}
                        </ActionTakenDetailTypographyValue>
                      </ActionTakenMainDetails>
                    );
                  })}
              </ActionTakenDetailContentGrid>
            ))}
          </ActionTakenDetailGrid>
        );
      } else {
        return (
          <ActionTakenDetailsNoDataGrid>
            <ActionTakenDetailsNoDataMessageTypography>
              {t('PRIVATE.EMAILS.EMAIL_DETAILS.NO_ACTION_TAKEN_DETAILS')}
            </ActionTakenDetailsNoDataMessageTypography>
          </ActionTakenDetailsNoDataGrid>
        );
      }
    }
  };

  const renderTaskDetailsSection = () => {
    return (
      <TaskDetailsDiv>
        <TaskDetailsHeaderGrid>
          <TaskDetailsHeaderTypography>
            {t('PRIVATE.EMAILS.EMAIL_DETAILS.TASK_DETAILS')}
          </TaskDetailsHeaderTypography>
        </TaskDetailsHeaderGrid>
        <TaskDetailContentWrapperGrid>
          {renderTaskDetails()}
        </TaskDetailContentWrapperGrid>
      </TaskDetailsDiv>
    );
  };

  const renderActionDetailsSection = () => {
    return (
      <ActionDetailsDiv>
        <ActionTakenHeaderGrid>
          <ActionTakenHeaderTypography>
            {t('PRIVATE.EMAILS.EMAIL_DETAILS.ACTION_DETAILS')}
          </ActionTakenHeaderTypography>
        </ActionTakenHeaderGrid>
        <ActionTakenContentWrapperGrid>
          {renderActionDetails()}
        </ActionTakenContentWrapperGrid>
      </ActionDetailsDiv>
    );
  };

  const renderKeyIdentifiersSection = () => {
    return (
      <KeyIdentifiersDiv>
        <KeyIdentifiersHeaderGrid>
          <KeyIdentifiersHeaderTypography>
            {t('PRIVATE.EMAILS.EMAIL_DETAILS.KEY_IDENTIFIERS')}
          </KeyIdentifiersHeaderTypography>
        </KeyIdentifiersHeaderGrid>
        <KeyIdentifiersContentWrapperGrid>
          {renderKeyItems()}
        </KeyIdentifiersContentWrapperGrid>
      </KeyIdentifiersDiv>
    );
  };

  const renderTaskDetailsAndActionTaken = () => {
    return (
      <TaskDetailsAndActionTakenContainer>
        <TaskDetailsSection>{renderTaskDetailsSection()}</TaskDetailsSection>
        {taskDetails.taskLayoutType ===
        QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.AI ? (
          <KeyIdentifiersSection>
            {renderKeyIdentifiersSection()}
          </KeyIdentifiersSection>
        ) : null}
        <ActionTakenSection>{renderActionDetailsSection()}</ActionTakenSection>
      </TaskDetailsAndActionTakenContainer>
    );
  };

  const renderTaskTypeNSADropdownAndStatusReason = () => {
    return (
      <DropdownSectionWrapperGrid>
        {renderStatusReason(false)}
        {renderUnclassifiedUnFlaggedDetailsUI()}
      </DropdownSectionWrapperGrid>
    );
  };

  const renderDBQueryInfo = () => {
    return (
      <DBQueryMainContainer>
        {/* Exception Type */}
        <DBQueryMainDetails>
          <DBQueryLabel>
            {t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.DB_QUERY.CONNECTOR_DESCRIPTION')}
          </DBQueryLabel>
          <DBQueryValue>{dBQueryDetails?.description ?? '-'}</DBQueryValue>
        </DBQueryMainDetails>
        {/* Action */}
        <DBQueryMainDetails>
          <DBQueryLabel>
            {t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.DB_QUERY.INSTRUCTIONS')}
          </DBQueryLabel>
          <DBQueryValue>{dBQueryDetails?.instructions ?? '-'}</DBQueryValue>
        </DBQueryMainDetails>
        <Hr />
        {dBQueryDetails && dBQueryDetails?.data?.length > 0
          ? dBQueryDetails?.data.map((dataItem, index: number) => {
              return (
                <DBQueryDetails key={dataItem?.label ?? index}>
                  <DBQueryLabel>{dataItem?.label ?? ''}</DBQueryLabel>
                  <DBQueryValue>{dataItem?.value ?? ''}</DBQueryValue>
                </DBQueryDetails>
              );
            })
          : t('ADMIN.QUEUE_TABS.QUEUE_DETAILS.DB_QUERY.NO_DATA_FOUND')}
      </DBQueryMainContainer>
    );
  };

  const mapQueueDetailsViewLayoutPropsAsPerQueueLayoutConfig = () => {
    const newQueueDetailsViewLayoutProps = {
      top: renderTopBarSection(),
      left: renderIframe(),
      right: renderEmailViewerAndKeyIdentifiers(),
    };

    // top section render method assignment starts

    if (
      queueDetailsState?.taskLayoutViewerConfig?.top ===
      QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.BACK_BUTTON_ONLY
    ) {
      newQueueDetailsViewLayoutProps.top = renderBackButtonOnlyInTopBar();
    }

    if (
      queueDetailsState?.taskLayoutViewerConfig?.top ===
      QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.BACK_BUTTON_AND_ARCHIVE_BUTTON_FOR_UNCLASSIFIED_AND_UNFLAGGED_TASKS
    ) {
      newQueueDetailsViewLayoutProps.top =
        renderBackButtonAndArchiveButtonForUnClassifiedAndUnFlaggedTasksInTopBar();
    }

    if (
      queueDetailsState?.taskLayoutViewerConfig?.top ===
      QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.BACK_BUTTON_AND_RETRY_BUTTON_FOR_AI_FAILED_TASKS
    ) {
      newQueueDetailsViewLayoutProps.top =
        renderBackButtonAndRetryButtonForAIFailedTasksInTopBar();
    }

    // top section render method assignment ends

    // left section render method assignment starts

    if (
      queueDetailsState?.taskLayoutViewerConfig?.left ===
      QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.EMAIL_VIEWER
    ) {
      newQueueDetailsViewLayoutProps.left = renderEmailViewer(true);
    }

    // left section render method assignment ends

    // right section render method assignment starts

    // TASK_DETAILS_AND_ACTION_TAKEN
    if (
      queueDetailsState?.taskLayoutViewerConfig?.right ===
      QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.TASK_DETAILS_AND_ACTION_TAKEN_AND_KEY_IDENTIFIERS
    ) {
      newQueueDetailsViewLayoutProps.right = renderTaskDetailsAndActionTaken();
    }

    // TASK_TYPE_AND_STATE_NSA_DROPDOWN
    else if (
      queueDetailsState?.taskLayoutViewerConfig?.right ===
      QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.TASK_TYPE_AND_STATE_NSA_DROPDOWN
    ) {
      newQueueDetailsViewLayoutProps.right =
        renderTaskTypeNSADropdownAndStatusReason();
    }

    // EXCEPTION_TYPE_ACTION_TAKEN_AND_ERROR_LOGS
    else if (
      queueDetailsState?.taskLayoutViewerConfig?.right ===
      QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.EXCEPTION_TYPE_ACTION_TAKEN_AND_ERROR_LOGS
    ) {
      newQueueDetailsViewLayoutProps.right = renderTaskExceptions();
    }

    // DB_CONNECTOR_NAME_DESCRIPTION_AND_DATA
    else if (
      queueDetailsState?.taskLayoutViewerConfig?.right ===
      QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.DB_CONNECTOR_NAME_DESCRIPTION_AND_DATA
    ) {
      newQueueDetailsViewLayoutProps.right = renderDBQueryInfo();
    }

    // STATUS_ARCHIVED
    else if (
      queueDetailsState?.taskLayoutViewerConfig?.right ===
      QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.STATUS_ARCHIVED
    ) {
      newQueueDetailsViewLayoutProps.right = renderStatusReason(true);
    }

    // right section render method assignment ends

    return newQueueDetailsViewLayoutProps;
  };

  const renderQueueDetailsViewLayout = () => {
    // Step 1: Define the props to be sent based on config
    const queueDetailsViewLayoutProps =
      mapQueueDetailsViewLayoutPropsAsPerQueueLayoutConfig();

    // Step 2: Call the Component with defined props
    return (
      <div>
        {isUpdatingTaskStatus ? (
          <LoaderContainer>
            <Loader size={60} />
          </LoaderContainer>
        ) : null}
        <QueueDetailsViewLayout
          topBarChildren={queueDetailsViewLayoutProps.top}
          leftSectionChildren={queueDetailsViewLayoutProps.left}
          rightSectionChildren={queueDetailsViewLayoutProps.right}
        />
      </div>
    );
  };

  return (
    <Box>
      {isLoading ? (
        <CircularProgressStyled>
          <CircularProgress />
        </CircularProgressStyled>
      ) : (
        renderQueueDetailsViewLayout()
      )}
      {showFlagQueueItem && renderFlagQueueItem()}
      {showChangeQueueItem && renderChangeQueueItem()}
    </Box>
  );
};

export default QueueDetails;
