import {
  FormControl,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import { APP } from 'src/styles/variables';

export const SelectWrapperGrid = styled(Grid)`
  min-width: fit-content;
`;

export const SelectFieldLabel = styled(InputLabel)`
  position: static !important;
  transform: none;
  margin-bottom: 0.5rem;
`;

export const FormControlDiv = styled(FormControl)`
  width: 100%;
`;

export const PlaceholderTypography = styled(Typography)`
  color: ${APP.PALETTE.TEXT.PRIMARY};
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
`;

export const DropdownGroupHeader = styled(ListSubheader)`
  width: 100%;
  height: 2rem;
  padding: 0.1rem 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  letter-spacing: 0.02rem;
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
`;

export const DropdownMenuItem = styled(MenuItem)`
  padding: ${({ isindentationneeded }: { isindentationneeded: 'yes' | 'no' }) =>
    isindentationneeded === 'yes'
      ? '0.3rem 0.6rem 0.3rem 1.8rem'
      : '0.3rem 0.6rem 0.3rem 1rem'};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  letter-spacing: 0.02rem;
`;
