import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/Store';
import FormDialog from '@components/dialog/form-dialog/FormDialog';
import {
  DialogActionsContainer,
  SaveButtonStyled,
} from '@components/dialog/form-dialog/FormDialogStyle';
import {
  setSessionExpiredInfo,
  logout,
} from '@modules/home/state/auth/AuthStateSlice';
import AuthUtils from '@utils/AuthUtils';
import { t } from 'i18next';
import { APP } from 'src/styles/variables';

const SessionExpireDialog = (): React.ReactElement => {
  const dispatch = useDispatch();
  const { isSessionExpired } = useSelector((state: RootState) => state.auth);

  const handleOk = () => {
    dispatch(setSessionExpiredInfo({ isSessionExpired: false }));
    dispatch(logout());
    AuthUtils.clearStorage();
    sessionStorage.clear();
    window.location.replace('/');
  };

  const renderDialogContent = () => {
    return <div>{t('SESSION_EXPIRED.SESSION_EXPIRED_DESCRIPTION')}</div>;
  };

  const renderOkButton = () => {
    return (
      <DialogActionsContainer style={{ padding: '1rem' }}>
        <SaveButtonStyled onClick={handleOk}>
          <span>{t('APP.LOGIN')}</span>
        </SaveButtonStyled>
      </DialogActionsContainer>
    );
  };

  const renderModel = () => {
    return (
      <FormDialog
        isDialogOpen={isSessionExpired}
        showCloseIcon={false}
        titleText={t('SESSION_EXPIRED.SESSION_EXPIRED')}
        titleFontSize={APP.FONT.FONT_SIZE.H5}
        subTitleText=""
        dialogContent={renderDialogContent()}
        submitButtonText={t('APP.OK')}
        renderFooterButtonSection={renderOkButton()}
        cancelButtonText={''}
      />
    );
  };
  return <div> {isSessionExpired && renderModel()}</div>;
};

export default SessionExpireDialog;
