import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { APP } from 'src/styles/variables';

interface TopBarGridProps {
  alignItems?: string;
  justifyContent?: string;
  height?: string;
}

export const TopBarGrid = styled(Grid)<TopBarGridProps>`
  width: 100%;
  height: ${props => (props.height ? props.height : '5rem')};
  display: flex;
  align-items: ${props => (props.alignItems ? props.alignItems : 'flex-start')};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  background-color: ${({ theme }) => theme.palette.background.paper};
  border: 1px solid ${APP.PALETTE.DASHBOARD.BORDER_COLOR.PRIMARY};
`;
