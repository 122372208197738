import React, { ReactElement } from 'react';
import {
  ProgressBackground,
  ProgressContainer,
  ProgressElementStyled,
  ProgressTotalStyled,
} from './CustomProgressBarStyle';
import { APP } from 'src/styles/variables';

interface IProgressElement {
  value: number;
  textColor?: string;
  fontSize?: number;
  className?: string;
}

type CustomProgressBarTypeProps<T> = {
  backgroundColor?: string;
  border?: string;
  total: number;
  elements: (IProgressElement & T)[];
  height?: number | string;
  transitionTime?: number;
  className?: string;
};

const CustomProgressBar = <T extends Record<string, any> | boolean>({
  backgroundColor = APP.PALETTE.BACKGROUND.BASE_BACKGROUND,
  border = '',
  elements,
  height = 34,
  transitionTime = 0.6,
  className,
  total,
}: CustomProgressBarTypeProps<T>): ReactElement => {
  return (
    <>
      <ProgressTotalStyled>{total}</ProgressTotalStyled>
      <ProgressContainer height={height} border={border} className={className}>
        <ProgressBackground backgroundColor={backgroundColor} />

        {elements.map((element, i) => {
          const value = total > element.value ? total : element.value;
          const offsetValue = total - element.value;
          return (
            <ProgressElementStyled
              backgroundColor={'inherit'}
              offset={offsetValue < 0 ? 0 : offsetValue}
              value={value}
              total={total}
              transitionTime={transitionTime}
              textColor={element.textColor}
              fontSize={element.fontSize}
              key={i}
              className={element.className}
            >
              {element.value}
            </ProgressElementStyled>
          );
        })}
      </ProgressContainer>
    </>
  );
};

export default CustomProgressBar;
