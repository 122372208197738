import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import { setUserType } from '@modules/home/state/auth/AuthStateSlice';
import { CircularProgress } from '@mui/material';
import AuthUtils from '@utils/AuthUtils';
import { getErrorMessage } from '@utils/ErrorUtils';
import { enqueueSnackbar } from 'notistack';
import { getProfileDetails } from 'src/services/service-handlers/private/profile-details-handlers/ProfileDetailsPrivateService';
import { CircularProgressStyled } from 'src/styles/common/CommonStyles';

const LandingScreen = (): ReactElement => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const meRes = await getProfileDetails();
        if (meRes) {
          AuthUtils.setUserType(meRes.data.userType);
          dispatch(setUserType(meRes.data.userType));

          setTimeout(() => {
            navigate(PrivateNavigationRoutes.Home.Path);
          }, 2000);
          setIsLoading(false);
        }
      } catch (e) {
        const errMessage = getErrorMessage(e);
        enqueueSnackbar(errMessage, SnackBarConfig.getError());
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, navigate]);

  return (
    <div>
      {!isLoading && (
        <CircularProgressStyled>
          <CircularProgress />
        </CircularProgressStyled>
      )}
    </div>
  );
};

export default LandingScreen;
