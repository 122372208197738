import React from 'react';
import 'react-calendar/dist/Calendar.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import './DateRange.css';
import {
  DateRangeContainer,
  DateRangeContainerContent,
} from './CustomDateRangePickerStyle';
import { Value } from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types';

interface DateRangePickerProps {
  value: Value;
  onChange: (value: Value) => void;
  minDate?: Date;
}

const CustomDateRangePicker: React.FC<DateRangePickerProps> = ({
  value,
  onChange,
  minDate,
}) => {
  const [calendarOpen, setCalendarOpen] = React.useState(false);

  const handleDateChange = (newValue: Value) => {
    onChange(newValue);
    setCalendarOpen(false);
  };
  return (
    <div className="DateRangeMainContainer">
      <DateRangeContainer>
        <DateRangeContainerContent>
          <DateRangePicker
            clearIcon={null}
            calendarIcon={null}
            isOpen={calendarOpen}
            calendarAriaLabel="Toggle calendar"
            showDoubleView={true}
            dayAriaLabel="Day"
            monthAriaLabel="Month"
            nativeInputAriaLabel="Date"
            onChange={handleDateChange}
            value={value}
            yearAriaLabel="Year"
            minDate={minDate}
          />
        </DateRangeContainerContent>
      </DateRangeContainer>
    </div>
  );
};

export default CustomDateRangePicker;
