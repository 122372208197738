import { ReactElement } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

interface TextInputProps extends Omit<TextFieldProps, 'variant'> {
  label: string;
  variant?: 'filled' | 'outlined' | 'standard';
}

const CustomTextInput = (props: TextInputProps): ReactElement => {
  const { variant = 'outlined', label, ...rest } = props;

  return <TextField variant={variant} label={label} {...rest} />;
};

export default CustomTextInput;
