import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  EmailSettingsSearchAndFilterPayloadType,
  EmailSettingsStateType,
} from './EmailSettingsTypes';
import { generateRandomTableName } from '@utils/miscellaneousUtils';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';

const initialState: EmailSettingsStateType = {
  emailSettingsSearchAndFilterOptions: {},
  emailSettingsTableName: 'Email-Settings-Table-Without-Filter',
};

const EmailSettingsSlice = createSlice({
  initialState,
  name: 'emailSettings',
  reducers: {
    setEmailSettingsSearchAndFilterValues(
      state,
      action: PayloadAction<EmailSettingsSearchAndFilterPayloadType>,
    ) {
      state.emailSettingsSearchAndFilterOptions = action.payload.data;
    },
    changeEmailSettingsTableName(state) {
      state.emailSettingsTableName = generateRandomTableName(
        AdminConstants.EMAIL_SETTINGS.EMAIL_SETTINGS_TABLE_WITH_FILTERS,
      );
    },
  },
});

export const {
  setEmailSettingsSearchAndFilterValues,
  changeEmailSettingsTableName,
} = EmailSettingsSlice.actions;

export default EmailSettingsSlice.reducer;
