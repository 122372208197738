import styled from '@emotion/styled';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { APP } from 'src/styles/variables';

export const InfoCardBox = styled(Box)<{
  isactive: string;
  israngebuttonneeded: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 8.5rem;
  min-width: 8.31rem;
  max-width: 100%;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  border-radius: 0;
  background-color: ${({ theme, isactive }) =>
    isactive === 'yes'
      ? APP.PALETTE.CARD.BACKGROUND_COLOR.PRIMARY
      : theme.palette.background.paper};
  border: ${({ theme, isactive }) =>
    isactive === 'yes'
      ? '3px solid ' + theme.palette.primary.main
      : '3px solid transparent'};
  cursor: pointer;
  padding: ${({ israngebuttonneeded }: { israngebuttonneeded: string }) =>
    israngebuttonneeded === 'yes'
      ? '0.34rem 0.35rem 1.55rem 1.81rem'
      : '1.48rem 1.81rem 1.55rem 1.81rem'};

  &:hover {
    background-color: ${APP.PALETTE.CARD.BACKGROUND_COLOR.PRIMARY};
    border: 3px solid;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const DateRangeTabsWrapperDiv = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 1.12rem;
`;

export const InfoCardContentDiv = styled('div')`
  display: flex;
  width: 100%;
  height: inherit;
  flex-direction: column;
  padding: ${({ israngebuttonneeded }: { israngebuttonneeded: string }) =>
    israngebuttonneeded === 'yes' ? '0 1.81rem 0 0' : '0'};
`;

export const DateRangeTabs = styled(Tabs)`
  width: fit-content;
  min-height: auto;
  display: flex !important;
  justify-content: center !important;
  align-items: flex-end !important;
`;

export const TimeSpanButtonTab = styled(Tab)`
  color: ${APP.PALETTE.CARD.FONT_COLOR.PRIMARY};
  min-height: fit-content;
  min-width: fit-content;
  font-size: 0.87rem;
  padding: 0 0.12rem;
`;

export const CountTypography = styled(Typography)`
  width: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
  font-size: 2.375rem;
  font-weight: 700;
  line-height: 2.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TextDescriptionTypography = styled(Typography)`
  width: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: ${APP.FONT.FONT_FAMILY.SEMIBOLD};
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  white-space: wrap;
`;
