import React from 'react';
import { useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIconForSnackBar from '@assets/images/CloseIconWhite.svg';

interface SnackbarCloseButtonProps {
  snackbarKey: string | number;
}

const SnackbarCloseButton: React.FC<SnackbarCloseButtonProps> = ({
  snackbarKey,
}) => {
  const { closeSnackbar } = useSnackbar();

  const handleClose = () => {
    closeSnackbar(snackbarKey);
  };

  return (
    <IconButton onClick={handleClose}>
      <img src={CloseIconForSnackBar} />
    </IconButton>
  );
};

export default SnackbarCloseButton;
