import { decrypt, encrypt } from './LocalStorage';

class Storage {
  static KEYS = {
    AUTH_TOKEN: 'AUTH_TOKEN',
    IS_AZURE_AUTHENTICATED: 'IS_AZURE_AUTHENTICATED',
    SELECTED_LOGIN_TYPE: 'SELECTED_LOGIN_TYPE',
    USER_TYPE: 'USER_TYPE',
    AZURE_LOGIN: 'AZURE_LOGIN',
    TASK_OWNER_TYPE: 'TASK_OWNER_TYPE',
    STATE_NSA: 'STATE_NSA',
    ASSIGNED: 'ASSIGNED',
  };

  static setItem = (key: string, value: any): void => {
    if (!value) {
      value = '';
    }
    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(encrypt(key), encrypt(value));
  };

  static getItem = (key: string, shouldParse?: boolean): string | null => {
    const keyVal = decrypt(localStorage.getItem(Storage.searchKey(key)));
    if (shouldParse && keyVal) {
      return JSON.parse(keyVal);
    }
    return keyVal;
  };

  static searchKey(key: string): string {
    const keys = Object.keys(localStorage);
    let i = keys.length;
    while (i--) {
      try {
        if (decrypt(keys[i]) === key) {
          return keys[i];
        }
      } catch (e) {
        localStorage.removeItem(keys[i]);
      }
    }
    return '';
  }

  static removeItem = (key: string): void => {
    localStorage.removeItem(Storage.searchKey(key));
  };

  static clear = (): void => {
    localStorage.clear();
  };
}

export default Storage;
