// eslint-disable @typescript-eslint/explicit-module-boundary-types
import moment from 'moment';

import { QueueTaskStatusTypes } from '../main/admin/queue/queue-constants/QueueConstants';
import {
  CHUNK_SIZE,
  DateFormatUTC,
  StateNsaLabelMapping,
} from '@constants/global-constants/constants';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';

// Output will be "3:30 PM" if it's today's date, otherwise "13 Mar"
export const formatEmailDateTime = (
  dateTime: string | number | Date,
): string => {
  const receivedDateTime = new Date(dateTime);

  // Function to add leading zeros to numbers less than 10
  const addLeadingZero = (num: number): string => {
    return num < 10 ? '0' + num : num.toString();
  };

  const formattedDate = `${addLeadingZero(
    receivedDateTime.getMonth() + 1,
  )}/${addLeadingZero(
    receivedDateTime.getDate(),
  )}/${receivedDateTime.getFullYear()}`;

  let timeString = receivedDateTime.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
  // Adjust the time string to add AM/PM and remove seconds
  timeString = timeString.replace(/:\d+ /, ' ').toLowerCase();
  if (timeString) {
    return `${formattedDate} ${timeString}`;
  } else {
    return `${formattedDate} `;
  }
};

// Output: "04/06/2024"
export const formatDate = (inputDate: string): string => {
  const date = new Date(inputDate);
  const month = date.getMonth() + 1; // getMonth() returns zero-based month
  const day = date.getDate();
  const year = date.getFullYear();

  // Pad single digit month and day with leading zeros
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedDay = day < 10 ? `0${day}` : `${day}`;

  // Return formatted date string in mm/dd/yyyy format
  return `${formattedMonth}/${formattedDay}/${year}`;
};

export const statusFormat = (text: string): string => {
  if (text === QueueTaskStatusTypes.IN_PROGRESS) return 'In Progress';
  else if (text === QueueTaskStatusTypes.FLAGGED) return 'Flagged';
  else if (text === QueueTaskStatusTypes.ASSIGNED) return 'Assigned';
  else if (text === QueueTaskStatusTypes.UNASSIGNED) return 'Unassigned';
  else if (text === QueueTaskStatusTypes.COMPLETED) return 'Completed';
  else return text && text.charAt(0).toUpperCase() + text.slice(1);
};

export const formatDateTime = (dateTimeValue: string): string => {
  const utcDate = moment.utc(dateTimeValue);
  // formatting to UTC format
  const formattedUtcDate = utcDate.format(DateFormatUTC);

  return formattedUtcDate;
};

export const generateUniqueKey = (): string => {
  const newKey = Math.random();
  return newKey.toString();
};

export const capitalizeEveryWord = (str: string): string => {
  return str.replace(/\b\w/g, char => char.toUpperCase());
};

// input:  '2023-11-10T18:10:00.000Z'  Output: Fri 11/10/2023 6:10PM
export const formatEmailDate = (inputDate: string | number | Date): string => {
  const date = new Date(inputDate);
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const day = days[date.getDay()];
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const dayOfMonth = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let period = 'AM';

  if (hours > 12) {
    hours -= 12;
    period = 'PM';
  }

  hours = String(hours).padStart(2, '0') as unknown as number;
  minutes = String(minutes).padStart(2, '0') as unknown as number;

  return `${day} ${month}/${dayOfMonth}/${year} ${hours}:${minutes}${period}`;
};

export const mapStateNsaLabel = (label?: string | null): string => {
  if (!label) {
    return '';
  }
  return StateNsaLabelMapping[label] || '';
};

export const processArrayBufferInChunks = (arrayBuffer: Uint8Array): string => {
  let result = '';
  for (let i = 0; i < arrayBuffer.length; i += CHUNK_SIZE) {
    result += String.fromCharCode.apply(
      null,
      Array.from(arrayBuffer.subarray(i, i + CHUNK_SIZE)),
    );
  }
  return result;
};

export const isUserPortal = (): boolean => {
  return (
    location.pathname.split('/')[1] ===
    PrivateNavigationRoutes.User.FullPath.substring(1)
  );
};

export const executeAfterTimeout = (
  callback: () => void,
  timeout = 100,
): void => {
  setTimeout(callback, timeout);
};

export const formatDateWithoutTime = (date: Date | null | string): string => {
  const momentDate = moment(date);

  if (date === null || !momentDate.isValid()) {
    return '';
  }

  return momentDate.format('YYYY-MM-DD');
};
