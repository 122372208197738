import { createSlice } from '@reduxjs/toolkit';

import { AuthenticationStateType } from './AuthStateTypes';
import AuthUtils from '@utils/AuthUtils';

const initialState: AuthenticationStateType = {
  isAuthenticated: false,
  userType: AuthUtils.getUserType() || '',
  isSessionExpired: false,
};

const authenticationSlice = createSlice({
  initialState,
  name: 'authentication',
  reducers: {
    login: state => {
      state.isAuthenticated = true;
    },
    logout: state => {
      state.isAuthenticated = false;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setSessionExpiredInfo: (state, action) => {
      state.isSessionExpired = action.payload.isSessionExpired;
    },
  },
});

export const { login, logout, setUserType, setSessionExpiredInfo } =
  authenticationSlice.actions;
export default authenticationSlice.reducer;
