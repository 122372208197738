import styled from '@emotion/styled';
import { Avatar, Button } from '@mui/material';
import { APP } from 'src/styles/variables';
import { UserCellImageWrapperDiv } from '../../../dashboard/DashboardStyle';

export const ButtonHoverStyled = styled(Button)`
  color: ${APP.FONT.FONT_COLOR.SECONDARY};
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
  text-transform: capitalize;
  text-decoration: underline;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

export const UserCellImageDivStyled = styled(UserCellImageWrapperDiv)`
  padding: 0 0.5rem;
`;

export const LoadingGridForProfilePic = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
`;

export const AvatarStyled = styled(Avatar)`
  width: 2rem;
  height: 2rem;
`;

export const DataNotFoundDiv = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 1rem;
  border: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
`;
