import { ReactElement, useState } from 'react';
import { produce } from 'immer';
import saveAs from 'file-saver';
import {
  EmailAttachmentContentGrid,
  EmailAttachmentIconImage,
  EmailAttachmentNameTypography,
  EmailAttachmentWrapperGrid,
  EmailDetailsContainerGrid,
  EmailDivStyled,
  HorizontalEmailSectionDivider,
  KeywordStyledSpan,
  RichTextEmailViewer,
} from './EmailRichTextViewerStyle';
import AttachmentIcon from '@assets/images/Attachment.svg';
import EmailAttachmentViewerDialog from '@components/dialog/email-attachment-viewer-dialog/EmailAttachmentViewerDialog';
import {
  capitalizeEveryWord,
  formatEmailDate,
  processArrayBufferInChunks,
} from '@modules/home/utils/Utils';
import { APP } from 'src/styles/variables';
import { t } from 'i18next';
import { GetEmailDetailsByIdFileDto } from 'src/services/service-handlers/private/email-handlers/EmailServiceMapper';
import { getErrorMessage } from '@utils/ErrorUtils';
import { enqueueSnackbar } from 'notistack';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { getFileById } from 'src/services/service-handlers/private/files-handlers/FilesPrivateService';
import { UNSUPPORTED_FILE_TYPES_ATTACHMENT_PREVIEW } from '@constants/global-constants/constants';

interface IEmailRichTextViewer {
  fromEmail: string;
  toEmail: string;
  cc?: string;
  subject: string;
  body: string;
  files: GetEmailDetailsByIdFileDto[] | any[];
  receivedAt: string;
  padding?: string;
  SubjectFontSize?: number;
  SubjectFontWeight?: number;
  FromEmailFontSize?: number;
  FromEmailFontWeight?: number;
  ToEmailFontSize?: number;
  ToEmailFontWeight?: number;
}

const EmailRichTextViewer = (props: IEmailRichTextViewer): ReactElement => {
  const {
    fromEmail,
    toEmail,
    cc,
    subject,
    body,
    files,
    receivedAt,
    padding = '3.125rem 3.625rem',
    SubjectFontSize = '1.25',
    SubjectFontWeight = APP.FONT.FONT_WEIGHT.CAPTION,
    FromEmailFontSize = '1',
    FromEmailFontWeight = APP.FONT.FONT_WEIGHT.H6,
    ToEmailFontSize = '1',
    ToEmailFontWeight = APP.FONT.FONT_WEIGHT.H6,
  } = props;
  const [state, setState] = useState({
    shouldDialogOpen: false,
    isDialogContentLoading: false,
    dialogTitle: '',
    dialogContent: '',
    base64DataURL: '',
    isMimeTypeUnsupported: false,
  });
  const modules = {
    toolbar: false,
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];

  const fetchEmailAttachment = async (attachmentItem: string | any) => {
    setState(
      produce(draft => {
        draft.isDialogContentLoading = true;
      }),
    );

    try {
      const fetchAttachmentDataApiResponse = await getFileById(
        attachmentItem.id,
      );

      const arrayBuffer = new Uint8Array(
        fetchAttachmentDataApiResponse?.data?.content?.data,
      );

      const array = processArrayBufferInChunks(arrayBuffer);

      const base64String = btoa(array);

      const fetchedAttachmentData = {
        fileTitle: attachmentItem.name,
        fileData: `data:${fetchAttachmentDataApiResponse.data.mimeType};base64,${base64String}`,
      };

      if (
        UNSUPPORTED_FILE_TYPES_ATTACHMENT_PREVIEW.includes(
          fetchAttachmentDataApiResponse.data.mimeType,
        )
      ) {
        setState(
          produce(draft => {
            draft.isMimeTypeUnsupported = true;
          }),
        );
      } else {
        setState(
          produce(draft => {
            draft.isMimeTypeUnsupported = false;
          }),
        );
      }

      setState(
        produce(
          (draft: {
            shouldDialogOpen: boolean;
            dialogTitle: any;
            dialogContent: string;
            base64DataURL: string;
            isDialogContentLoading: boolean;
          }) => {
            draft.shouldDialogOpen = true;
            draft.dialogTitle = fetchedAttachmentData.fileTitle;
            draft.dialogContent = fetchedAttachmentData.fileData;
            draft.base64DataURL = fetchedAttachmentData.fileData;
            draft.isDialogContentLoading = false;
          },
        ),
      );
    } catch (errorResponse) {
      const errorMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    } finally {
      setState(
        produce(draft => {
          draft.isDialogContentLoading = false;
        }),
      );
    }
  };

  const handleEmailAttachmentClick = async (emailAttachmentItem: any) => {
    setState(prevState => ({
      ...prevState,
      shouldDialogOpen: true,
      isDialogContentLoading: true,
    }));

    setState(
      produce(
        (draft: {
          shouldDialogOpen: boolean;
          isDialogContentLoading: boolean;
        }) => {
          draft.shouldDialogOpen = true;
          draft.isDialogContentLoading = true;
        },
      ),
    );

    await fetchEmailAttachment(emailAttachmentItem);
  };

  const handleEmailAttachmentDownload = () => {
    saveAs(state.base64DataURL, state.dialogTitle);
  };

  const dialogClose = () => {
    setState(prevState => ({
      ...prevState,
      shouldDialogOpen: false,
    }));
  };

  const renderEmailAttachments = (emailAttachmentsList: any[]) => {
    return emailAttachmentsList.map(emailAttachmentItem => {
      return (
        <EmailAttachmentContentGrid
          key={emailAttachmentItem.id}
          onClick={() => handleEmailAttachmentClick(emailAttachmentItem)}
        >
          <EmailAttachmentIconImage src={AttachmentIcon} alt="..." />
          <EmailAttachmentNameTypography>
            {emailAttachmentItem.name}
          </EmailAttachmentNameTypography>
        </EmailAttachmentContentGrid>
      );
    });
  };

  const renderDialog = (dialogProps: any): ReactElement => {
    return <EmailAttachmentViewerDialog {...dialogProps} />;
  };

  const renderCC = (allCC: string) => {
    return (
      <EmailDivStyled
        fontSize={FromEmailFontSize}
        fontWeight={FromEmailFontWeight}
      >
        <KeywordStyledSpan
          fontSize={APP.FONT.FONT_SIZE.BODY1}
          fontWeight={APP.FONT.FONT_WEIGHT.CAPTION}
        >
          {t('ADMIN.EMAIL.CC')}
        </KeywordStyledSpan>
        <span>{allCC}</span>
      </EmailDivStyled>
    );
  };

  return (
    <>
      {renderDialog({
        open: state.shouldDialogOpen,
        handleCloseBtnClick: dialogClose,
        isLoading: state.isDialogContentLoading,
        dialogTitle: state.dialogTitle,
        dialogContent: state.dialogContent,
        handleDownloadBtnClick: handleEmailAttachmentDownload,
        isMimeTypeUnsupported: state.isMimeTypeUnsupported,
      })}

      <EmailDetailsContainerGrid padding={padding}>
        <EmailDivStyled
          fontSize={SubjectFontSize}
          fontWeight={SubjectFontWeight}
        >
          {capitalizeEveryWord(subject)}
        </EmailDivStyled>

        <EmailDivStyled
          fontSize={FromEmailFontSize}
          fontWeight={FromEmailFontWeight}
        >
          <KeywordStyledSpan
            fontSize={APP.FONT.FONT_SIZE.BODY1}
            fontWeight={APP.FONT.FONT_WEIGHT.CAPTION}
          >
            {t('ADMIN.EMAIL.FROM')}
          </KeywordStyledSpan>
          {fromEmail}
        </EmailDivStyled>

        <EmailDivStyled
          fontSize={ToEmailFontSize}
          fontWeight={ToEmailFontWeight}
        >
          <KeywordStyledSpan
            fontSize={APP.FONT.FONT_SIZE.BODY1}
            fontWeight={APP.FONT.FONT_WEIGHT.CAPTION}
          >
            {t('ADMIN.EMAIL.TO')}
          </KeywordStyledSpan>
          {toEmail}
        </EmailDivStyled>

        {cc && renderCC(cc)}

        <EmailDivStyled
          fontSize={ToEmailFontSize}
          fontWeight={ToEmailFontWeight}
        >
          <KeywordStyledSpan
            fontSize={APP.FONT.FONT_SIZE.BODY1}
            fontWeight={APP.FONT.FONT_WEIGHT.CAPTION}
          >
            {t('ADMIN.EMAIL.RECEIVED_AT')}
          </KeywordStyledSpan>
          {formatEmailDate(receivedAt)}
        </EmailDivStyled>

        {files && files.length !== 0 && (
          <EmailAttachmentWrapperGrid>
            {renderEmailAttachments(files)}
          </EmailAttachmentWrapperGrid>
        )}

        <HorizontalEmailSectionDivider />
        {body && (
          <RichTextEmailViewer
            issubjectviewer={'no'}
            value={body}
            modules={modules}
            formats={formats}
            readOnly={true}
          />
        )}
      </EmailDetailsContainerGrid>
    </>
  );
};

export default EmailRichTextViewer;
