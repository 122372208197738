import { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import {
  TabsFilterWrapper,
  TabFilterButtonWrapperDiv,
  CustomTab,
  TabFilterDivider,
} from './TabFilterStyle';

export interface ITabFilterButtons {
  label: string;
  value: string;
}

interface ITabFilterProps {
  isDefaultTabFilterOptionsNeeded?: boolean;
  onSelectionChange?: (value: string) => void;
  filterOptions?: ITabFilterButtons[];
  defaultSelectedTab?: string | number;
}

const defaultTabFilterButtons: ITabFilterButtons[] = [
  { label: 'All', value: 'All' },
  { label: 'Assigned', value: 'Assigned' },
  { label: 'Unassigned', value: 'Unassigned' },
];

const TabFilter = (props: ITabFilterProps): ReactElement => {
  const {
    isDefaultTabFilterOptionsNeeded = false,
    onSelectionChange,
    filterOptions = [],
    defaultSelectedTab,
  } = props;

  const [tabValue, setTabValue] = useState<string | number>('');

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    if (onSelectionChange) onSelectionChange(newValue);
  };

  useEffect(() => {
    if (isDefaultTabFilterOptionsNeeded) {
      setTabValue(defaultTabFilterButtons[0].value);
    } else {
      setTabValue(filterOptions[0]?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultSelectedTab) {
      setTabValue(defaultSelectedTab);
    }
  }, [defaultSelectedTab]);

  const renderTabFilterButtons = (filterOptionsList: ITabFilterButtons[]) => {
    if (Array.isArray(filterOptionsList) && filterOptionsList.length > 0) {
      return filterOptionsList.map(
        (filterOption: ITabFilterButtons, index: number) => {
          return (
            <TabFilterButtonWrapperDiv
              key={filterOption.value}
              onClick={(e: SyntheticEvent) =>
                handleChange(e, filterOption.value)
              }
            >
              <CustomTab
                label={filterOption.label}
                value={filterOption.value}
                isactive={tabValue === filterOption.value ? 'yes' : 'no'}
                disableRipple
              />
              {index !== filterOptionsList.length - 1 && (
                <TabFilterDivider
                  variant="middle"
                  orientation="vertical"
                  flexItem
                />
              )}
            </TabFilterButtonWrapperDiv>
          );
        },
      );
    }
  };

  return (
    <TabsFilterWrapper value={false} onChange={handleChange}>
      {isDefaultTabFilterOptionsNeeded
        ? renderTabFilterButtons(defaultTabFilterButtons)
        : renderTabFilterButtons(filterOptions)}
    </TabsFilterWrapper>
  );
};

export default TabFilter;
