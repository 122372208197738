import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('PUBLIC.LOG_IN.ERROR_MESSAGE.EMAIL_REQUIRED')
    .email('PUBLIC.LOG_IN.ERROR_MESSAGE.INVALID_EMAIL')
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'PUBLIC.LOG_IN.ERROR_MESSAGE.INVALID_EMAIL',
    ),
  password: Yup.string().required(
    'PUBLIC.LOG_IN.ERROR_MESSAGE.PASSWORD_REQUIRED',
  ),
});
