import TopBarWrapperGrid from '@components/top-bar-wrapper-grid/TopBarWrapperGrid';
import { CircularProgress, Grid } from '@mui/material';
import {
  FilterIconButton,
  ParentGridContainerStyled,
  ProgressBarContainer,
  ProgressBarGridStyled,
  ProgressTextStyled,
  UserSearchBarStyledDiv,
} from './UserQueueStyle';
import QueueTable from '../../admin/queue/queue-table/QueueTable';
import { t } from 'i18next';
import { APP } from 'src/styles/variables';
import { ReactElement, useEffect, useState } from 'react';
import { getErrorMessage } from '@utils/ErrorUtils';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { enqueueSnackbar } from 'notistack';
import CustomProgressBar from '@components/custom-progress-bar/CustomProgressBar';
import { getTaskProgress } from 'src/services/service-handlers/private/tasks-handlers/TasksPrivateService';
import { GetProgressDataDto } from 'src/services/service-handlers/private/tasks-handlers/TasksServiceMapper';
import { produce } from 'immer';
import { CircularProgressStyled } from 'src/styles/common/CommonStyles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/Store';
import {
  setIsRefetchingNeeded,
  setQueueSearchAndFilterOptions,
  taskCompleted,
} from '@modules/home/state/queue/QueueSlice';
import Filter from '@assets/images/Filter.svg';
import SearchAndFilterDialog, {
  ISearchAndFilterDialogProps,
} from '@components/dialog/search-and-filter-dialog/SearchAndFilterDialog';
import { FilterConfigurationType } from 'src/types/adminTypes';
import USER_PORTAL_SEARCH_AND_FILTER_CONFIGURATIONS from '../SearchAndFilterConfigurations';
import { executeAfterTimeout } from '@modules/home/utils/Utils';
import moment from 'moment';

interface IUserQueueState {
  progressData: GetProgressDataDto;
  isLoading: boolean;
  shouldDialogOpen: boolean;
  filterConfiguration: FilterConfigurationType;
  searchAndFilterOptions: { [key: string]: string | boolean };
}

const UserQueue = (): ReactElement => {
  const dispatch = useDispatch();
  const [userQueueState, setUserQueueState] = useState<IUserQueueState>({
    isLoading: false,
    progressData: {
      dailyCompletedTasksCount: 0,
      dailyTasksUserTarget: 0,
    },
    shouldDialogOpen: false,
    filterConfiguration: {
      SEARCH_BY_OPTIONS: [],
      FILTER_OPTIONS: [],
    },
    searchAndFilterOptions: {},
  });

  const { isTaskCompleted } = useSelector((state: RootState) => state.queue);
  const { progressData, isLoading } = userQueueState;

  const init = async () => {
    setUserQueueState(
      produce(draft => {
        draft.isLoading = true;
      }),
    );
    try {
      const todayDate = moment().toString();
      const progressRes = await getTaskProgress({ todayDate });
      setUserQueueState(
        produce(draft => {
          draft.progressData = progressRes.data;
        }),
      );
      dispatch(taskCompleted({ isTaskCompleted: false }));
    } catch (e) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setUserQueueState(
        produce(draft => {
          draft.isLoading = false;
        }),
      );
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTaskCompleted]);

  const renderSearchAndFilterDropdownDialog = (
    dialogProps: ISearchAndFilterDialogProps,
  ) => {
    return <SearchAndFilterDialog {...dialogProps} />;
  };

  const dialogClose = () => {
    setUserQueueState(
      produce(draft => {
        draft.shouldDialogOpen = false;
      }),
    );
  };

  const handleFilterIconButtonClick = () => {
    setUserQueueState(
      produce(draft => {
        draft.shouldDialogOpen = true;
        draft.filterConfiguration =
          USER_PORTAL_SEARCH_AND_FILTER_CONFIGURATIONS.QUEUE_SEARCH_AND_FILTER_CONFIG;
      }),
    );
  };

  const handleApplySearchAndFilterButtonClick = (data: {
    [key: string]: string | boolean;
  }) => {
    if (!data?.searchValue) {
      delete data?.searchKey;
      delete data?.searchValue;
    }
    const values = Object.values(data);
    for (let i = 0; i < values.length; i++) {
      const valueItem = values[i];
      if (valueItem) {
        setUserQueueState(
          produce(draft => {
            draft.searchAndFilterOptions = data;
          }),
        );
        dispatch(setQueueSearchAndFilterOptions({ data: data }));
        dispatch(setIsRefetchingNeeded({ data: true }));
        break;
      }
    }

    executeAfterTimeout(() => {
      setUserQueueState(
        produce(draft => {
          draft.shouldDialogOpen = false;
        }),
      );
    }, 100);
  };

  const handleSearchAndFilterClearButtonClick = () => {
    dispatch(setQueueSearchAndFilterOptions({ data: {} }));
    setUserQueueState(
      produce(draft => {
        draft.searchAndFilterOptions = {};
      }),
    );
    if (Object.entries(userQueueState.searchAndFilterOptions).length !== 0) {
      dispatch(setIsRefetchingNeeded({ data: true }));
    }

    executeAfterTimeout(() => {
      setUserQueueState(
        produce(draft => {
          draft.shouldDialogOpen = false;
        }),
      );
    }, 100);
  };

  return isLoading ? (
    <CircularProgressStyled>
      <CircularProgress />
    </CircularProgressStyled>
  ) : (
    <>
      <TopBarWrapperGrid height="5rem">
        <ParentGridContainerStyled container>
          <Grid item>
            <UserSearchBarStyledDiv>
              <FilterIconButton onClick={handleFilterIconButtonClick}>
                <img src={Filter} height={36} width={36} alt="..." />
              </FilterIconButton>
              {renderSearchAndFilterDropdownDialog({
                open: userQueueState.shouldDialogOpen,
                configuration: userQueueState.filterConfiguration,
                handleCloseBtnClick: dialogClose,
                handleApplyFilterClick: (data: {
                  [key: string]: string | boolean;
                }) => handleApplySearchAndFilterButtonClick(data),
                handleClearFilterClick: handleSearchAndFilterClearButtonClick,
                topDistance: '5.937rem',
                leftDistance: '-0.937rem',
                isLeftPosition: true,
              })}
            </UserSearchBarStyledDiv>
          </Grid>
        </ParentGridContainerStyled>
        <ProgressBarGridStyled item>
          <ProgressBarContainer>
            <CustomProgressBar
              total={progressData?.dailyTasksUserTarget || 0}
              elements={[
                {
                  value: progressData?.dailyCompletedTasksCount || 0,
                  fontSize: 16,
                  textColor: APP.PALETTE.COMMON.WHITE,
                  className: 'progress-segment-1',
                },
              ]}
            />
            <ProgressTextStyled>{t('USER.QUEUE.PROGRESS')}</ProgressTextStyled>
          </ProgressBarContainer>
        </ProgressBarGridStyled>
      </TopBarWrapperGrid>
      <QueueTable isRowSelectionNeeded={false} isUserPortal={true} />
    </>
  );
};

export default UserQueue;
