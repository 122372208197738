import { AdminConstants } from '@constants/admin-constants/AdminConstants';

export const GLOBAL_CONSTANTS = {};

export const QueuePriorityTypes = {
  UN_ORDERED: 'U',
  HIGH: 'H',
  LOW: 'L',
  MEDIUM: 'M',
};

export const staticPriorityDropdownOptionsList = [
  { optionId: 'high', optionText: 'High' },
  {
    optionId: 'medium',
    optionText: 'Medium',
  },
  {
    optionId: 'low',
    optionText: 'Low',
  },
];

export const staticStatusDropdownOptionsList = [
  { optionId: 'open', optionText: 'Open' },
  { optionId: 'unassigned', optionText: 'Unassigned' },
  {
    optionId: 'assigned',
    optionText: 'Assigned',
  },
  {
    optionId: 'inprogress',
    optionText: 'In Progress',
  },
  {
    optionId: 'flagged',
    optionText: 'Flagged',
  },
  {
    optionId: 'completed',
    optionText: 'Completed',
  },
  {
    optionId: 'failed',
    optionText: 'Failed',
  },
];

export const BOOLEAN_TYPES = {
  YES: 'yes',
  NO: 'no',
};

export const SORT_ORDER = {
  DUE_DATE: 'dueDate',
  ASC: 'asc',
  DESC: 'desc',
  DATE: 'date',
  COMPLETED_DATE: 'completedOn',
  EMAIL_DATE: 'emailDate',
};

export const SEARCH_DEBOUNCE_DELAY_IN_MS = 500;

export const USER_TYPES = {
  ADMIN: 'admin',
  USER: 'user',
};

export const FormatDate = {
  MonthAndDay: 'MMMM dd',
};

export const DateFormatUTC = 'MM/DD/YYYY hh:mm A';

export const UNSUPPORTED_FILE_TYPES_ATTACHMENT_PREVIEW = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/vnd.oasis.opendocument.text',
  'application/text',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const ERROR_CONSTANTS = {
  ERR_NETWORK: 'ERR_NETWORK',
};

export const FETCHING_AVAILABLE_FOR_FILTER_OPTIONS = [
  'assignedUserId',
  'taskCategoryId',
  'taskTypeId',
  'nsaState',
  'title',
  'role',
];

export const AVAILABLE_FILTER_DROPDOWN_OPTIONS = {
  ASSIGNEE: 'assignedUserId',
  STATUS: 'taskStatus',
  PRIORITY: 'taskPriority',
  TASK_CATEGORY: 'taskCategoryId',
  TASK_TYPE: 'taskTypeId',
  NSA_OR_STATE: 'nsaState',
  TITLE: 'title',
  ROLE: 'role',
};

export const FILTER_OPTION_FIELD_TYPES = {
  DROPDOWN: 'dropdown',
  CHECKBOX: 'checkbox',
};

export const VALIDATION_IMAGE_CONSTANTS = {
  JPEG: 'image/jpeg',
  JPG: 'image/jpg',
  WEBP: 'image/WebP',
  PNG: 'image/png',
};

export const QUEUE_SCREEN_TABS = {
  QUEUE: 'Queue',
  QUEUE_AI: 'QueueAI',
  UNCLASSIFIED: 'Unclassified',
  HISTORY: 'History',
  ASSIGNMENTS: 'Assignments',
};

export const MAX_FILE_SIZE_FOR_PROFILE_PIC = {
  // max file size 2MB = 2 * 1024 * 1024 bytes
  MAX_FILE_SIZE_ALLOWED: 2097152,
};

export const EXPECTED_PARAM_KEYS = [
  'assigned',
  'status',
  'categoryId',
  'typeId',
  'priority',
  'userId',
  'stateNsa',
];

export const QueueTaskStatusDropdownOptions = [
  'assigned',
  'inprogress',
  'unassigned',
];

export const QueueAITaskStatusDropdownOptions = [
  'assigned',
  'inprogress',
  'completed',
  'failed',
  'open',
];

export const UserQueueTaskStatusDropdownOptions = [
  'assigned',
  'inprogress',
  'flagged',
];

export const QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION = {
  DEFAULT: 'default',
  AI: 'ai',
  UNCLASSIFIED: 'unclassified',
  DATA_EXCEPTIONS: 'data-exceptions',
  DB_QUERY: 'db-query',
  TASK_DETAILS: 'task-details',
  TASK_DETAILS_AND_ACTION_TAKEN_AND_KEY_IDENTIFIERS:
    'task-details-and-action-taken-and-key-identifiers',
  ARBIT: 'arbit',
  EMAIL_VIEWER: 'email-viewer',
  EMAIL_VIEWER_AND_KEY_IDENTIFIERS: 'email-viewer-and-key-identifiers',
  BACK_BUTTON_ONLY: 'back-button-only',
  BACK_BUTTON_AND_RETRY_BUTTON_FOR_AI_FAILED_TASKS:
    'back-button-and-retry-button-for-ai-failed-tasks',
  BACK_BUTTON_AND_ARCHIVE_BUTTON_FOR_UNCLASSIFIED_AND_UNFLAGGED_TASKS:
    'back-button-and-retry-button-for-unclassified-and-unflagged-tasks',
  TASK_TYPE_AND_STATE_NSA_DROPDOWN: 'task-type-and-state-nsa-dropdown',
  EXCEPTION_TYPE_ACTION_TAKEN_AND_ERROR_LOGS:
    'exception-type-action-and-error-logs',
  DB_CONNECTOR_NAME_DESCRIPTION_AND_DATA:
    'db-connector-name-description-and-data',
  STATUS_ARCHIVED: 'status-reason-viewer',
};

export const StateNsaLabelMapping: Record<string, string> = {
  state: AdminConstants.USERS.STATE,
  allState: AdminConstants.USERS.STATE,
  nsa: AdminConstants.USERS.NSA,
};

export const CHUNK_SIZE = 0x8000; // 32768
