import ServiceRequest from 'src/services/ServiceRequest';
import {
  FetchAllDashboardUserListParams,
  FetchAllDashboardUserListResponseData,
  GetAllCategoryTypesByCategoryIdParams,
  GetAllCategoryTypesByCategoryIdResponseData,
  GetAllDashboardCategoryListParams,
  GetAllDashboardCategoryListResponseData,
  GetAllDashboardInfoCardListParams,
  GetAllDashboardInfoCardListResponseData,
  GetCompletedTaskDetailsParams,
  GetCompletedTaskDetailsResponseData,
} from './DashboardServiceMapper';
import {
  getAllCategoryListUrl,
  getAllCategoryTypeByCategoryIdUrl,
  getAllDashboardInfoCardListUrl,
  getAllDashboardUserListUrl,
  getCompletedTaskDetailsUrl,
} from './DashboardApiEndPoints';

export const fetchAllDashboardInfoCardList = async (
  data: GetAllDashboardInfoCardListParams,
): Promise<GetAllDashboardInfoCardListResponseData> => {
  return await ServiceRequest.get(getAllDashboardInfoCardListUrl, {
    payload: data,
  });
};

export const fetchAllCategoryListUrlList = async (
  data: GetAllDashboardCategoryListParams,
): Promise<GetAllDashboardCategoryListResponseData> => {
  return await ServiceRequest.get(getAllCategoryListUrl, {
    payload: data,
  });
};

export const fetchAllCategoryTypesByCategoryId = async (
  data: GetAllCategoryTypesByCategoryIdParams,
): Promise<GetAllCategoryTypesByCategoryIdResponseData> => {
  return await ServiceRequest.get(getAllCategoryTypeByCategoryIdUrl, {
    payload: data,
  });
};

export const fetchAllDashboardUserList = async (
  data: FetchAllDashboardUserListParams,
): Promise<FetchAllDashboardUserListResponseData> => {
  return await ServiceRequest.get(getAllDashboardUserListUrl, {
    payload: data,
  });
};

export const GetCompletedTaskDetails = async (
  data: GetCompletedTaskDetailsParams,
): Promise<GetCompletedTaskDetailsResponseData> => {
  return await ServiceRequest.get(getCompletedTaskDetailsUrl, {
    payload: data,
  });
};
