import { QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION } from '@constants/global-constants/constants';

export type QueueDetailsLayoutViewerType = {
  top: string;
  left: string;
  right: string;
};

export type QueueDetailsViewLayoutConfigurationType = {
  [key: string]: QueueDetailsLayoutViewerType;
};

export const queueDetailsViewLayoutConfiguration: QueueDetailsViewLayoutConfigurationType =
  {
    [QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.DEFAULT]: {
      top: QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.TASK_DETAILS,
      left: QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.ARBIT,
      right:
        QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.EMAIL_VIEWER_AND_KEY_IDENTIFIERS,
    },
    [QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.AI]: {
      top: QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.BACK_BUTTON_AND_RETRY_BUTTON_FOR_AI_FAILED_TASKS,
      left: QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.EMAIL_VIEWER,
      right:
        QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.TASK_DETAILS_AND_ACTION_TAKEN_AND_KEY_IDENTIFIERS,
    },
    [QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.UNCLASSIFIED]: {
      top: QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.BACK_BUTTON_AND_ARCHIVE_BUTTON_FOR_UNCLASSIFIED_AND_UNFLAGGED_TASKS,
      left: QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.EMAIL_VIEWER,
      right:
        QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.TASK_TYPE_AND_STATE_NSA_DROPDOWN,
    },
    [QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.DATA_EXCEPTIONS]: {
      top: QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.TASK_DETAILS,
      left: QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.ARBIT,
      right:
        QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.EXCEPTION_TYPE_ACTION_TAKEN_AND_ERROR_LOGS,
    },
    [QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.DB_QUERY]: {
      top: QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.TASK_DETAILS,
      left: QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.ARBIT,
      right:
        QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.DB_CONNECTOR_NAME_DESCRIPTION_AND_DATA,
    },
    [QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.STATUS_ARCHIVED]: {
      top: QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.BACK_BUTTON_ONLY,
      left: QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.EMAIL_VIEWER,
      right: QUEUE_DETAILS_VIEW_LAYOUT_CONFIGURATION.STATUS_ARCHIVED,
    },
  };
