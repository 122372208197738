import { Grid, styled } from '@mui/material';

export const LoadingGridForHistoryTabProfilePic = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.188rem;
`;
