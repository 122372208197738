import styled from '@emotion/styled';
import { APP } from 'src/styles/variables';

export type ProgressContainerProps = {
  height: string | number;
  border: string;
};

export type ProgressElementStyledProps = {
  backgroundColor: string;
  offset: number;
  value: number;
  total: number;
  transitionTime: number;
  textColor?: string;
  fontSize?: number;
  isBold?: boolean;
};

export const ProgressBackground = styled.div<{ backgroundColor: string }>`
  background-color: ${props => props.backgroundColor};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const ProgressContainer = styled.div<ProgressContainerProps>`
  width: 100%;
  height: ${props =>
    typeof props.height === 'string' ? props.height : props.height + 'px'};
  position: relative;
  border: ${props => props.border};
  border-radius: 25rem;
  overflow: hidden;
`;

export const ProgressTotalStyled = styled('div')`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin-top: 0.25rem;
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  color: ${APP.PALETTE.SECONDARY.MAIN};
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
`;
export const ProgressElementStyled = styled.div<ProgressElementStyledProps>`
  background-color: ${props => props.backgroundColor};
  width: ${props => (props.value / props.total) * 100 + '%'};
  z-index: 8;
  height: 100%;
  top: 0;
  left: ${props => {
    if (props.offset === props.total) {
      return '92%';
    } else {
      return (props.offset / props.total) * 96 + '%';
    }
  }};
  position: absolute;
  transition: width ${props => props.transitionTime}s ease-in-out,
    left ${props => props.transitionTime}s ease-in-out;
  color: ${props => props.textColor};
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  padding: 0.5rem;
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
  font-size: ${props => (props.fontSize ? props.fontSize : 8)}px;
`;
