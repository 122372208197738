export enum QueueTableColumns {
  caseId = 'caseId',
  taskCategory = 'taskCategory',
  taskType = 'taskType',
  customerName = 'customerName',
  nsaState = 'nsaState',
  payorName = 'payorName',
  priority = 'priority',
  dueDate = 'dueDate',
  date = 'date',
  completedOn = 'completedOn',
  emailDate = 'emailDate',
  status = 'status',
  statusReason = 'statusReason',
  assignedUser = 'assignedUser',
}
