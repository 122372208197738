import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { APP } from 'src/styles/variables';

interface EmailInterfaceDivProps {
  fontSize?: string | number;
  fontWeight?: string | number;
}

export const EmailDetailsContainerGrid = styled(Grid)<{ padding: string }>`
  width: 100%;
  height: 100%;
  padding: ${({ padding }) => padding};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  overflow: auto;
`;

export const EmailDivStyled = styled('div')<EmailInterfaceDivProps>`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}rem` : 'inherit')};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

export const KeywordStyledSpan = styled('span')<EmailInterfaceDivProps>`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}rem` : 'inherit')};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

export const RichTextEmailViewer = styled(ReactQuill)<{
  issubjectviewer: 'yes' | 'no';
}>`
  border: none;
  width: 100%;
  height: fit-content;

  .ql-container.ql-snow {
    border: none;
    font-size: ${({ issubjectviewer }) =>
      issubjectviewer === 'yes' ? '1.312rem' : ''};
  }

  .ql-editor {
    padding: 0 !important;
  }
`;

export const EmailAttachmentWrapperGrid = styled(Grid)`
  width: inherit;
  min-height: 3.3rem;
  height: 3.3rem;
  flex-wrap: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 0.312rem 0 0.312rem 0;
  overflow: auto;
  gap: 0.5rem;
  overflow-y: hidden;
`;

export const HorizontalEmailSectionDivider = styled('hr')`
  width: 60%;
  border: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
  margin: 1.05rem 0 !important;
`;

export const EmailAttachmentContentGrid = styled(Grid)`
  width: fit-content;
  min-height: 2.18rem;
  height: 2.18rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: ${APP.PALETTE.SECONDARY.MAIN};
  padding: 0.5rem 0.6rem;
  cursor: pointer;
`;

export const EmailAttachmentIconImage = styled('img')`
  padding: 0.125rem 0.25rem 0.125rem 0.125rem;
  height: 1.375rem;
  width: 1.375rem;
`;

export const EmailAttachmentNameTypography = styled(Typography)`
  color: ${APP.PALETTE.COMMON.WHITE};
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
`;

export const CCGrid = styled(Grid)`
  width: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 0.35rem;
`;
