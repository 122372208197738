import styled from '@emotion/styled';
import { Tab, Tabs, Divider, Theme } from '@mui/material';
import { APP } from 'src/styles/variables';

export const TabsFilterWrapper = styled(Tabs)`
  min-width: 16.68rem;
  height: 2rem;
  min-height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.556rem;
  background-color: ${APP.PALETTE.TAB_FILTER.BACKGROUND_COLOR.PRIMARY};
  padding: 0.125rem;

  & .MuiTabs-indicator {
    display: none;
  }
`;

export const TabFilterButtonWrapperDiv = styled('div')`
  height: 1.75rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0.125rem 0;
  border-radius: 0.43rem;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const CustomTab = styled(Tab)`
  height: inherit;
  min-height: inherit;
  width: inherit;
  text-transform: none;
  font-size: 0.87rem;
  font-weight: 600;
  border-radius: 0.43rem;
  opacity: 10;
  background-color: ${({
    isactive,
    theme,
  }: {
    isactive: 'yes' | 'no';
    theme?: Theme | undefined;
  }) => (isactive === 'yes' ? theme?.palette.background.paper : 'transparent')};
  box-shadow: ${({ isactive }: { isactive: 'yes' | 'no' }) =>
    isactive === 'yes'
      ? APP.PALETTE.TAB_FILTER.BOX_SHADOW.PRIMARY + ' 0px 0.18rem 0.5rem'
      : 'none'};

  &.Mui-selected {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

export const TabFilterDivider = styled(Divider)`
  width: 0.125rem;
  border-radius: 0.0312rem;
  opacity: 0.3;
  background-color: ${APP.PALETTE.TAB_FILTER.DIVIDER.BACKGROUND_COLOR.PRIMARY};
`;
