import { SyntheticEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { t } from 'i18next';
import { produce } from 'immer';

import FormDialog from '@components/dialog/form-dialog/FormDialog';
import { LabelStyled } from '@components/select-input/select-text-input/SelectTextInputStyle';
import SelectSearchInput from '@components/select-input/select-search-input/SelectSearchInput';
import CrossIcon from '@assets/images/Close.svg';
import SearchIcon from '@assets/images/Search.svg';
import {
  DividerStyled,
  ErrorTextStyled,
  FooterSubTextStyled,
  FooterTextStyled,
  StyledCustomChip,
} from './QueuePUBucketAddUsersStyle';
import { TextField } from '@mui/material';
import { getErrorMessage } from '@utils/ErrorUtils';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { useSnackbar } from 'notistack';
import {
  addUsersUnderTaskCategoryByTaskCategoryId,
  getAllNonAssignedUsersForTaskCategoryByTaskCategoryId,
  getAssignableUsersForTaskCategoryByTaskCategoryId,
} from 'src/services/service-handlers/private/queue-handlers/QueuePrivateService';
import {
  AddUsersUnderTaskCategoryByTaskCategoryIdPayloadDto,
  GetAssignableUsersInCategoryByTaskCategoryIdDataRowDto,
} from 'src/services/service-handlers/private/queue-handlers/QueueServiceMapper';

interface IQueuePUBucketAddUsersProps {
  handleCancel: () => void;
  isDialogOpen: boolean;
  taskCategoryId: string;
  refreshTaskCountSummaryByUserForCategory: () => void;
}

interface IOptionProps {
  id: string;
  name: string;
}
interface IQueuePUBucketAddUsersState {
  inputValue: string;
  selectedUsers: IOptionProps[];
  selectedUsersToDelete: IOptionProps[];
  usersList: IOptionProps[];
  userError: string;
  isUsersListLoading: boolean;
  isUpdatingPUBucketUsers: boolean;
  isAssignableUsersListLoading: boolean;
}

const mapAssignableUsersListToSelectedUsersDto = (
  usersList: GetAssignableUsersInCategoryByTaskCategoryIdDataRowDto[],
): IOptionProps[] => {
  if (usersList.length > 0) {
    return usersList.map(({ userId, userFullName }) => ({
      id: userId,
      name: userFullName,
    }));
  }
  return [];
};

const initialQueuePUBucketAddUsersState: IQueuePUBucketAddUsersState = {
  inputValue: '',
  selectedUsers: [],
  selectedUsersToDelete: [],
  usersList: [],
  userError: '',
  isUsersListLoading: false,
  isUpdatingPUBucketUsers: false,
  isAssignableUsersListLoading: false,
};

const QueuePUBucketAddUsers = (
  props: IQueuePUBucketAddUsersProps,
): JSX.Element => {
  const { control, handleSubmit, reset } = useForm({});

  const {
    isDialogOpen,
    handleCancel,
    taskCategoryId,
    refreshTaskCountSummaryByUserForCategory,
  } = props;

  const [queuePUBucketAddUsersState, setQueuePUBucketAddUsersState] =
    useState<IQueuePUBucketAddUsersState>(initialQueuePUBucketAddUsersState);

  const {
    inputValue,
    selectedUsers,
    usersList,
    userError,
    selectedUsersToDelete,
  } = queuePUBucketAddUsersState;

  const { enqueueSnackbar } = useSnackbar();

  const onClose = () => {
    reset();
    handleCancel();
  };

  // API region starts

  const fetchAllNonAssignedUsersForTaskCategoryId = async () => {
    setQueuePUBucketAddUsersState(
      produce(draft => {
        draft.isUsersListLoading = true;
      }),
    );
    try {
      const getAllNonAssignedUsersInCategoryByTaskCategoryIdResponse =
        await getAllNonAssignedUsersForTaskCategoryByTaskCategoryId(
          taskCategoryId,
        );

      const formattedUsersList = mapAssignableUsersListToSelectedUsersDto(
        getAllNonAssignedUsersInCategoryByTaskCategoryIdResponse?.data?.rows ??
          [],
      );

      setQueuePUBucketAddUsersState(
        produce(draft => {
          draft.usersList = formattedUsersList;
        }),
      );
    } catch (errorResponse) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueuePUBucketAddUsersState(
        produce(draft => {
          draft.isUsersListLoading = false;
        }),
      );
    }
  };

  const fetchAllAssignableUsersForTaskCategoryId = async () => {
    setQueuePUBucketAddUsersState(
      produce(draft => {
        draft.isAssignableUsersListLoading = true;
      }),
    );
    try {
      const getAssignableUsersInCategoryByTaskCategoryIdResponse =
        await getAssignableUsersForTaskCategoryByTaskCategoryId(taskCategoryId);
      if (
        getAssignableUsersInCategoryByTaskCategoryIdResponse?.data?.rows
          ?.length > 0
      ) {
        const newNonAssignableUsersList =
          mapAssignableUsersListToSelectedUsersDto(
            getAssignableUsersInCategoryByTaskCategoryIdResponse?.data?.rows,
          );
        setQueuePUBucketAddUsersState(
          produce(draft => {
            draft.selectedUsers = newNonAssignableUsersList;
          }),
        );
      }
    } catch (errorResponse) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueuePUBucketAddUsersState(
        produce(draft => {
          draft.isAssignableUsersListLoading = false;
        }),
      );
    }
  };

  const addUsersUnderTaskCategory = async () => {
    setQueuePUBucketAddUsersState(
      produce(draft => {
        draft.isUpdatingPUBucketUsers = true;
      }),
    );
    try {
      const payloadData: AddUsersUnderTaskCategoryByTaskCategoryIdPayloadDto = {
        userIdsToAdd: [],
        userIdsToDelete: [],
      };
      if (selectedUsers.length > 0) {
        payloadData.userIdsToAdd = selectedUsers.map(
          selectedUser => selectedUser.id,
        );
      }
      if (selectedUsersToDelete.length > 0) {
        payloadData.userIdsToDelete = selectedUsersToDelete.map(
          selectedUserToDelete => selectedUserToDelete.id,
        );
      }
      const addUsersUnderTaskCategoryResponse =
        await addUsersUnderTaskCategoryByTaskCategoryId(
          taskCategoryId,
          payloadData,
        );
      const successMessage =
        addUsersUnderTaskCategoryResponse?.data?.message ?? '';
      enqueueSnackbar(successMessage, SnackBarConfig.getSuccess());
      onClose();
      refreshTaskCountSummaryByUserForCategory();
    } catch (errorResponse) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueuePUBucketAddUsersState(
        produce(draft => {
          draft.isUpdatingPUBucketUsers = false;
        }),
      );
    }
  };

  // API region ends

  const init = async () => {
    await fetchAllNonAssignedUsersForTaskCategoryId();
    await fetchAllAssignableUsersForTaskCategoryId();
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (
    event: SyntheticEvent<Element, Event>,
    newInputValue: string,
  ) => {
    setQueuePUBucketAddUsersState(
      produce(draft => {
        draft.inputValue = newInputValue;
      }),
    );
  };

  const handleSelectChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: IOptionProps,
  ) => {
    let updatedList: IOptionProps[] = [];
    if (newValue) {
      if (Array.isArray(newValue)) {
        updatedList = [...selectedUsers, ...newValue];
      } else {
        updatedList = [...selectedUsers, newValue];
      }
      setQueuePUBucketAddUsersState(
        produce(draft => {
          draft.selectedUsers = updatedList;
          draft.usersList = usersList.filter(user => user.id !== newValue.id);
          draft.inputValue = '';
          draft.userError =
            updatedList.length > 0
              ? ''
              : t('ADMIN.VALIDATIONS.ADD_USERS_DIALOG.ADD_USERS_REQUIRED');
        }),
      );
    }
  };

  const handleDelete = (valueToDelete: IOptionProps) => () => {
    // Remove the item from the selected values list
    const updatedList = selectedUsers.filter(
      value => value.id !== valueToDelete.id,
    );

    setQueuePUBucketAddUsersState(
      produce(draft => {
        draft.selectedUsers = [...updatedList];
        // Add the deleted item back to the options list and deleted list
        draft.usersList = [...usersList, valueToDelete];
        draft.selectedUsersToDelete = [...selectedUsersToDelete, valueToDelete];
        draft.userError =
          updatedList.length > 0
            ? ''
            : t('ADMIN.VALIDATIONS.ADD_USERS_DIALOG.ADD_USERS_REQUIRED');
      }),
    );
  };

  const onSubmit = async () => {
    if (selectedUsers.length === 0) {
      setQueuePUBucketAddUsersState(
        produce(draft => {
          draft.userError = t(
            'ADMIN.VALIDATIONS.ADD_USERS_DIALOG.ADD_USERS_REQUIRED',
          );
        }),
      );
    } else {
      await addUsersUnderTaskCategory();
    }
  };

  const renderDialogContent = () => {
    return (
      <div>
        <Controller
          name="selectUsers"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <>
              <LabelStyled>
                {t(
                  'ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.ADD_USER_DIALOG.SEARCH_AND_SELECT',
                )}
              </LabelStyled>
              <SelectSearchInput
                value={null}
                onChange={(e, newValue) =>
                  handleSelectChange(e, newValue as IOptionProps)
                }
                inputValue={inputValue}
                onInputChange={handleInputChange}
                options={usersList}
                getOptionLabel={(option: any) => option.name}
                includeInputInList
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder="search & select"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: <img src={SearchIcon} alt="search" />,
                    }}
                  />
                )}
              />
            </>
          )}
        />
        {userError && <ErrorTextStyled>{t(`${userError}`)}</ErrorTextStyled>}

        <DividerStyled />
        <FooterTextStyled>
          {t('ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.ADD_USER_DIALOG.SELECTED_USERS')}
        </FooterTextStyled>
        <FooterSubTextStyled>
          {selectedUsers.length > 0 &&
            selectedUsers.map((item: IOptionProps, index) => (
              <StyledCustomChip
                key={item.id}
                label={item.name}
                onDelete={handleDelete(item)}
                variant={undefined}
                deleteIcon={<img src={CrossIcon} alt="close" />}
              />
            ))}
        </FooterSubTextStyled>
      </div>
    );
  };

  return (
    <FormDialog
      isDialogOpen={isDialogOpen}
      handleCancel={onClose}
      titleText={t(
        'ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.ADD_USER_DIALOG.EDIT_USERS_IN_BUCKET',
      )}
      subTitleText={t(
        'ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.ADD_USER_DIALOG.SUB_TEXT_SELECT_USER',
      )}
      dialogContent={renderDialogContent()}
      submitButtonText={t(
        'ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.ADD_USER_DIALOG.SAVE_AND_ADD',
      )}
      cancelButtonText={t(
        'ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.ADD_USER_DIALOG.CANCEL',
      )}
      onSubmit={handleSubmit(onSubmit)}
    />
  );
};

export default QueuePUBucketAddUsers;
